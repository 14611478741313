import React from 'react'

import { calculatePhotoUrl } from '../utils/imageUrl'

interface ProtosScrollProps {
  photos: any
  scroll?: any
  width?: any
}
export class PhotosScroll extends React.PureComponent<ProtosScrollProps, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      index: 0
    }
  }

  handleRight = (e) => {
    e.preventDefault()
    if (this.state.index + 1 < this.props.photos.length) {
      this.setState({ index: this.state.index + 1 })
    }
  }
  handleLeft = (e) => {
    e.preventDefault()

    if (this.state.index > 0) {
      this.setState({ index: this.state.index - 1 })
    }
  }
  render() {
    const { photos } = this.props
    const currentPhoto = photos[this.state.index]

    if (currentPhoto) {
      const photo = calculatePhotoUrl(currentPhoto)
      return (
        <div style={{ display: "flex", position: "relative", height: "100%" }}>
          {this.props.scroll && (
            <div
              style={{ position: "absolute", right: 10, left: 10 }}
              onClick={this.handleLeft}
            >
              {"<<"}
            </div>
          )}
          <img
            src={photo.url}
            style={{ objectFit: "cover", width: "100%", height: "100%" }}
            alt=""
          />
          {this.props.scroll && (
            <div
              style={{ position: "absolute", right: 10, top: 10 }}
              onClick={this.handleRight}
            >
              {">>"}
            </div>
          )}
        </div>
      )
    }
    return this.renderMockPhoto()
  }
  renderMockPhoto() {
    return (
      <div
        style={{ display: "flex", position: "relative", height: "100%" }}
      ></div>
    )
  }
}
