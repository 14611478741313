import React from 'react'
import styled from 'styled-components'

export const FullPageContainerStyled = styled.div`

    padding-top: 68px;

    // background: radial-gradient(
    //   94.03% 126.35% at 98.33% -26.2%,
    //   #7b00a6 0%,
    //   #3f0055 100%
    // );

    .Container {
      width: 960px;
      margin: auto;
      background-color: #fafafa;
      box-shadow: 0 30px 50px #dedfe4;
      color: #1b1b1d;
      border-radius: 10px;
      min-height: 100%;
      max-width: 100%;
    }

    .CenteredWrapper {
      width: 960px;
      margin: auto;
      max-width: 100%;
    }

    .Left {
      flex: 7;
      padding: 16px;
      background: white;
      border-radius: 10px;
      box-shadow:0 10px 20px #f0f0f0;
    }

    .Right {
      padding: 0 16px;
      flex: 5;
    }

    h2 {
      font-size: 24px;
    }
    .sectionTitle {
      font-size: 20px;
      margin: 5px 0 5px 8px;
    }
    .Section {
      padding: 20px;
    }
    .Photos {
      height: 325px;
      width: 100%;
      padding: 32px;
      display: flex;
      margin-bottom: 10px;
      box-sizing: border-box;
      overflow: hidden;

      .InnerWrapper {
        display: flex;
        flex-wrap: nowrap;

        > div {
          flex: 0 0 auto;
          // display: inline;
        }
      }
      img {
        border-radius: 10px;
      }
      .Photos--Single {
        justify-content: center;
        .VenuePage__Photos__Photo {
          width: calc(960px - 40px);
        }
      }

      .Photo {
        width: calc(1020px * 0.62);
        border-radius: 16px;
        margin: 0 10px;
        // padding: 0 5px;
        div {
          border-radius: 16px;
          -o-object-fit: cover;
          object-fit: cover;
          height: 100%;
          background: no-repeat center;
          background-size: cover;
          display: block;
          height: 100%;
          position: relative;
          z-index: 1;
        }
      }
    }
    .categoryChip {
      border-radius: 16px;
      padding: 4px 8px;
      color: white;
      background-color: blueviolet;
      margin-right: 6px;
      display: inline;
      font-size: 14px;
      line-height: 16px;
    }
    .CTA {
      border-radius: 12px;
      background: linear-gradient(to bottom, #ff5722, #ff5722cc);
      color: white;
      text-align: center;
      font-weight: 600;
      margin: 10px 0;
      padding: 10px 20px;
      box-shadow: 0 30px 50px #ff572220;
      cursor: pointer;
      &:hover {
        box-shadow: 0 30px 50px #ff572240;
      }
    }

    .Name {
      font-size: 28px;
      font-weight: 800;
    }
    
    .SectionTitle {
      font-size: 24px;
      line-height: 2em;
    }

    .Address {
      color: #333;
      font-size: 16px;
      line-height: 20px;
      padding: 16px 0;
    }

    .Content {
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      box-sizing: border-box;
      // max-width: 1280px;
      // min-height: 100vh;
      padding: 32px;
      display: flex;
      flex-direction: row;
      min-height: 60vh;
     
    }

    .AdminPhotos {
      .List {
        display: flex;
        flex-direction: row;
        overflow: auto;
      }
      .Wrapper {
        min-width: 200px;
        max-width: 200px;
        height: 200px;
        margin: 5px;
        cursor: pointer;
        position: relative;
        &:hover {
          .VenuePage__AdminPhotos__Actions {
            display: initial;
            opacity: 0.9;
            background-color: white;
            border-radius: 5px;
            padding: 4px 6px;
            cursor: pointer;
          }
        }
      }
      .Photo {
        width: 200px;
        height: 200px;
      }
      .Actions {
        display: none;
        position: absolute;
        top: 20;
        right: 20;
      }
    }

    .Products {
      border-radius: 8px;
      box-shadow: 0 30px 50px #9695b740;

      .Item:last-child {
        border-bottom: none;
      }

      .Item {
        padding: 15px;
        border-bottom: 1px solid #ccc;
        display: flex;
        flex-direction: row;
        .ItemContent {
          flex: 1;
        }

        .Description {
          font-size: 0.8em;
          color: #999;
        }
        .Price {
          font-size: 1.2em;
          text-align: right;
          max-width: 120px;
          overflow: hidden;
        }
      }
    }
    .Benefits {
      color: #212b36;
      display: flex;
      flex-direction: row;
      font-size: 21px;
      font-weight: 800;
      line-height: 29px;
      letter-spacing: 0.3px;
    }
    .BenefitItem {
      box-sizing: border-box;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: black;
      flex: 1;

      &:hover {
        .VenuePage__BenefitItem__Inner {
          box-shadow: 0px 15px 30px rgba(149, 156, 183, 0.3);
        }
      }

      .Inner {
        border-radius: 50px;
        cursor: pointer;
        width: 72px;
        height: 72px;
        box-shadow: 0px 15px 30px rgba(149, 156, 183, 0.2);
        background: #ebeaff;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color: #8a85ea;
      }

      .Name {
        // border-right: 1px solid rgba(45, 54, 95, 0.2);
        // width: 62%;
        color: #7a75d5;
        padding-top: 20px;
        font-size: 13px;
        font-weight: 600;
        line-height: 23px;
        text-align: center;
        letter-spacing: 0.3px;
      }

      .Value {
        font-size: 36px;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
      }
    }
  }

  .VenueMiniMap {
    height: 300px;
    width: 100%;
    position: relative;
  }

  .VenueFeed {
  }

  // For mobile only
  @media (max-width: 725px) {
    
    padding-top: 54px;

    .Container {
      width: 100%;
      border-radius: 0; 
    }

    .Content {
      display: flex;
      flex-direction:column;
      padding: 16px;
    }
    .Header {

    }
    .Photos {
padding: 16px;
    }

    .SectionTitle {
        margin: 4px 0;
    }

    .VenuePage {
      background-color: initial;
      .Container {
        background-color: initial;
        width: 100%;
        flex-direction: column;
      }
      .Content {
        padding: 32px;
        box-sizing: border-box;
        flex-direction: column;
      }

      .Section {
        padding: 20px 0;
      }
      .Photos {
        .InnerWrapper {
          width: 100%;
        }
        .Photo {
          width: 100%;
          margin: 0;
        }
      }
      .LeftBubble {
        position: absolute;
        top: 35vh;
        left: 0;
        z-index: -1;
        height: 70vw;
      }
    }
  
`

export const FullPageContainer = (props) => {
  return (
    <div
      className="container m-auto max-w-screen-lg"
      style={{
        paddingTop: 68
      }}
    >
      {props.children}
    </div>
  )
}
