import React from 'react'

import { Footer } from '../../components/footer'
import { FullPageContainer } from '../../components/FullPageContainer'
import OgMeta from '../../components/OgMeta'

export const ContactPage = (props) => {
  return (
    <FullPageContainer>
      <OgMeta />
      <div className="min-h-screen">
        <div className="bg-white p-10 rounded-xl">
          <div>
            <div className="text-xl font-bold">Contact ClapHere</div>

            <div className="font-bold">contact@claphere.com</div>
          </div>
        </div>
      </div>

      <Footer />
    </FullPageContainer>
  )
}
