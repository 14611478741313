import ClapTypo from "../../../components/ClapTypo"
import { PageContainer } from "../../../components/PageContainer"
import { useVenueFeed } from "../../feed/api/useVenueFeed"
import { FeedCardRoman } from "../../feed/components/FeedCardRoman"

export const VenuePageFeed = ({ venue }) => {
  const { loading, data, error, posts } = useVenueFeed(venue.id)
  const items = data?.edges ? data.edges.map((e) => e.edge) : []

  return (
    <PageContainer
      style={{
        paddingLeft: 0,
        paddingRight: 0,
        backgroundColor: "var(--clap-color-orange-100)"
      }}
    >
      <div className="mt-4 container m-auto max-w-screen-xl min-h-screen">
        <div className="max-w-lg m-auto">
          <div className="text-center my-4">
            <ClapTypo.HeaderVenuePageName>Feed</ClapTypo.HeaderVenuePageName>
          </div>
          {data && (
            <>
              {items.map((item) => (
                <div className="mb-4">
                  <FeedCardRoman post={item} />
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </PageContainer>
  )
}
