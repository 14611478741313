import React from "react";
import { useLocation, useRouteMatch, match } from "react-router-dom";
import { FullPageContainer } from "../../../components/FullPageContainer";
import { useUserQuery } from "../api/useUserQuery";

interface UserPageMatch {
  userId: string;
}
export const UserPage = (props) => {
  const location = useLocation();
  const match: match<UserPageMatch> = useRouteMatch<UserPageMatch>();

  const { user, loading, data, error } = useUserQuery(match.params.userId);
  return (
    <FullPageContainer>
      <div className="Container">
        <div className="Photos">Header User Page</div>

        <div className="Content">
          <div className="Left">Left </div>

          <div className="Right">Right </div>
        </div>
      </div>
    </FullPageContainer>
  );
};
