// import { withApollo } from '@apollo/client/react/hoc'
import useAxios from 'axios-hooks'
import React from 'react'

// import gql from 'graphql-tag'
// import bookmarkableHoc from '../../../shared/modules/common/bookmarkable-hoc'

// const qlposts = gql(
//   `query posts($after: String, $first:Int!) {
//     posts(after: $after, first: $first) {
//         totalCount
//         pageInfo {
//           endCursor
//           hasNextPage
//         }
//         edges {
//           cursor
//           edge {
//             id
//             type
//             publishedAt
//             contentText
//             venueId
//             media { id type mimeType urls { w url } }
//             authorUserId
//             authorUser {
//               id name isFollowed profilePhoto { url id }
//             }
//             venue {
//               venueId
//               name
//               lat
//               lng
//               city
//               street
//               categories { name }
//               tags { name code }
//             }
//             bookmark {
//               bookmarkUid
//               createdTime
//             }
//           }
//         }
//     } }
//     `
// )

export const SectionFeedHoc = (WrappedComponent) => {
  class RecommendationspostsHocInner extends React.PureComponent<any, any> {
    constructor(props) {
      super(props)
      this.state = {}
    }

    setStateAsync = (state) => {
      new Promise<void>((resolve) => {
        this.setState(state, () => resolve())
      })
    }

    startSearch = async () => {
      return
      // const search = {}
      // try {
      //   await this.setStateAsync({ isLoading: true })
      //   const res = await this.props.client.query({
      //     query: qlposts,
      //     variables: {},
      //     fetchPolicy: "no-cache"
      //   })
      //   let posts = []
      //   if (res && res.data && res.data.posts) {
      //     posts = res.data.posts.items
      //   }

      //   await this.setStateAsync({ isLoading: false, posts })
      // } catch (err) {
      //   await this.setStateAsync({ isLoading: false })
      // }
    }

    handleQueryChange = (params) => {}
    handleSearch = () => {
      this.startSearch()
      // debounce(() => this.startSearch, 3000)
    }
    handleToggleBookmark = async (postId, on) => {
      console.log("handleToggleBookmark", postId, on)

      try {
        const update = async (cache, { data }) => {
          let bookmarkSuccess = false
           if (data && on && data.addBookmark) {
            bookmarkSuccess = data.addBookmark.success
          }
          if (data && !on && data.removeBookmark) {
            bookmarkSuccess = data.removeBookmark.success
          }
          // if (bookmarkSuccess) {
          //   const qlpostsCache = cache.readQuery({
          //     query: qlposts
          //   })
          //   if (
          //     qlpostsCache &&
          //     qlpostsCache.posts &&
          //     qlpostsCache.posts.items
          //   ) {
          //     const itemIdx = qlpostsCache.posts.items.findIndex(
          //       (i) => i.postsUid === postId
          //     )
          //     if (itemIdx !== undefined) {
          //       const items = [...qlpostsCache.posts.items]
          //       items[itemIdx] = { ...items[itemIdx] }
          //       if (on) {
          //         items[itemIdx].bookmark = data.addBookmark.bookmark
          //       } else {
          //         items[itemIdx].bookmark = null
          //       }
          //       cache.writeQuery({
          //         query: qlposts,
          //         data: {
          //           posts: {
          //             ...qlpostsCache.posts,
          //             items
          //           }
          //         }
          //       })
          //     }
          //   }
          // }
        }
        // const deleteResult = data.myPostDelete

        await this.props.onToggleBookmark(postId, on, { update })
      } catch (err) {}
    }
    render() {
      // console.log('Render RecommendationspostsHoc', this.props, this.state)
      return (
        <WrappedComponent
          {...this.props}
          onSearch={this.handleSearch}
          posts={this.props.posts}
          isLoading={this.state.isLoading}
          onQueryChange={this.handleQueryChange}
          onToggleBookmark={this.handleToggleBookmark}
        />
      )
    }
  }

  const RecommendationspostsHocInnerWithQuery = (props) => {
    const pageSize = 6
    const variables: any = {
      first: pageSize,
      after: null
    }
    if (props.venueId) {
      variables.venueId = props.venueId
    }
    const url = process.env.REACT_APP_CLAPHERE_REST_API + "/feed"
    const [{ data, loading }, refetch] = useAxios(url, {
      useCache: false
    })

    const posts = data && data.edges ? data.edges : []
    return (
      // <Query
      //   notifyOnNetworkStatusChange={true}
      //   query={qlposts}
      //   variables={variables}
      // >
      //   {({ loading, data, refetch, fetchMore }) => {
      //     console.log("Render postsHOC query", props, data);
      //     const posts = data && data.posts ? data.posts.edges : [];

      // return (
      <RecommendationspostsHocInner
        {...props}
        posts={posts}
        onRefetch={refetch}
        isLoading={loading}
        hasNextPage={
          posts && posts.pageInfo ? posts.pageInfo.hasNextPage : false
        }
        // onLoadMore={() => {
        //   console.log("OnLoadMore")
        //   if (!posts.pageInfo.hasNextPage) {
        //     return
        //   }
        //   fetchMore({
        //     variables: {
        //       first: pageSize,
        //       after: posts.pageInfo.endCursor
        //     },
        //     updateQuery: (prevResult, { fetchMoreResult }) => {
        //       const newEdges = fetchMoreResult.posts.edges
        //       const pageInfo = fetchMoreResult.posts.pageInfo
        //       return newEdges.length
        //         ? {
        //             posts: {
        //               __typename: prevResult.posts.__typename,
        //               edges: [...prevResult.posts.edges, ...newEdges],
        //               pageInfo
        //             }
        //           }
        //         : prevResult
        //     }
        //   })
        // }}
      />
    )
    // );
    //   }}
    // </Query>
  }
  //   bookmarkableHoc
  const postsHocCmd = RecommendationspostsHocInnerWithQuery
  return postsHocCmd
}
