import { useState } from 'react'
import { Link } from 'react-router-dom'

import { Headshot } from '../../../components/Headshot'
import { ClapCardJuly } from '../../../components/july/ClapCardJuly'
import { PhotoWithTags } from '../../../components/PhotoWithTags'
import { OrderOnMobilePopup } from '../../venues/components/OrderOnMobilePopup'
import { isMobile } from '../../venues/components/VenueOrderNowButton'

const OrderSameCircle = ({ post, onClick }) => {
  return (
    <div
      onClick={onClick}
      className="w-40 h-40 text-white font-bold 
      text-center flex flex-col
      items-center justify-center text-2xl
      absolute
      cursor-pointer
"
      style={{
        backgroundColor: "var(--clap-color-orange)",
        borderRadius: 128,
        transform: "translateY(-70px)",
        right: 0
      }}
    >
      <div>ORDER</div>
      <div>SAME</div>
      <div>SAME</div>
    </div>
  )
}
const FeedCardHeader = ({ post }) => {
  console.log("FeedCardHeader", post)
  return (
    <div className="flex flex-row my-2 justify-center">
      <div className="flex flex-row justify-end">
        <div className="mr-2 flex flex-col justify-center">
          {post.venue && <Headshot user={post.authorUser} />}
        </div>
        <div className="mr-2 flex flex-col justify-center">
          <img
            src="/assets/logo/clap_logo_grey.png"
            width={51}
            alt="Clap logo"
          />
        </div>
        <div className="mr-2 flex flex-col justify-center">
          {post.authorUser && <Headshot user={post.authorUser} />}
        </div>
      </div>
      {/* Right part */}
      <div className="flex-1">
        <div className="ml-4 text-right">
          <div
            className="font-medium 
          text-xl
          italic text-black"
          >
            {post.authorUser.name} clapped for
          </div>
          <div
            className="font-bold text-xl italic"
            style={{ color: "var(--clap-color-orange)" }}
          >
            <Link to={`/shops/${post.venue?.id}`}>{post.venue?.name}</Link>
          </div>
        </div>
      </div>
    </div>
  )
}
export const FeedCardRoman = ({ post }) => {
  const [isShowingPopup, setIsShowingPopup] = useState(false)

  // var url = `https://claphere.app.link/th/post?clap_post_id=${post.id}&clap_action=order_same&~channel=claphere.com`
  var url = `https://claphere.app.link/post/${post.id}?action=order_same`
  // ?clap_post_id=${post.id}&clap_action=order_same&~channel=claphere.com

  const handleOrderSame = (event: any) => {
    event.preventDefault()
    var mobile = isMobile()

    if (mobile && window) {
      window.open(url, "_blank")
    } else {
      setIsShowingPopup(true)
    }
  }

  return (
    <Link to={`/posts/${post.id}`}>
      <ClapCardJuly>
        <FeedCardHeader post={post} />

        <div className="my-2 sm:my-4">
          {post.media && post.media.length > 0 && (
            <PhotoWithTags photo={post.media[0]} tags={post.tags} />
          )}
        </div>

        <div className="flex flex-row">
          <div
            className="
            flex-1 
            font-medium
            italic
            sm:ml-2
            mr-8
            mt-2
            text-black
            "
          >
            {post.contentText}
          </div>
          <div className="mx-4 relative h-28 w-40">
            <OrderSameCircle post={post} onClick={handleOrderSame} />
          </div>
        </div>
      </ClapCardJuly>
      {isShowingPopup && (
        <div className="fixed">
          <OrderOnMobilePopup
            isOpen={isShowingPopup}
            post={post}
            venue={post.venue}
            onCancel={() => setIsShowingPopup(false)}
            url={url}
          />
        </div>
      )}
    </Link>
  )
}
