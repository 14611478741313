import "twin.macro"
import { VenueQR } from "./VenueQR"

const AppIcon = ({ label, img, alt, href }) => (
  <a href={href} target="_blank" rel="noreferrer">
    <div className="flex flex-col items-center mx-2">
      <div className="text-xs">{label}</div>
      <div
        style={{
          border: "1px solid white",
          borderRadius: 100
        }}
        className="p-2"
      >
        <img src={img} alt={alt} />
      </div>
    </div>
  </a>
)
export const VenuePageSectionQR = ({ venue }) => {
  return (
    <div
      className="h-42 w-full bg-white rounded-2xl flex"
      style={{
        boxShadow: "var(--clap-shadow-card-21)"
      }}
    >
      <div
        className="flex-1 text-white 
        p-2 sm:p-4 
        rounded-2xl items-center flex flex-col"
        style={{
          backgroundColor: "var(--clap-color-orange)",
          boxShadow: "var(--clap-shadow-card-21)"
        }}
      >
        <div className="font-normal text-lg sm:text-2xl">Open in</div>
        <div className="font-black text-lg sm:text-2xl">ClapHere App</div>
        <div className="flex-1" />
        <div className="
          pb-2
          sm:p-2 flex">
          <AppIcon
            label="Get it on"
            img="/assets/img/google_play_with_logo_white.svg"
            alt="Google Play"
            href="https://claphere.onelink.me/UAtl/installwebsite"
          />
          <AppIcon
            label="Get it on"
            img="/assets/img/app_store_with_logo_white.svg"
            alt="Google Play"
            href="https://claphere.onelink.me/UAtl/installwebsite"
          />
        </div>
      </div>
      <div className="
      w-36 sm:w-48 
      hidden sm:flex
      p-2 sm:p-4 
      flex flex-col items-center">
        <VenueQR venue={venue} />
      </div>
    </div>
  )
}
