import { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Redirect, useParams } from 'react-router-dom'
import styled from 'styled-components'

import { Footer } from '../../components/footer'
import { useLocale } from '../../utils/useLocale'
import { HomeSectionRestaurants } from './home4/HomeSectionRestaurants'

const HomeStyled = styled.main`
  background: 248, 249, 254, 1;

  padding-top: 68px;

  background: radial-gradient(
    94.03% 126.35% at 98.33% -26.2%,
    #7b00a6 0%,
    #3f0055 100%
  );

  font-family: Gilroy;

  h2 {
  }

  h3 {
    font-size: 36px;
    line-height: 1.2;
    font-weight: 800;
  }

  /* used by description of points */
  // h4 {
  //   font-size: 20px;
  //   line-height: 1.2;
  //   font-weight: 500;
  // }

  h5 {
    font-size: 24px;
  }

  .top {
    width: 100%;
    min-height: 50vh;

    position: relative;

    > .clip {
      clip-path: path(
        "M238.848 241.307C123.648 295.677 32.2392 291.731 0.934814 282.961V0.153809H711.435C711.435 0.153809 1131.93 0.15403 1440.93 0.153809C1327.93 42.1542 1196.76 35.2307 998.5 158C800.239 280.769 679.196 195.269 562.326 173.346C445.457 151.423 382.848 173.346 238.848 241.307Z"
      );
    }

    padding-bottom: 200px;
    > .clip1 {
      position: absolute;
      left: 0;
      right: 0;
      pointer-events: none;
    }
    > .clip2 {
      position: absolute;
      left: 0;
      right: 0;
      pointer-events: none;
    }

    > .clip3 {
      position: absolute;
      left: 0;
      right: 0;
      pointer-events: none;
    }

    > .topContent {
      margin-top: 60px;
      display: flex;
      flex-direction: row;
      color: white;

      > .left {
        flex: 1;

        // h4 {
        //   font-weight: 200;
        //   font-size: 20px;
        //   line-height: 28px;
        // }
        > .buttonsWrapper {
          display: flex;
          margin-top: 40px;
          color: pink;
          a:link,
          a:visited,
          a:hover,
          a:active {
            text-decoration: none;
          }
        }
      }

      > .right {
        flex: 1;
        display: flex;
        justify-content: center;

        .phone {
          margin-left: auto;
          margin-right: auto;
          width: 377px;
          height: 760px;
          object-fit: contain;
          box-shadow: -40px 4px 60px rgba(0, 0, 0, 0.35);
          // border-radius: 50px;
        }
      }
    }
  }

  .centeredFullWidthContent {
    margin-left: auto;
    margin-right: auto;
    // width: 1280px;

    min-width: 800px;
    max-width: 1180px;
    width: 100%;
    margin: auto;
  }

  .pointsWrapper {
    display: flex;

    background-color: #fceeeb;
    margin-bottom: 80px;

    > .point {
      text-align: center;
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;

      > .icon {
        > img {
          width: 180px;
          height: 180px;
          margin: 40px;
        }
      }

      > .name {
        color: #55098d;
        text-align: center;
        height: 110px;
      }

      > .description {
        font-family: Gilroy;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        padding: 0 40px;
        color: #766a72;
      }
    }
  }

  @media (min-width: 725px) {
    .clip1 {
      bottom: 50px;
    }
    .clip2 {
      bottom: -5px;
    }
    .clip3 {
      bottom: -5px;
    }
    .top {
      padding-bottom: 200px;
    }
  }
  @media (max-width: 725px) {
    padding: 0 !important;
    padding-top: 80px !important;

    .clip1 {
      bottom: 20px;
    }
    .clip2 {
      bottom: 0px;
    }
    .clip3 {
      bottom: -1px;
    }
    .top {
      padding-bottom: 100px;
    }

    h4 {
      font-size: 7vw !important;
      line-height: 1.05 !important;
      font-weight: 500;
    }

    .centeredFullWidthContent {
      flex-direction: column;
      min-width: 100% !important;
      max-width: 100% !important;
      display: initial !important;
    }

    .centeredContainer {
      width: 100% !important;
      max-width: 100% !important;
      min-width: 100% !important;

      > .content {
        padding: 0 40px;
      }
    }

    .top {
      min-height: 10vh;

      > .topContent {
        > .left {
          img {
            max-width: 100% !important;
            width: 100% !important;
          }

          width: 100%;
          max-width: 100%;
          padding: 0 40px;

          h4 {
            font-size: 16px !important;
            line-height: 20px !important;
            max-width: 100%;
          }

          > .buttonsWrapper {
            margin-top: 10px;
            display: flex !important;
            flex-direction: column;
            // padding: 0 20px;
          }
        }
        > .right {
          .phone {
            width: 60vw;
            height: initial;
          }
        }
      }
    }
    .pointsWrapper {
      > .point {
        > .name {
          height: initial !important;
          margin: 20px 0;
        }
      }
    }
  }
`

const DownloadButtonStyled = styled.div`
  border-radius: 54px;
  border: 1px solid white;
  padding: 6px 0px;
  width: 212px;
  height: 64px;
  margin-right: 20px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  background: transparent !important;
  color: white;
  align-items: center;
  text-decoration: none !important;

  &:hover,
  &:active {
    background: rgba(255, 255, 255, 0.3) !important;
    text-decoration: none !important;
  }
  :active {
    text-decoration: none !important;
  }

  > .logo {
    // width: 26px;
    // height: 26px;
    padding: 0 16px;
    // margin-right: 12px;
  }

  > .right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    > .get {
      font-weight: 500;
      font-size: 12px;
      margin-bottom: 4px;
    }

    > .name {
      font-weight: 500;
      font-size: 12px;
    }
  }

  @media (max-width: 725px) {
    width: 60vw;
    // flex: 1;
    margin-left: auto !important;
    margin-right: auto !important;
    // height: 40px;
    padding: 3px 6px;
    margin: 10px 10px;

    > .logo {
      padding-left: 16px;
      padding-right: 16px;
    }
    > .right {
      flex: 1;

      > .get {
      }
      > .brand {
        width: 100px;
        height: 20px;
        img {
          object-fit: contain;
        }
      }
    }
  }
`

const HomeSectionWhite = styled.section`
  // background-color: #f8f9fe;
  background-color: #fceeeb;
  padding: 30px 0;

  h2 {
    font-weight: 800;
    font-size: 96px;
    line-height: 90px;
    color: #55098d;
    margin: 0 !important;
  }

  h5 {
    font-weight: 800;
    font-size: 36px;
    line-height: 50px;
    color: #55098d;
    margin: 0 !important;
  }

  .section {
    padding: 24px 0;
  }
  .centeredContainer {
    min-width: 800px;
    max-width: 900px;
    // max-width: 1180px;
    width: 100%;
    margin: auto;
    // display:flex;
    // flex-direction:row;

    > .items {
      display: flex;
      padding: 16px 0;

      flex-direction: row;
      > .item {
        width: 180px;
        height: 180px;
        margin-right: 20px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  @media (max-width: 725px) {
    padding: 0;

    h2 {
      font-size: 32px;
      line-heigh: 1.2;
    }
    h5 {
      font-size: 24px;
      line-heigh: 1.2;
    }
    .content {
      width: 100%;
      max-width: 100%;
      min-width: 100% !important;
      > .items {
        > .item {
          width: 20vw !important;
          height: 20vw !important;
          margin-right: 2vw;
        }
      }
    }
  }
`

export const Home4 = (props) => {
  const localeParams: any = useLocale()
  const routeParams = useParams<any>()
  const [redirectTo, setRedirectTo] = useState<string | undefined>(undefined)
  // console.log("localeParams", localeParams)

  useEffect(() => {
    // console.log("Home, locale:", routeParams)
    if (!routeParams.country) {
      setRedirectTo("/th/th")
    } else {
      setRedirectTo(undefined)
    }
  }, [])
  if (!routeParams.country) {
    return <Redirect to={"/th/th"} />
  }

  const renderTop = () => {
    return (
      <div className="top">
        <div className="topContent centeredFullWidthContent">
          <div className="left px-4 xl:px-0">
            <h1 className="text-4xl sm:text-7xl font-black text-left ">
              Get any shop <br /> online
            </h1>
            <h4 className="text-xl font-light mt-2">
              <FormattedMessage
                id="home4.subtitle"
                defaultMessage="One-stop complete platform to sell more, increase revenue, adapt new lifestyles"
              />

              {/* Earn <strong>FREE rewards </strong> for recommending your favorite
              restaurants and businesses, then enjoy{" "}
              <strong>games and challenges</strong> as you discover new places
              recommended by your friends */}
            </h4>
            <div className="buttonsWrapper" style={{}}>
              <a
                // href="https://play.google.com/store/apps/details?id=com.claphere.claphere"
                href="https://claphere.onelink.me/UAtl/installwebsite"
                target="_blank"
                rel="noreferrer"
              >
                <DownloadButtonStyled>
                  <div className="logo">
                    <img
                      src="/assets/2021/google_play_logo.svg"
                      alt="GooglePlay"
                    />
                  </div>
                  <div className="right">
                    <div className="get">Get it on</div>
                    <div className="brand">
                      <img
                        src="/assets/2021/google_play_name.svg"
                        alt="Google play"
                      />
                    </div>
                  </div>
                </DownloadButtonStyled>
              </a>
              <a
                // href="https://apps.apple.com/us/app/claphere/id1531751296"
                href="https://claphere.onelink.me/UAtl/installwebsite"
                target="_blank"
                rel="noreferrer"
              >
                <DownloadButtonStyled>
                  <div className="logo">
                    <div
                      style={{
                        background: "url(/assets/2021/app_store_logo.svg)",
                        backgroundRepeat: "no-repeat",
                        width: 26,
                        height: 31
                      }}
                      // src=
                      // alt="GooglePlay"
                    >
                      &nbsp;
                    </div>
                  </div>
                  <div className="right">
                    <div className="get">Get it on</div>
                    <div className="brand">
                      <img
                        src="/assets/2021/app_store_name.svg"
                        alt="Google play"
                      />
                    </div>
                  </div>
                </DownloadButtonStyled>
              </a>
            </div>
          </div>
          <div className="right">
            <img
              className="phone"
              src="/assets/2021/app-phone.png"
              alt="Phone"
            />
          </div>
        </div>
        <div className="clip1">
          <img
            src="/assets/2021/background_wave_1.svg"
            style={{ width: "100%" }}
            alt="clip"
          />
        </div>

        <div className="clip2">
          <img
            src="/assets/2021/background_wave_2.svg"
            style={{ width: "100%" }}
            alt="clip"
          />
        </div>

        <div className="clip3">
          <img
            src="/assets/2021/background_wave_3.svg"
            style={{ width: "100%" }}
            alt="clip"
          />
        </div>
        {/* <div className="clip" /> */}
      </div>
    )
  }

  const renderPoints = () => {
    return (
      <div className=" centeredFullWidthContent pointsWrapper">
        <div className="point">
          <h3 className="name">
            <FormattedMessage
              id="home.socialNetworkingReinwented"
              defaultMessage="Social networking reinvented"
            ></FormattedMessage>
          </h3>
          <div className="icon">
            <img src="/assets/2021/point1_icon.svg" alt="Clap" />
          </div>
          <h5 className="description">
            <FormattedMessage
              id="home.purelyPositiveAtmosphere"
              defaultMessage="ClapHere’s purely positive atmosphere lets you post in peace as you
            support your favorite places in the community."
            />
          </h5>
        </div>
        <div className="point">
          <h3 className="name">
            <FormattedMessage
              id="home.socialMediaRewards"
              defaultMessage="Social Media with Rewards"
            />
          </h3>
          <div className="icon">
            <img src="/assets/2021/point2_icon.svg" alt="Clap" />
          </div>
          <h5 className="description">
            <FormattedMessage
              id="home.friendlerVibe"
              defaultMessage="A friendlier vibe, local recommendations, and a system where users
            are rewarded for their content – what’s not to like?"
            />
          </h5>
        </div>
        <div className="point">
          <h3 className="name">
            <FormattedMessage
              id="home.bringingYouCloser"
              defaultMessage="Bringing you closer"
            />
          </h3>
          <div className="icon">
            <img src="/assets/2021/point3_icon.svg" alt="Clap" />
          </div>
          <h5 className="description">
            <FormattedMessage
              id="home.recommendYourFavorite"
              defaultMessage="Recommend your favorite local businesses, receive exclusive offers,
            and build rewarding relationships through the app with the click of
            a button."
            />
          </h5>
        </div>
      </div>
    )
  }

  return (
    <HomeStyled>
      {/* <ClapHereNav /> */}
      {renderTop()}
      {/* 
      <HomeSectionWhite>
        {renderPoints()}
        <HomeHowItWorks />
      </HomeSectionWhite> */}
      <HomeSectionRestaurants />

      <div
        style={{
          backgroundColor: "#fceeeb"
        }}
      >
        <Footer />
      </div>
    </HomeStyled>
  )
}
