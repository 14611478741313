// import './vars.css'
import './index.css'

import Axios from 'axios'
import LRU from 'lru-cache'
import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { useLocation } from 'react-router'
import { BrowserRouter as Router } from 'react-router-dom'

import { AppRouter } from './app-root'
import { configure, loadCache, serializeCache } from './axios/axios-hooks'
import { AuthContextProvider } from './modules/auth/authContext'
import * as serviceWorker from './serviceWorker'

const uri = process.env.REACT_APP_CLAPHERE_API_URL
  ? process.env.REACT_APP_CLAPHERE_API_URL
  : "https://9a1evbebr8.execute-api.ap-southeast-1.amazonaws.com/staging/graphql"

const axios = Axios.create({
  baseURL: process.env.REACT_APP_CLAPHERE_REST_API
})

const cache = new LRU({ max: 10 })
configure({ axios, cache })

// Axios SSR
var w: any = window
if (w.__AXIOS_HOOKS_CACHE__) {
  console.log("loading __AXIOS_HOOKS_CACHE__", w.__AXIOS_HOOKS_CACHE__)
  loadCache(w.__AXIOS_HOOKS_CACHE__)
  delete w.__AXIOS_HOOKS_CACHE__
}

function ScrollToTop() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}
serializeCache().then((c) => {})
ReactDOM.hydrate(
  <React.StrictMode>
    <AuthContextProvider>
      <Router basename={process.env.PUBLIC_URL}>
        <ScrollToTop />
        <AppRouter />
      </Router>
    </AuthContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
