import React from 'react'
import styled from 'styled-components'

import { FullPageContainer } from '../../../components/FullPageContainer'
import { useItemTypes } from '../api/useItemTypes'
import { ItemTypeCard } from '../components/ItemTypeCard'

const ItemTypesWrapperStyled = styled.div`
  display: grid !important;
  grid-template-columns: repeat(12, 1fr) !important;
  gap: 32px 12px !important;

  .ItemWrapper {
    grid-column: span 4 / auto !important;
  }
`

export const ItemTypesPage = (props) => {
  const { itemTypes, loading } = useItemTypes()
  return (
    <FullPageContainer>
      <div className="CenteredWrapper">
        {/* <div>Item Types </div> */}

        <ItemTypesWrapperStyled>
          {itemTypes &&
            itemTypes.map((itemType) => {
              return (
                <div className="ItemWrapper">
                  <ItemTypeCard itemType={itemType} />
                </div>
              )
            })}
        </ItemTypesWrapperStyled>
      </div>
    </FullPageContainer>
  )
}
