import React from "react"
import { useLocation } from "react-router-dom"
import styled from "styled-components"

import { Footer } from "../../../components/footer"
import { BlogListItem } from "../components/BlogListItem"

export const ListContainer = styled.div`
  padding: 0 20px;

  @media (max-width: 735px) {
    padding-top: 10px;
  }
`

const ListWrapper = styled.div`
  max-width: 1160px;
  width: 100%;
  margin: auto;
  height: 100%;
  padding-left: 32px;
  padding-right: 32px;
  margin-top: 68px;

  @media (max-width: 735px) {
    margin-bottom: 24px;
    padding-left: 15px;
    padding-right: 15px;
  }
`

// const entries: any = [
//   {
//     title: "Where does it come from?",
//     url: "/blog/bog-1",
//     subtitle: "1914 translation by H. Rackham",
//     createdAt: "Sept 1st, 2020",
//     image:
//       "https://res.cloudinary.com/mapspire/image/upload/c_fit,f_auto,w_750/v1/mapspire_web_upload/restaurant-3597677_1920_ldiz82",
//     description:
//       "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id",
//     wordCount: 100,
//     author: "Admin"
//   },
//   {
//     title: "Where does it come from?",
//     url: "/blog/bog-2",
//     subtitle: "1914 translation by H. Rackham",
//     createdAt: "Sept 15th, 2020",
//     image:
//       "https://res.cloudinary.com/mapspire/image/upload/c_fit,f_auto,w_750/v1/mapspire_web_upload/restaurant-3597677_1920_ldiz82",
//     description:
//       "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id",
//     wordCount: 100,
//     author: "Admin"
//   }
// ]

export const BlogList = (props) => {
  console.log("BlogList")
  const location = useLocation()
  return (
    <ListContainer>
      <ListWrapper>
        <BlogListItem
          image="https://res.cloudinary.com/mapspire/image/upload/c_fit,f_auto,w_750/v1/mapspire_web_upload/restaurant-3597677_1920_ldiz82"
          url={`${location.pathname}/save-your-business-with-customer-loyalty`}
          title="All it takes is a little push"
          description="How to “Nudge” Your Customers to Make Them
    Come Back."
        ></BlogListItem>
        {/* {entries &&
          entries.map((entry: any) => (
            <BlogEntry key={entry.createdAt} entry={entry} />
          ))} */}
      </ListWrapper>
      <Footer />
    </ListContainer>
  )
}
