import React from "react";
import { FullPageContainer } from "../../components/FullPageContainer";
import { Footer } from "../../components/footer";
import OgMeta from "../../components/OgMeta";
export const CarreersPage = (props) => {
  return (
    <FullPageContainer>
      <OgMeta />
      <div className="Container">
        <div className="Content">Careers</div>
      </div>

      <Footer />
    </FullPageContainer>
  );
};
