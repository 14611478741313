import React from "react"
import styled from "styled-components"

import { ClapModal } from "../../../components/ClapModal"
import { ItemDetailsPopup } from "../pages/ItemDetailsPopup"

const ItemTypeCardStyled = styled.div<any>`
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 30px 50px 0px rgba(45, 54, 95, 0.1);
  align-items: center;
  padding: 32px 16px;
  cursor: pointer;

  .details {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: yellow;
    display: none;
  }

  ${({ showDetails }) =>
    showDetails &&
    `
    .details {
        display:initial;
    }
  `}

  .name {
    font-weight: 600;
    font-size: 24px;
    text-align: center;
    color: #333;
  }

  .description {
    margin-top: 16px;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    color: #666;
  }
  .icon {
    width: 80px;
    height: 80px;
    margin: 16px;
    img {
      width: 100%;
      height: 100%;
    }
  }
`
export const ItemTypeCard = ({ itemType }) => {
  const [isShowingDetails, setShowDetails] = React.useState<any>(false)
  function handleClick() {
    console.log("inClick")
    setShowDetails(!isShowingDetails)
  }

  return (
    <ItemTypeCardStyled onClick={handleClick} showDetails={isShowingDetails}>
      {/* Item Card */}
      <div className="icon">
        {itemType.svgUrl && <img src={itemType.svgUrl} alt="Item svg" />}
      </div>
      <div className="name">{itemType.name}</div>
      <div className="description">{itemType.description}</div>

      {/* <div className="details"></div> */}
      <ClapModal
        isOpen={isShowingDetails}
        onCancel={() => {
          setShowDetails(false)
        }}
        title="Item details"
      >
        <ItemDetailsPopup itemType={itemType} />
      </ClapModal>
    </ItemTypeCardStyled>
  )
}
