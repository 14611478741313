import React, { useState } from 'react'

import { SectionFeedHoc } from '../api/SectionFeedHoc'
import { FeedItem2 } from '../components/FeedItem2'

export const RecommendationsFeed = (props) => {
  const [isSharing, setIsSharing] = useState(false)
  // componentDidMount() {
  //   this.props.onSearch();
  //   if (window) {
  //     window.addEventListener("scroll", this.handleOnScroll);
  //   }
  // }

  //  const  handleStartShare = (post) => {
  //     console.log("handleStartShare", post);
  //     this.setState({ hasSharePopup: true, sharePost: post });
  //   };
  //   handleCloseReshare = () => {
  //     this.setState({ hasSharePopup: false, sharePost: undefined });
  //   };

  // componentWillUnmount = () => {
  //   window.removeEventListener("scroll", this.handleOnScroll);
  // };
  // handleOnScroll = () => {
  //   // console.log("handleOnScroll");
  //   // http://stackoverflow.com/questions/9439725/javascript-how-to-detect-if-browser-window-is-scrolled-to-bottom
  //   var scrollTop =
  //     (document.documentElement && document.documentElement.scrollTop) ||
  //     document.body.scrollTop;
  //   var scrollHeight =
  //     (document.documentElement && document.documentElement.scrollHeight) ||
  //     document.body.scrollHeight;
  //   var clientHeight =
  //     document.documentElement.clientHeight || window.innerHeight;
  //   var scrolledToBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;
  //   if (scrolledToBottom && this.props.hasNextPage) {
  //     this.props.onLoadMore();
  //   }
  // };

  const renderList = (posts) => {
    return (
      <div className="RecommendationsFeed__List">
        {posts.map((post) => (
          <FeedItem2
            key={post.id}
            post={post}
            // onClickShare={this.handleStartShare}
            // onToggleBookmark={this.props.onToggleBookmark}
          />
        ))}
      </div>
    )
  }

  let { posts } = props
  if (posts) {
    posts = posts.map((p) => p.edge || p)
  }
  return (
    <div className="RecommendationsFeed">
      {/* <h3 onDoubleClick={this.props.onSearch}>Recommendations Feed</h3> */}

      {posts && posts.length === 0 && (
        <React.Fragment>
          We don't have any claps for this venue yet.
        </React.Fragment>
      )}

      {posts && posts.length > 0 && renderList(posts)}
      {/* <SharePopup
          post={this.state.sharePost}
          isOpen={this.state.hasSharePopup}
          onClose={this.handleCloseReshare}
        /> */}
    </div>
  )
}

const SectionFeedWithHoc: any = SectionFeedHoc(RecommendationsFeed)
export default SectionFeedWithHoc
