import { useEffect, useRef } from "react"
import styled from "styled-components"

import { ClapButton } from "./ClapButton"

function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return
        }
        handler(event)
        console.log("prevent", event)
        event.stopImmediatePropagation()
      }
      document.addEventListener("mousedown", listener)
      document.addEventListener("touchstart", listener)
      return () => {
        document.removeEventListener("mousedown", listener)
        document.removeEventListener("touchstart", listener)
      }
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]
  )
}

const ClapModalWrapper = styled.div<any>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  opacity: 0;

  transition: opacity 200ms ease;

  .overlay {
    background-color: #00000080;
    z-index: 1000;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  > .wrapper {
    position: absolute;
    z-index: 1001;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100% !important;
  }

  .modal {
    width: 250px;
    height: 250px;
    background-color: white;
    box-shadow: 0 30px 50px 0px rgba(45, 54, 95, 0.1);
    border-radius: 20px;

    transform: translateY(20px);
    transition: transform 250ms ease;
  }
  display: none;

  ${({ isOpen }) =>
    isOpen &&
    `
    display:initial !important;
    opacity:1;
    transition: opacity 200ms ease;
    
    .modal {
      transform: translateY(0) !important;
      transition: transform 250ms ease;
    }

  `}
`

export const ClapModalContent = ({ children, title, onCancel }) => {
  return (
    <>
      {/* <div
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div> */}

      <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        {/* <div className="sm:flex sm:items-start"> */}
        {/* <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                <svg
                  className="h-6 w-6 text-red-600"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                  />
                </svg>
              </div> */}
        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <h3
            className="text-xl leading-6 font-bold text-gray-900 text-center"
            id="modal-title"
          >
            {title}
          </h3>
          {children}
        </div>
        {/* </div> */}
      </div>
      <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        {/* {isOtpSent ? (
          <button
            type="button"
            onClick={onConfirmOtp}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
          >
            Confirm OTP
          </button>
        ) : (
          <button
            type="button"
            onClick={onLogIn}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
          >
            Request OTP
          </button>
        )} */}
        <ClapButton label="OK" onClick={onCancel} />
        {/* <button
              type="button"
              onClick={onCancel}
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Cancel
            </button> */}
      </div>
    </>
  )
}

export const ClapModal = ({ onCancel, children, isOpen, title }) => {
  const ref = useRef<any>()
  useOnClickOutside(ref, () => {
    console.log("Outside")
    onCancel()
  })
  return (
    <div
      className="fixed z-10 inset-0 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
      style={{ backgroundColor: "#00000090" }}
    >
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <div
          ref={ref}
          className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform 
            transition-all sm:my-8 sm:align-middle 
            w-full sm:w-3/4
            max-w-xl sm:max-w-lg sm:w-full"
        >
          {children}
        </div>
      </div>
    </div>
  )

  // return (
  //   <ClapModalWrapper isOpen={props.isOpen}>
  //     <div className="overlay" />

  //     <div className="wrapper">
  //       <div className="modal">{props.children}</div>
  //     </div>
  //   </ClapModalWrapper>
  // )
}
