import { ClapHereNav } from '../../../components/ClapHereNav'
import { LoadingWidget } from '../../../components/LoadingWidget'
import OgMeta from '../../../components/OgMeta'
import { VenueFeed } from '../components/VenueFeed'
import { FeedPageStyled } from './FeedPageStyled'
import { SectionFeed } from './SectionFeed'

export const FeedPage = (props) => {
  // componentDidMount() {
  //   if (window && window.scrollTo) {
  //     window.scrollTo(0, 0)
  //   }
  // }
  const { venue } = props

  const renderInner = () => {
    return (
      <div>
        <div className="row">
          <div className="col-md-8">
            <section
              className="MpsMdCard MpsMdCard--xl"
              style={{
                boxShadow: "0 8px 16px 1px rgba(60,64,67,0.30)",
                borderRadius: 6,
                marginBottom: 16,
                padding: 0
              }}
            >
              <div>
                <div className="" style={{ padding: 16, paddingBottom: 6 }}>
                  <div className="VenuePage__Name">{venue.name}</div>
                  <div className="VenuePage__Address">
                    {venue.city} {venue.street}
                  </div>
                  <div style={{ padding: "6px 0" }}>
                    <div className="VenuePage__categoryChip">Restaurant</div>
                  </div>
                </div>

                <div style={{ borderTop: "1px solid #ddd", padding: 16 }}>
                  Bottom element (show some more info)
                </div>
              </div>
            </section>

            <section className="MpsMdCard MpsMdCard--noShadow MpsMdCard--bordered MpsMdCard--xl">
              <div className="MpsMdCard-title VenuePage__name">
                Affiliate Program information (Benefits)
              </div>
            </section>

            <section className="MpsMdCard  MpsMdCard--noShadow  MpsMdCard--bordered MpsMdCard--xl">
              <div className="MpsMdCard-title VenuePage__name">
                Note from owner
              </div>
            </section>

            <section className="MpsMdCard--xl">
              <div
                className="MpsMdCard-title VenuePage__name"
                style={{ marginTop: 5, marginBottom: 5 }}
              >
                Feed
              </div>
              <VenueFeed venueId={venue.uid} />
            </section>
          </div>

          <div className="col-md-4">
            {/* <section className="MpsMdCard MpsMdCard--xl">
              <div className="MpsMdCard-title VenuePage__name">
                {venue.name}
              </div>
            </section> */}

            <section
              className=""
              style={{
                padding: 0,
                border: "1px solid #dadce0",
                borderRadius: 6,
                margin: 8
              }}
            >
              {/* <VenuePageMiniMap venue={venue} /> */}
              <div style={{ padding: 10 }}>
                <div className="row">
                  <div className="col-md-8">Get Grab for around 120THB</div>
                  <div className="col-md-4"> [GRAB] </div>
                </div>
                <div>Other tools to help get there</div>
              </div>
            </section>

            <section
              className="MpsMdCard MpsMdCard--xl"
              style={{ backgroundColor: "#ff5722", color: "white" }}
            >
              <div className="MpsMdCard-title VenuePage__name">
                CTA - GO NOW AND GET 15% BACK !
              </div>
            </section>

            <section className="MpsMdCard MpsMdCard--xl">
              <div className="MpsMdCard-title VenuePage__name">MENU</div>
            </section>
          </div>
        </div>
      </div>
    )
  }
  const renderGallery = () => {
    console.log("renderGallery")
    // const photos =
    //   this.props.venue && this.props.venue.photos ? this.props.venue.photos : []
    const photos: any = []
    for (let i = 0; i < 10; i++) {
      photos.push({
        url: "https://images.deliveryhero.io/image/fd-th/LH/u8gp-listing.jpg?width=400&height=292"
      })
    }
    return (
      <div className="VenuePage__Photos">
        {photos &&
          photos.length > 0 &&
          photos.map((p) => {
            return (
              <div className="VenuePage__Photos__Photo">
                {/* <img src={p.url} /> */}
                <div
                  style={{
                    backgroundImage: `url(https://images.deliveryhero.io/image/fd-th/LH/t0xf-hero.jpg?width=2000&height=500)`
                  }}
                />
              </div>
            )
          })}
        {/* {!photos ||
          (photos.length === 0 && 
            photos.map && (
              <div className="VenuePage__Photos__Photo">
              MoCK PHOTOS
            </div>)
          ))} */}
      </div>
    )
    // TODO - can mix with photos from recommendations
  }
  console.log("Render FeedPage", props)
  return (
    <FeedPageStyled>
      <ClapHereNav />
      <OgMeta />
      <div className="Wrapper">
        {/* {props.isLoading && <LoadingWidget />} */}
        {<SectionFeed />}
        <div style={{ textAlign: "center" }}>
          <LoadingWidget />
        </div>
      </div>
      {/* {this.renderGallery()} */}
      <section>{/* <Footer19 page="home" /> */}</section>
    </FeedPageStyled>
  )
}
