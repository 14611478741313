import React from 'react'
import styled from 'styled-components'

import { calculatePhotoUrl } from '../../../utils/imageUrl'

export interface VenueMedia {
  __typename: string
  id: string
  type: string
  mimeType: string
  urls: {
    w: string
    url: string
  }
}

export interface VenueMediaProps {
  media: VenueMedia[]
}

const Container = styled.div`
  height: 325px;
  width: 100%;
  display: flex;
  box-sizing: border-box;
  overflow: hidden;

  @media (max-width: 735px) {
    padding: 0;
    width: 100%;
    height: 40vh;
    margin: 0;
  }
`

// const InnerWrapper = styled.div`
//   display: flex;
//   flex-wrap: nowrap;

//   > div {
//     flex: 0 0 auto;
//     // display: inline;
//   }

//   @media only screen and (max-width: 640px) {
//     width: 100%;
//   }
//   @media (max-width: 735px) {
//     width: 100%;
//   }
// `

interface IPhotoProps {
  hasOne?: boolean
}

const Photo = styled.div<IPhotoProps>`
  // width: calc(1020px * 0.62);
  width: 100%;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;

  div {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    -o-object-fit: cover;
    object-fit: cover;
    height: 100%;
    background: no-repeat center;
    background-size: cover;
    display: block;
    height: 100%;
    position: relative;
    z-index: 1;
  }

  ${({ hasOne }: any) =>
    hasOne &&
    `
      background: white;
  `}

  @media only screen and (max-width: 640px) {
    width: 100%;
    margin: 0;
  }
  @media (max-width: 735px) {
    width: 100%;
    border-radius: 0px;
    margin: 0;
    div {
      border-radius: 0;
    }
  }
`

const VenuesMediaGallery: React.FC<VenueMediaProps> = ({ media }) => {
  const handlePhotoClick = (): void => {
    console.log("handlePhotoClick - scroll to photo")
  }

  return (
    <Container>
      {media &&
        media.length > 0 &&
        media.map((p, idx) => {
          return (
            <Photo
              key={idx}
              hasOne={media && media.length === 1}
              onClick={handlePhotoClick}
            >
              <div
                style={{
                  backgroundImage: `url(${calculatePhotoUrl(p, 800).url})`
                }}
              />
            </Photo>
          )
        })}
    </Container>
  )
  // TODO - can mix with photos from recommendations
}

export default VenuesMediaGallery
