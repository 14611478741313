import { useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { CTAContainer } from '../pages/VenueDetailStyled'
import { OrderOnMobilePopup } from './OrderOnMobilePopup'

export const isMobile = () => {
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    // true for mobile device
    return true
  } else {
    // false for not mobile device
    return false
  }
}
export const VenueOrderNowButton = ({ venue }) => {
  const [isShowingPopup, setIsShowingPopup] = useState(false)
  const handleOrderNow = () => {
    var mobile = isMobile()
    console.log("handleOrderNow", mobile)

    if (mobile) {
      // var url = `https://claphere.onelink.me/UAtl/wvoss?venueId=${venue.id}&action=order&url=/venues/${venue.id}`
      var url = `https://claphere.app.link/th/shop?clap_venue_id=${
        venue.slug ?? venue.id
      }&clap_action=order&~channel=claphere.com`
      // url += '?$deeplink_path'
      // https://claphere.onelink.me/UAtl?pid=website&c=Website%20venue%20-%20order%20same%20same&deep_link_value=wvoss&venueId=0&action=0

      // var url = `https://claphere.app.link/th/en/shops/bangkok/creamery-boutique-ice-creams`
      if (window) {
        window.open(url, "_blank")
      }
    } else {
      setIsShowingPopup(true)
    }
  }

  return (
    <div className="w-full">
      <CTAContainer onClick={handleOrderNow}>
        {/* transform hover:scale-110 motion-reduce:  transform-none */}
        <FormattedMessage id="orderNow" defaultMessage="Order Now" />
      </CTAContainer>

      {isShowingPopup && (
        <OrderOnMobilePopup
          isOpen={isShowingPopup}
          venue={venue}
          onCancel={() => setIsShowingPopup(false)}
        />
      )}
    </div>
  )
}
