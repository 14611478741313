import { calculatePhotoUrl } from "./imageUrl"

/**
 * Utils to generate Open Graph meta (for facebook and others)
 */
export class OgUtils {
  static defaultMeta: OgData = {
    url: "https://www.claphere.com",
    siteName: "ClapHere",
    title: "ClapHere - Recommendations with Benefits"
  }

  static getDefaultOg() {
    return this.defaultMeta
  }

  static getRootOg = {
    // const locale = 'en';
  }

  static generateOgForMedia(media) {
    console.log("generateOgForMedia", media)
    if (!media || media.length === 0) {
      return {}
    }
    const list: any = []
    media.map((item: any) => {
      const res = calculatePhotoUrl(item, 1200)
      list.push({ url: res.url, width: 1080 })
      // item.urls.map((image) => {
      //   list.push({ url: image.url, width: image.w })
      // })
    })

    return { images: list }
  }

  static generateOgForVenue(venue): OgData {
    if (!venue) {
      return this.defaultMeta
    }
    const ogData: OgData = {
      url: "https://www.claphere.com/shops/" + venue.id,
      type: "restaurant",
      title: venue.name + " on ClapHere",
      description: venue.description,
      siteName: venue.name + " on ClapHere",
      alIosUrl: "claphere://shop/" + venue.id,
      alIosAppName: "ClapHere",
      alAndroidUrl: "claphere://shop/" + venue.id,
      alAndroidAppName: "ClapHere",
      ...OgUtils.generateOgForMedia(venue.media)
    }
    // console.log(ogData)
    return ogData
  }

  static generateOgForProduct(product, venue, url): OgData {
    if (!product) {
      return this.defaultMeta
    }
    const ogData: OgData = {
      url,
      // url: "https://www.claphere.com/shops/" + venue.id,
      type: "product",
      title: product.name + " - " + venue.name + " on ClapHere",
      description: product.description
        ? product.description + " - from " + venue.name
        : venue.description,
      siteName: product.name + " - " + venue.name + " on ClapHere",
      alIosUrl: "claphere://shop/" + venue.id + "/product/" + product.id,
      alIosAppName: "ClapHere",
      alAndroidUrl: "claphere://shop/" + venue.id + "/product/" + product.id,
      alAndroidAppName: "ClapHere",
      ...OgUtils.generateOgForMedia(product.media)
    }
    // console.log(ogData)
    return ogData
  }

  static generateOgForPost(post, localeParams): OgData {
    if (!post) {
      return this.defaultMeta
    }

    const ogData: OgData = {
      url:
        `https://www.claphere.com/${localeParams.country}/${localeParams.lang}/posts/` +
        post.id,
      type: "post",
      title: post.contentText,
      description:
        "ClapHere Post " +
        (post.authorUser ? `by ${post.authorUser.name}` : ""),
      siteName: "ClapHere",
      alIosUrl: "claphere://posts/" + post.id,
      alIosAppName: "ClapHere",
      alAndroidUrl: "claphere://post/" + post.id,
      alAndroidAppName: "ClapHere",
      ...OgUtils.generateOgForMedia(post.media || {})
    }
    return ogData
  }
}

export type OgData = {
  url?: string
  type?: string
  title?: string
  description?: string
  siteName?: string
  alIosUrl?: string
  alIosAppName?: string
  alAndroidUrl?: string
  alAndroidAppName?: string
  images?: any
}
