import styled from "styled-components"

export const HeaderMenuItemStyled = styled.div<any>`
  margin: 0 8px;
  cursor: pointer;
  color: white;
  font-size: 16px;
  font-weight: 500;
  margin: auto 16px;

  &:hover {
    color: #6c6d73;
  }

  a {
    text-decoration: none;
    color: white;
  }

  @media (max-width: 800px) {
    font-size: 12px;
    display: initial;
    margin: 0px 6px;
    font-weight: normal;

    .Label {
      display: none;
    }
    .Icon {
      display: initial;
    }

    .content {
      left: 0 !imrpotant;
    }
  }

  ${(props) =>
    props.selected &&
    `
    font-weight: bold;
    color: #FF7C00;
    a {
        color: #FF7C00;
    }
`}
`

export const ClapHereNavStyled = styled.header<any>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 0px;

  align-items: center;
  box-sizing: border-box;
  top: 0;
  width: 100%;
  z-index: 10;
  position: fixed;
  top: 0;
  width: 100%;
  height: var(--clap-header-height);

  .MobileNavWrapper {
    display: none;
  }
  > .content {
    position: absolute;
    bottom: 0;
    left: 70px;
    top: 0;
    right: 70px;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 30px;
    min-width: 800px;
    margin: 0 70px;
    align-items: center;
  }

  @media (max-width: 725px) {
    max-width: 100% !important;
    min-width: 100% !important;
    padding: 32px 16px;

    .content {
      min-width: initial;
      margin: 0 20px !important;
      gap: 10px !important;
      left: 0 !important;
      right: 0 !important;

      > .PrimaryNav {
        display: none !important;
      }

      > .LangSelect {
        display: none !important;
      }
      .Burger {
        color: white;
        display: initial;
        grid-column: 12 / 12;
        grid-row: 1;
      }
    }

    .HeaderLeft {
      img {
        width: 40px !important;
        height: 40px !important;
      }
    }
  }
  margin: auto;

  .LogoWrapper {
    grid-column: 1/1;
    grid-row: 1;
    height: 60px;
    text-decoration: none !important;
  }

  .LangSelect {
    color: white;
    grid-column: 12 / 12;
    grid-row: 1;
    font-size: 14px;
    font-weight: 900;
    > .Item {
      cursor: pointer;
    }
  }

  .PrimaryNav {
    grid-column: 3 / 11;
    grid-row: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  a {
    text-decoration: none !important;
  }
  .Burger {
    display: none;
  }
  .HeaderLeft {
    height: 100%;
    color: white;
    display: flex;
    align-content: center;
    align-items: center;
    padding: 6px 0;
    text-decoration: none !important;

    img {
      height: 100%;
      box-sizing: border-box;
      padding: 5px 0;
      margin-right: 16px;
    }

    > .name {
      font-weight: 900;
      font-size: 26px;
      text-decoration: none !important;
    }
  }

  @media only screen and (max-width: 640px) {
    padding: 16px 8px;
    .PrimaryNav {
      &__Burger {
        display: initial;
      }
    }

    .MobileNavWrapper {
      display: initial;
      .MobileNav {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        background-color: white;
        transform: translateX(100vw);
        transition: translate 150ms ease-in;
        padding: 20px;
      }
    }

    .MobileNavWrapper.visible {
      .MobileNav {
        display: flex !important;
        transform: translateX(0);
        color: #333;
        flex-direction: column;
        top: var(--clap-header-height);

        > .menuItem {
          padding: 16px;
          color: #333;
        }
      }
    }
    .HeaderMenuItem {
      display: none;
    }
    .Header {
      background: white;
      z-index: 100;
      box-shadow: 0 10px 20px #dedfe4;
      padding: 10px;
    }

    .HomeSectionFeedPreview {
      flex-direction: column;
      padding: 0 20px;
    }
    .HomeSection {
      h2 {
        font-size: 30px;
      }
    }
  }

  @media (max-width: 800px) {
    .HeaderLeft {
      img {
        padding: 4px;
        width: 100px;
        margin-left: 0;
      }
      > .name {
      }
    }
  }
`
