import { FormattedMessage } from "react-intl"

const TextWrapper = ({ children }) => (
  <div
    className="
    w-3/4 sm:w-80 text-center"
  >
    {children}
  </div>
)
const TextP = ({ children }) => (
  <p
    className="mb-6 
    text-xl sm:text-2xl"
  >
    {children}
  </p>
)

const PointTitle = ({ children }) => (
  <div
    className="
my-4 sm:my-6 
mb-8 
sm:w-64
text-4xl sm:text-5xl 
font-italic text-italic italic text-center"
    style={{
      color: "var(--clap-color-orange)",
      fontWeight: 900
    }}
  >
    {children}
  </div>
)

const PointIcon = ({ url, alt }) => (
  <img className="w-56 h-56" src={url} alt={alt} />
)

const SectionWrapper = ({ children }) => (
  <div className="flex flex-col sm:flex-row items-center">{children}</div>
)

const LeftWrapper = ({ children }) => (
  <div
    className="flex flex-row items-end 
  justify-center sm:justify-end"
    style={{ flex: 8 }}
  >
    {children}
  </div>
)
const RightWrapper = ({ children }) => (
  <div className="" style={{ flex: 7 }}>
    {children}
  </div>
)

const Left = () => (
  <LeftWrapper>
    {/* <div className="relative mb-16"> */}
    <img
      className="w-2/3"
      src="/assets/home4/website-mock.png"
      alt="Website example"
    />
    {/* <div className="absolute w-96" style={{ top: 80, left: 130 }}>
        <img src="/assets/home4/menu-mock.png" alt="Website example" />
      </div> */}
    {/* </div> */}
  </LeftWrapper>
)

const Right = () => (
  <RightWrapper>
    {/* <div
    className="flex-1 sm:w-32 m-auto flex flex-col  items-end"
    style={
      {
        // backgroundColor: "yellow"
      }
    }
  > */}
    <div className="p-8 sm:pr-32 flex flex-col items-center">
      <div className="">
        <PointIcon
          url="/assets/home4/register_free.png"
          alt="Register for FREE"
        />
      </div>

      <PointTitle>Your menu online for FREE</PointTitle>
      <TextWrapper>
        <TextP>
          Start your digital presence with <strong>FREE digital menu</strong>.
        </TextP>
        <TextP>Create your digital storefront in few minutes.</TextP>
        <TextP>If you need, you can also bring your own domain</TextP>
      </TextWrapper>
    </div>
  </RightWrapper>
)
const Left2 = () => (
  <div
    className="flex-1"
    style={{
      overflow: "hidden"
      //   backgroundColor: "pink"
    }}
  >
    <div className="relative mb-16">
      <div
        className="flex 
        flex-row sm:flex-row"
      >
        <div className="">
          <img
            src="/assets/home4/ordering-1.png"
            alt="Website example"
            className="inline h-96"
          />
        </div>
        <img
          src="/assets/home4/ordering-2.png"
          alt="Website example"
          className="inline h-96"
        />
        <img
          className="inline h-96"
          src="/assets/home4/ordering-3.png"
          alt="Website example"
        />
      </div>
    </div>
  </div>
)

const Right2 = () => (
  <div
    className="flex-1"
    style={
      {
        //   backgroundColor: "pink"
      }
    }
  >
    <div
      className="
       relative 
       mb-8 sm:mb-16
       p-4 sm:p-0
       flex flex-col items-center"
    >
      <div className="">
        <PointIcon url="/assets/home4/zero_gp.png" alt="Zero GP" />
      </div>

      <div
        className="my-4 
        mb-8 
     
        text-4xl 
        font-italic text-italic italic text-left"
        style={{
          color: "var(--clap-color-orange)",
          fontWeight: 900
        }}
      >
        Direct ordering
      </div>
      <TextWrapper>
        <TextP>We reinvented Digital Ordering.</TextP>

        <TextP>
          No more commissions from your profit. <br />
          We introduce 0% GP
        </TextP>

        <TextP>
          We put you back in control - you decide about your pricing. <br />
          You can do self-delivery or use integration with logistic providers.
        </TextP>
      </TextWrapper>
    </div>
  </div>
)

const Left3 = () => (
  <div
    className="flex-1 sm:w-32 m-auto flex flex-col  items-end"
    style={
      {
        // backgroundColor: "yellow"
      }
    }
  >
    <div className="p-8 sm:pr-32 flex flex-col items-center">
      <div className="">
        <PointIcon
          url="/assets/home4/customers_icon.png"
          alt="Your customers"
        />
      </div>

      <div
        className="my-4 mb-8 
        text-4xl font-italic text-italic italic text-center"
        style={{
          color: "var(--clap-color-orange)",
          fontWeight: 900
        }}
      >
        Your customer
      </div>
      <TextWrapper>
        <TextP>
          Use our Digital Hospitality tools to be closer to your customers.
        </TextP>
        <TextP>Run your marketing campaigns</TextP>
        <TextP>And with ClapHere - you always own the data.</TextP>
      </TextWrapper>
    </div>
  </div>
)

const Right3 = () => (
  <div
    className="flex-1"
    style={
      {
        //   backgroundColor: "pink"
      }
    }
  >
    <div className="relative mb-16 overflow-hidden">
      <img
        className="w-96"
        src="/assets/home4/website-mock.png"
        alt="Website example"
      />
      <div className="absolute w-96" style={{ top: 80, left: 130 }}>
        <img src="/assets/home4/menu-mock.png" alt="Website example" />
      </div>
    </div>
  </div>
)

export const HomeSectionRestaurants = (props) => {
  return (
    <div
      style={{
        backgroundColor: "#FCEEEB"
      }}
    >
      <div className="container m-auto">
        <div
          className="py-2 
          sm:py-12"
        >
          <div
            className="
            italic 
            text-4xl sm:text-7xl 
            p-4 sm:p-0
            m-auto text-center"
            style={{
              color: "var(--clap-color-violet)",
              fontWeight: 900,
              maxWidth: "960px"
            }}
          >
            Putting restaurants back in control of their online orders
          </div>
        </div>
        <SectionWrapper>
          <Left />
          <Right />
        </SectionWrapper>
        <SectionWrapper>
          <Left2 />
          <Right2 />
        </SectionWrapper>
        <SectionWrapper>
          <Left3 />
          <Right3 />
        </SectionWrapper>
      </div>
    </div>
  )
}
