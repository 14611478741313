import React from 'react'
import { match, useLocation, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'

import { ClapListItem } from '../../../components/ClapListItem'
import { Footer } from '../../../components/footer'
import { FullPageContainer } from '../../../components/FullPageContainer'
import OgMeta, { PostOgMeta } from '../../../components/OgMeta'
import { PageContainer } from '../../../components/PageContainer'
import { PhotoWithTags } from '../../../components/PhotoWithTags'
import { ShareBar } from '../../../components/ShareBar'
import { useScrollToTop } from '../../../utils/useScrollToTop'
import { FeedCardRoman } from '../../feed/components/FeedCardRoman'
import { OrderSameButton } from '../../venues/components/OrderSameButton'
import { usePostQuery } from '../api/usePostQuery'
import { AuthorChip } from '../components/AuthorChip'

interface UserPageMatch {
  postId?: string
}

const PostPageStyled = styled(FullPageContainer)`
  .Text {
    margin: 8px 0;
  }
  .Section {
    margin: 8px 0;
  }
`

const PostPageTagsStyled = styled.div`
  .Tile {
    paddin: 8px 16px;
    background-color: #ff7c00;
    font-size: 16px;
    color: white;
  }
`

const Photos = styled.div`
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;

  @media (max-width: 735px) {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
    img {
      height: 100%;
      left: 0;
      top: 0;
    }
  }
`
export const PostGallery = ({ post }) => {
  if (post && post.media && post.media.length > 0) {
    return <PhotoWithTags photo={post.media[0]} tags={post.tags} />
  }
  return <span />
}

export const SectionAuthor = ({ post }) => {
  let formattedDate
  if (post && post.publishedAt) {
    // TODO FIX
    // formattedDate = format(parse(post.publishedAt), "YYYY-MM-DD HH:mm")
  }
  return (
    <div className="Author">
      {/* <div className="FeedItem__author__hs">
            {/* {post.author && <Headshot user={post.author} size="sm" />} 
          </div> */}
      <AuthorChip user={post.authorUser} />
      {post.type === "consumption"
        ? " just consumed based on recommendation "
        : " clapped for "}
      {post.publishedAt && <span> @ {formattedDate}</span>}
    </div>
  )
}
export const PostPage = (props) => {
  const location = useLocation()

  useScrollToTop()
  const pmatch: match<UserPageMatch> = useRouteMatch<UserPageMatch>()

  const { post, loading, data, error } = usePostQuery(pmatch.params.postId)

  const cantFind = !post && !loading

  const renderTags = () => {
    if (post.tags && post.tags.length > 0) {
      return (
        <PostPageTagsStyled>
          <h4 className="Title">Tagged in this post</h4>
          {post.tags.map((tag) => {
            return <ClapListItem title={tag.product?.name}></ClapListItem>
          })}
        </PostPageTagsStyled>
      )
    }

    return <span />
  }

  console.log("Post page", location, pmatch)
  const renderActions = () => {
    const shareQuote = `${
      post.authorUser ? post.authorUser.name : ""
    } clapped for ${post.venue?.name} \n ${post.contentText}`

    return (
      <div className="Right">
        <h4 className="Title">Share</h4>
        <ShareBar
          url={"https://www.claphere.com" + location.pathname}
          quote={shareQuote}
        />
        {renderTags()}

        <div style={{ textAlign: "center" }}>
          <OrderSameButton post={post} />
        </div>
      </div>
    )
  }
  return (
    <PageContainer>
      <PostOgMeta post={post} />
      <div className="container m-auto max-w-screen-lg mb-8 pt-8">
        {post && <FeedCardRoman post={post} />}
        {/* // <ClapCardJuly>
        //   <div className="Container">
        //     <Photos>
        //       <PostGallery post={post} />
        //     </Photos>

        //     {cantFind && <div>"Can't find this post"</div>}
        //     {post && (
        //       <React.Fragment>
        //         <div className="Left">
        //           <div className="my-4">
        //             <SectionAuthor post={post} />
        //           </div>
        //           <div className="Venue">
        //             {post && <VenueChip venue={post.venue} />}
        //           </div>

        //           <div className="Text Section">"{post.contentText}"</div>
        //         </div>

        //         {renderActions()}
        //       </React.Fragment>
        //     )}
        //   </div>
        // </ClapCardJuly> */}
      </div>
      <Footer />
    </PageContainer>
  )
}
