import { useParams } from "react-router-dom"

// : {
//   country: string
//   lang: string
//   locale: string
// }
export const useLocale = () => {
  const routeParams = useParams<any>()
  // console.log("useLocale.route params", routeParams)
  var country = (routeParams.country ?? "th").toLowerCase()
  var lang = (routeParams.lang ?? "th").toLowerCase()
  const langs = ["th", "en", "pl", "cn", "jp"]
  // console.log(country, lang)
  if (langs.indexOf(lang) === -1) {
    lang = "th"
  }
  if (langs.indexOf(country) === -1) {
    country = "th"
  }
  // console.log(country, lang)
  return { country, lang: lang, locale: lang + "_" + country.toUpperCase() }
}
