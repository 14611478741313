import { faUser } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export const Headshot = ({ user }: { user?: any }) => {
  return (
    <div className="flex flex-row justify-center">
      <div
        className="w-16 h-16 
        bg-gray  flex flex-row justify-center items-center"
        style={{
          backgroundColor: "#f0f0f0",
          borderRadius: 100
        }}
      >
        <div>
          <FontAwesomeIcon icon={faUser} />
        </div>
      </div>
    </div>
  )
}
