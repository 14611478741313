import { first, last } from 'lodash'

// async function WebpIsSupported() {
//   // If the browser doesn't has the method createImageBitmap, you can't display webp format
//   console.log('WebpIsSupported')
//   if (!createImageBitmap) return false;

//   // Base64 representation of a white point image
//   const webpData = 'data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAEAAQAcJaQAA3AA/v3AgAA=';

//   // Retrieve the Image in Blob Format
//   const blob = await fetch(webpData).then(r => r.blob());

//   // If the createImageBitmap method succeeds, return true, otherwise false
//   return createImageBitmap(blob).then(() => true, () => false);
// }

function support_format_webp() {
  if (typeof document === "undefined") {
    return false
  }
  var elem = document ? document.createElement("canvas") : undefined

  if (elem && !!(elem.getContext && elem.getContext("2d"))) {
    // was able or not to get WebP representation
    return elem.toDataURL("image/webp").indexOf("data:image/webp") === 0
  } else {
    // very old browser like IE 8, canvas not supported
    return false
  }
}

export const calculatePhotoUrl = (photo: any, size: number = 200): any => {
  const hasWebp = support_format_webp()
  // console.log("calculatePhotoUrl, has webp: ", hasWebp)
  let declaredWidth = size
  if (!photo) {
    return undefined
  }
  if (!photo.urls || photo.urls.length === 0) {
    return photo
  }
  const urls = [...photo.urls]

  urls.sort((a: any, b: any): number => {
    return a.w - b.w
  })
  const urls2 = urls.filter((a) => a.w >= declaredWidth)
  var result
  if (urls2 && urls2.length > 0) {
    result = { ...first(urls2) }
  } else {
    result = { ...last(urls) }
  }

  if (!hasWebp && result?.url) {
    result.url = result.url.replace(".webp", ".png")
  }
  return result
}
