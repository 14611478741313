import useAxios from 'axios-hooks'

export const useVenueFeed = (venueId) => {
  const url =
    process.env.REACT_APP_CLAPHERE_REST_API +
    "/posting/v2/CLAP/venues/" +
    venueId +
    "/feed"
  const [{ data, loading, error }, refetch] = useAxios(url, {
    useCache: false
  })

  let posts
  if (data && data.edges) {
    posts = data.edges.map((post) => post.edge)
  }
  // console.log("posts", posts, "data", data)
  return { loading, data, error, posts }
}
