import { useState, useEffect } from "react"
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch
} from "react-router"
import { PhotosScroll } from "../../../components/PhotoScroll"
import { PriceWidget } from "../../../components/PriceWidget"
import { VenueMenuItemPopup } from "../menu/VenueMenuItemPopup"

const Product2 = ({ product, onClick }) => (
  <div
    onClick={onClick}
    className="
    group
    flex
    flex-col
    mb-2 sm:mb-0
    cursor-pointer
    hover:bg-gray-50 
    hover:shadow-sm
    shadow-sm
  "
    style={{
      boxShadow: "0 4px 4px 0px #EBF1F5",
      borderRadius: 25
    }}
  >
    <div
      className="
         w-full h-36
         xl:w-full xl:h-60
         rounded-t-3xl
         mr-2 overflow-hidden"
    >
      <PhotosScroll photos={product.media} width={100} />
    </div>

    <div
      className="
        h-28
        flex flex-row
        p-3
        overflow-hidden
      "
    >
      <div className="flex-1">
        <div style={{ color: "#2E266F" }} className="font-semibold">
          {product.name}
        </div>
        <div style={{ color: "#707070" }} className="text-sm">
          {product.description}
        </div>
      </div>
      <div style={{ color: "#FA6400" }} className="font-bold">
        <PriceWidget value={product.price} currencyCode="THB" />
      </div>
    </div>
  </div>
)
const Product = ({ product }) => {
  return (
    <div
      className="
        group 
        flex flex-row 
        mb-2 sm:mb-4 
        mt-2 
        cursor-pointer 
        hover:bg-gray-50 
        hover:shadow-sm
        rounded-xl
        p-2"
    >
      <div
        className="
            w-24 h-24
         xl:w-36 xl:h-36
         mr-2 rounded-md overflow-hidden"
      >
        <PhotosScroll photos={product.media} width={100} />
      </div>

      <div className="flex-1 flex  flex-col">
        <div
          className="flex flex-row 
        -mt-1 sm:mt-0
         mb-1 sm:mb-0"
        >
          <div
            className="
            leading-tight sm:leading-normal 
            flex-1 
            font-bold 
            mr-4"
          >
            {product.name}
          </div>

          <div
            className="
            leading-tight sm:leading-normal 
            text-xl font-black "
          >
            <PriceWidget value={product.price} currencyCode="THB" />
          </div>
        </div>

        <div className="text-xs sm:text-sm sm:mt-2 flex-1">
          {product.description}
        </div>
        <div className="h-6 group">
          <div className="group-hover:visible hidden">Order</div>
        </div>
      </div>
    </div>
  )
}
const Category = ({ category, menu, onClick }) => {
  const productsForCategory = menu
    ? menu.products.filter((p) => p.categoryId === category.id)
    : []

  if (productsForCategory.length === 0) {
    return <span />
  }
  return (
    <div>
      <div className="my-2 text-xl font-bold border-b mb-6">
        {category.name}
      </div>
      <div
        className="grid 
        grid-cols-1 sm:grid-cols-2 xl:grid-cols-3
        gap-4 sm:gap-6 xl:gap-8
        mb-6 "
      >
        {productsForCategory.map((product: any) => (
          <Product2
            product={product}
            onClick={(event: any) => onClick(product)}
          />
        ))}
      </div>
    </div>
  )
}

const VenueProductInner = ({ menu, onCancel, venue }) => {
  const route = useRouteMatch()
  const params = useParams<any>()
  const product = menu.products.find((p) => p.id === params.id)
  return (
    <VenueMenuItemPopup product={product} onCancel={onCancel} venue={venue} />
  )
}

export const VenueMenuNew = ({ venue }: { venue: any }) => {
  const [rootUrl, setRootUrl] = useState<any>()
  const [initialTitle, setInitialTitle] = useState<any>()
  const location = useLocation()
  const history = useHistory()
  const route = useRouteMatch()

  const _setRootUrl = (url) => {
    if (url.lastIndexOf("/") === url.length - 1) {
      url = url.substr(0, url.length - 1)
    }
    setRootUrl(url)
  }
  useEffect(() => {
    console.log("Venue menu", route)
    _setRootUrl(route.url)
    if (typeof document !== "undefined") {
      setInitialTitle(document.title)
    }
  }, [])
  useEffect(() => {
    console.log("route.url changed", route.url)
    _setRootUrl(route.url)
  }, [route.url])
  if (!venue) {
    return <div />
  }
  const handleSelectProduct = (product) => {
    // window.history.replaceState(
    //   null,
    //   product.name,
    //   `${rootUrl}/product/${product.id}`
    // )
    history.push(`${rootUrl}/product/${product.id}`)
  }
  const closeProductPopup = () => {
    history.push(`${rootUrl}`)
    // window.history.replaceState(null, initialTitle, rootUrl)
  }
  var path = `${route.url}/product/:id`
  console.log("path", path)
  return (
    <>
      <div className="pb-4">
        <div
          className="text-center text-3xl font-medium py-2 sm:py-4 tracking-wider"
          style={{
            color: "var(--clap-color-violet)"
          }}
        >
          MENU
        </div>
        {venue.menu?.productCategories.map((category) => (
          <Category
            key={category.id}
            category={category}
            menu={venue.menu}
            onClick={handleSelectProduct}
          />
        ))}
      </div>
      <Switch>
        <Route path={`${rootUrl}/product/:id`}>
          <VenueProductInner
            menu={venue.menu}
            venue={venue}
            onCancel={() => {
              console.log("On cancel", location.pathname, rootUrl)
              history.push(rootUrl)
            }}
          />
        </Route>
      </Switch>
    </>
  )
}
