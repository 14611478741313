// import { useQuery } from "@apollo/client";
import useAxios from '../../../axios/axios-hooks'

// const qlGetPost = gql(
//   `query post($postId: String!)
//     { post(postId:$postId)
//       {
//         id
//         type
//         publishedAt
//         contentText
//         authorUser {
//           id name
//           profilePhoto { url }
//         }
//         venue { id name merchant { id name } }
//         media { id type mimeType urls { w url } }
//         tags {
//           id
//           mediaId
//           productId
//           product { id name }
//           type
//           label
//           x
//           y
//         }
//       }
//     }`
// )

export const usePostQuery = (postId) => {
  const url =
    process.env.REACT_APP_CLAPHERE_REST_API +
    "/posting/v2/CLAP/post/" +
    postId +
    "?role=public"
  const [{ data, loading, error }, refetch] = useAxios(url, {
    // useCache: false
  })
  // const { data, loading, error } = useQuery(qlGetPost, {
  //   variables: { postId },
  //   fetchPolicy: "cache-and-network"
  // });

  // const post = data && data.post ? data.post : undefined;

  return { loading, data, error, post: data, refetch }
}
