import styled from "styled-components"
import { Link } from "react-router-dom"
import ClapTypo from "../../../components/ClapTypo"

const AuthorChipStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .PhotoWrapper {
    width: 80px;

    img {
      width: 56px;
      height: 56px;
      object-fit: cover;
      border-radius: 50px;
      margin-right: 16px;
    }
  }

  .AuthorName {
    font-size: 22px;
    flex: 1;
    a {
      text-decoration: none;
      color: initial;
    }
  }

  @media (max-width: 725px) {
    .AuthorName {
      font-size: 14px;
    }
  }
`

export const AuthorChip = ({ user }) => {
  return (
    <AuthorChipStyled>
      <div className="PhotoWrapper">
        {user.profilePhoto && user.profilePhoto.url && (
          <img src={user.profilePhoto.url} />
        )}
      </div>
      <ClapTypo.HeaderVenuePageName>
        <Link to={`/user/${user.id}`}>{user.name}</Link>
      </ClapTypo.HeaderVenuePageName>
    </AuthorChipStyled>
  )
}
