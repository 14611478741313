import React from "react"
import styled from "styled-components"

import { ClapTitle } from "../../../components/ClapTitle"

const MAX_LENGTH = 250

const Row = styled.div`
  display: flex;
  @media (max-width: 735px) {
    display: block;
  }
`

const Column = styled.div`
  flex-direction: column;
  padding: 10px;
  flex: 50%;
`

const Img = styled.img`
  max-width: 500px;
  border-radius: 16px;
  @media (max-width: 735px) {
    max-width: 300px;
  }
`

const Container = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: row;
  box-shadow: 0 10px 20px #dbdbdb;
  border-radius: 16px;
  cursor: pointer;
  transition: background-color 150ms;

  :hover {
    background-color: #fafafa;
  }
`
const Wrapper = styled.div`
  a {
    text-decoration: none;
    color: initial;
  }
`

type BlogListItemProps = {
  title: string
  url: string
  subtitle?: string
  createdAt?: string
  image?: string
  description?: string
  wordCount?: Number
  author?: string
}

export const BlogListItem: React.FC<BlogListItemProps> = (props) => {
  const { title, url, description, image } = props

  return (
    <Wrapper>
      <a href={url}>
        <Container>
          <Row>
            <Column>{image && <Img src={image} alt="clap blog" />}</Column>
            <Column>
              <ClapTitle type="h1" style={{ marginTop: 0, fontWeight: 900 }}>
                {title}
              </ClapTitle>
              <div style={{ color: "var(--clap-text-color-secondary" }}>
                2020-10-15
              </div>
              <Description>
                {`${description?.substring(0, MAX_LENGTH)} ...`}
              </Description>
            </Column>
          </Row>
          {/* <Row>
        {entry.author} | {entry.createdAt}
      </Row> */}
        </Container>
      </a>
    </Wrapper>
  )
}

export const Description = styled.p`
  max-width: 50ch;
  color: var(--clap-text-color-primary);
  @media (max-width: 735px) {
    width: 100%;
  }
`
export const ImageWrapper = styled.div`
  width: 80%;
  height: 40px;
  margin: auto;

  @media (max-width: 735px) {
    width: 100%;
    height: 140px;
    border-radius: 0;
    padding: 8px 16px;
  }
`
