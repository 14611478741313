import React from 'react'
import styled from 'styled-components'

import { useItemType } from '../api/useItemTypes'

const ItemDetailsPopupStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .name {
    font-size: 20px;
    font-weight: 600;
    padding: 16px;
  }

  .title {
    text-align: center;
    padding: 16px;
  }

  .svg {
    width: 100px;
    height: 100px;
  }
`
export const ItemDetailsPopup = ({ itemType }) => {
  const loadedDetails = useItemType(itemType.id)

  const detailedItemType = loadedDetails.itemType || itemType
  return (
    <ItemDetailsPopupStyled>
      {/* <div className="title">Item</div> */}
      <div className="name">{detailedItemType.name}</div>
      {detailedItemType.svgUrl && (
        <img className="svg" src={detailedItemType.svgUrl} alt="Item svg" />
      )}
      <div style={{ width: "100%", padding: 8 }}>
        <strong>Where to get</strong>
      </div>

      {/* <div>
        Where to get:
      </div> */}
    </ItemDetailsPopupStyled>
  )
}
