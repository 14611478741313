import React from "react"
import { Link } from "react-router-dom"

import { PhotoWithTags } from "../../../components/PhotoWithTags"
import { PostTools } from "../../posts/components/PostTools"
import { VenueChip } from "../../posts/components/VenueChip"
import { FeedItem2Styled, LinkContainer } from "./FeedItem2Styled"

// import { Headshot } from "mps-components";
// import VenueChip from "../../v19/components/venue-chip.jsx";

export class FeedItem2 extends React.PureComponent<any, any> {
  handleCloseShare = () => {
    console.log("handleCloseShare")
    this.setState({ hasSharePopup: false })
  }

  renderComments() {
    return (
      <div className="Comments">
        <div className="InputWrapper">
          {/* <div className={style.FI__Comments__InputWrapper}> */}
          <textarea placeholder="Write a comment..." rows={1} />
          {/* <div className="FeedItem__AddComment__Label">

          </div>
          <div className="FeedItem__AddComment__Content" /> */}
          {/* </div> */}
        </div>
      </div>
    )
  }
  render() {
    const { post } = this.props
    let formattedDate
    // console.log("Render Feed Item", this.props)
    if (post && post.publishedAt) {
      // Krishnal FIX
      formattedDate = "FIX"
      // formattedDate = format(parse(post.publishedAt), "YYYY-MM-DD HH:mm")
    }
    // console.log("formattedDate", formattedDate, "post", post)
    return (
      <FeedItem2Styled>
        <Link to={`/posts/${post.id}`}>
          <LinkContainer>
            <div className="Photo__Wrapper">
              <div className="Photo">
                {post.media && post.media.length > 0 && (
                  <PhotoWithTags photo={post.media[0]} tags={post.tags} />
                )}
              </div>
            </div>
            <div className="FeedItemContent">
              <div className="Author">
                <strong>{post.authorUser && post.authorUser.name}</strong>
                {post.type === "consumption"
                  ? " just consumed based on recommendation "
                  : " clapped for "}
                {post.publishedAt && <span> @ {formattedDate}</span>}
              </div>
              <div className="Text">{post.contentText}</div>
              {post.venue && (
                <div className="Venue">
                  <VenueChip venue={post.venue} />
                </div>
              )}
              <PostTools post={post} />
              {/* {this.renderComments()} */}
            </div>
          </LinkContainer>
        </Link>
      </FeedItem2Styled>
    )
  }
}
