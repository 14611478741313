import React from "react"
import styled from "styled-components"

const VenueChipStyled = styled.div`
  display: flex;
  flex-direction: row;
  background-color: rgb(255, 241, 228);
  align-items: center;
  border-radius: 50px;
  padding: 8px;
  margin: 4px 0;

  .VenueLogo {
    // min-width: 80px;
    min-width: 8px;
  }

  .VenueName {
    font-size: 20px;
    color: #666;
  }

  @media (max-width: 725px) {
    .VenueName {
      font-size: 14px;
    }
  }
`
export const VenueChip = ({ venue }) => {
  return (
    <VenueChipStyled>
      <div className="VenueLogo">{/* <div>LO</div> */}</div>
      <div className="VenueName">{venue.name}</div>
    </VenueChipStyled>
  )
}
