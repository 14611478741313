import { useEffect } from "react"

import useAxios, { serializeCache } from "../../../axios/axios-hooks"

// const fragmentGamedefinitionAll = `
// fragment gameDefinitionAll on GameDefinition {
//   id
//   name
//   archetypeId
//   archetype { id name }
//   scope
//   description
//   daysToComplete
//   state
//   tasks {
//       id
//       type
//       minValue
//       quantity
//   }
//   rewards {
//    id
//    type
//    artifactTypeId
//    artifactType { id name archetypeId archetype { name } }
//   }
// }`
// const qlGetVenue = gql(
//   `query venue($venueId: String!)
//     { venue(venueId:$venueId)
//       {
//         __typename
//       id
//       name
//       lat
//       lng
//       city
//       street
//       tags { name code }
//       categories { name }
//       facebookUrl
//       websiteUrl
//       description

//       media {
//         __typename
//         id
//         type
//         mimeType
//         urls {
//           w
//           url
//         }
//       }

//       benefitPrograms {
//         __typename
//         id
//         name
//         activeFrom
//         activeTill
//         details
//         typeCode
//       }
//       availableGames {
//         gameDefinition {
//           __typename
//           ...gameDefinitionAll
//         }
//       }
//     }
//   }${fragmentGamedefinitionAll}`
// )

export const useVenueQuery = (venueId) => {
  const url =
    process.env.REACT_APP_CLAPHERE_REST_API + "/venues/v2/CLAP/venue/" + venueId
  console.log("useVenueQuery", venueId, url)
  const [{ data, loading, error }, refetch] = useAxios(url, {
    useCache: true,
    // ssr: true
  })

  if (error) {
    console.error("useAxios error", error)
  }
  useEffect(() => {
    serializeCache().then((c) => {})
  })
  // const { data, loading, error } = useQuery(qlGetVenue, {
  //   variables: { venueId },
  //   fetchPolicy: "cache-and-network"
  // })
  // console.log("UseVenueQuery", data, loading, url)
  const venue = data

  return { loading, data, error, venue, refetch }
}
