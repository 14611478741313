import { Link } from 'react-router-dom'

const Item = ({ label, selected, rootUrl }) => {
  return (
    // <Link to={`${rootUrl}/${label.toLowerCase()}`}>
    <Link
      to={`${rootUrl}/${label.toLowerCase()}`}
      className=" flex-1 
    py-2 sm:py-4
    text-lg
    text-center
    relative
    rounded-2xl
    cursor-pointer
"
      style={{
        color: selected ? "white" : "#848199",
        fontWeight: 800,
        backgroundColor: selected ? "var(--clap-color-orange)" : "white",
        boxShadow: selected ? "var(--clap-shadow-july)" : undefined
      }}
    >
      <div>{label}</div>
    </Link>
  )
}
export const DiscoverTabSwitch = ({ rootUrl, page }) => {
  return (
    <div
      className="
      mx-2 sm:m-auto
      sm:w-1/3 flex flex-row bg-white 
      rounded-2xl
      my-2 sm:my-4"
      //   style={{ boxShadow: "var(--clap-shadow-july)" }}
    >
      <Item label="SHOPS" selected={page === "shops"} rootUrl={rootUrl} />
      <Item label="FEED" selected={page === "feed"} rootUrl={rootUrl} />
    </div>
  )
}
