import styled from 'styled-components'

export const LinkContainer = styled.div`
  padding: 0 30px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 40px 100px rgba(85, 98, 126, 0.08);

  @media (max-width: 735px) {
    padding: 0px;
  }
`

export const FeedItem2Styled = styled.article`
  margin: 20px 0;
  padding-top: 80px;

  a {
    text-decoration: none;
    color: initial;
  }

  .Photo__Wrapper {
    position: relative;
    padding: 0 20px;
    height: 220px;
    width: 100%;
    box-sizing: border-box;
  }
  .Photo {
    background: #fefeff;
    height: 280px;
    position: absolute;
    top: -80px;
    left: 0;
    right: 0;
    margin: auto;
    box-sizing: border-box;
    border-radius: 15px;
    box-shadow: 0px 10px 20px rgba(53, 65, 109, 0.2);
    overflow: hidden;
    object-fit: cover;

    .Outer {
    }

    img {
      width: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      height: 100%;
    }
  }
  .FeedItemContent {
    .Author {
      padding: 10px 0;
      color: #19223d;
      font-size: 13px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0.3px;
    }

    .Text {
      color: #212b36;
      font-size: 21px;
      font-weight: 800;
      line-height: 29px;
      letter-spacing: 0.3px;
      padding: 20px 0;
    }

    .Venue {
      border-top: solid 0.5px #d8e0ea;
      padding: 10px 0;
    }
  }

  .Venue {
    display: flex;
    flex-direction: row;
  }

  .Options {
    background: #f7f7ff;
    display: flex;
    flex-direction: row;
    margin: 0 -30px;
    padding: 13px 0;

    .Option {
      a {
        color: #8a85ea;
      }
      color: #8a85ea;
      font-size: 12px;
      margin-left: 8px;
      font-weight: 700;
      line-height: 16px;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      img,
      svg {
        margin-right: 8px;
        height: 24px;
        width: 24px;
      }
    }
  }
  .Comments {
    padding: 10px 0;
    .InputWrapper {
      padding: 10px 0;
      min-height: 40px;
      position: relative;

      textarea {
        background: #f9fafb;
        border-radius: 10px;
        border: solid 0.5px #dfe3e8;
        width: 100%;
        padding: 10px;
        color: #919eab;
        font-size: 15px;
        &:active,
        &:focus {
          outline: none;
        }
      }
    }
  }
  @media (max-width: 735px) {
    padding-top: 0px;
    display: flex;
    flex-direction: column;
    margin-top: 0;
    margin-bottom: 16px;
    flex-shrink: 0;
    position: relative;
    border-radius: 0;

    .Photo__Wrapper {
      padding: 0px;
      display: block;
      position: relative;
      height: 60vw;
      width: 100%;
      pading-bottom: 125px;
      overflow: hidden;
    }
    .Photo {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      border-radius: 0;
      img {
        position: absolute;
        height: 100%;
        left: 0;
        top: 0;
      }
    }

    .FeedItemContent {
      .Author {
        font-size: 11px;
        padding: 4px 8px;
      }

      .Text {
        padding: 4px 8px;
        font-size: 13px;
      }
      .Venue {
        border-top: none;
        padding: 8px;
      }
      .Options {
        display: none;
      }
    }
  }
`
