import axios from 'axios'
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'

export interface CurrentUser {
  id: string
  name: string
}

export interface AuthContextType {
  currentUser?: CurrentUser
  showLoginPopup?: Function
  confirmOtp: Function
  //   logout: Function
  //   refetchCurrentUser: Function
  //   cognitoUser?: any
  //   initializationCompleted: boolean
  //   isLoadingProfile: boolean
  //   isSuperAdmin: boolean
  //   homeData?: {
  //     unreadMessagesCount: number
  //     reservationsToApprove: number
  //   }
  //   refreshHomeData: Function
}

const LOCAL_STORAGE_KEY = "ClapAuth"

export const AuthContext = createContext<AuthContextType>({
  showLoginPopup: () => {},
  confirmOtp: () => {}
  //   refetchCurrentUser: () => {},
  //   initializationCompleted: false,
  //   isLoadingProfile: false,
  //   isSuperAdmin: false,
  //   refreshHomeData: () => {}
})

export const AuthContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(undefined)
  //   const [currentCognitoUser, setCurrentCognitoUser] = useState()
  const [isInitializationCompleted, setIsInitializationCompleted] =
    useState<boolean>(false)
  //   const [isLoadingProfile, setIsLoadingProfile] = useState(false)
  //   const [homeData, setHomeData] = useState()

  const _rehydrate = async () => {
    // console.log("_rehydrate")
    if (window && window.localStorage) {
      const dataStr = window.localStorage.getItem(LOCAL_STORAGE_KEY)
      if (dataStr != null) {
        const data = JSON.parse(dataStr)
        if (data.currentUser) {
          // console.log("Found current user")
          setCurrentUser(data.currentUser)
          return data.currentUser
        }
      }
    }
  }

  const confirmOtp = async (phoneNumber, otp) => {
    var url = process.env.REACT_APP_CLAPHERE_REST_API + "/auth/confirmOTP"
    const result = await axios.post(url, {
      method: "PHONE",
      phone: phoneNumber,
      otp
    })
    console.log("result", result)
    if (result.status === 200) {
      console.log("Setting user ", result.data)
      setCurrentUser({
        ...result.data.user,
        refreshToken: result.data.refreshToken,
        token: result.data.token
      })
      // await setTimeout
      // _persist()
    }
  }

  useEffect(() => {
    // Access the user session on the client
    // console.log("AuthContext Initialization")
  }, [])

  useEffect(() => {
    // console.log("Current user changed")
  }, [currentUser])
  const logout = () => {
    // console.log("logout")
    setCurrentUser(undefined)
    _persist()
  }

  const _persist = useCallback(() => {
    console.log("_persist", currentUser)
    if (window.localStorage) {
      if (currentUser) {
        const data = {
          currentUser
        }
        console.log("AuthContext .persist")
        window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(data))
      } else {
        window.localStorage.removeItem(LOCAL_STORAGE_KEY)
      }
    }
  }, [currentUser])

  //   useEffect(() => {
  //     console.log("AuthContext Persister")
  //     if (isInitializationCompleted) {
  //       _persist()
  //     }
  //     //   refreshHomeData()
  //   }, [currentUser, isInitializationCompleted, _persist])

  useEffect(() => {
    _rehydrate().then((rehydrated) => {
      // console.log("rehydrated", rehydrated)
      setIsInitializationCompleted(true)
    })
  }, [])

  const contextValue = {
    logout,
    currentUser,
    confirmOtp
  }
  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  )
}

export const useAuthContext = (): AuthContextType =>
  useContext(AuthContext) as AuthContextType
