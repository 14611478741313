import useAxios from 'axios-hooks'

export const useCouponQuery = (couponId, params?: any) => {
  var url =
    process.env.REACT_APP_CLAPHERE_REST_API +
    "/coupons/v2/CLAP/coupon/" +
    couponId
  if (params) {
    var k: any = Object.keys(params as any).reduce((a: any, i: any) => {
      return [...a, `${i}=${params[i]}`]
    }, [])
    url = url + "?" + k.join("&")
  }
  const [{ data, loading, error }, refetch] = useAxios(url, {
    useCache: false,
    ssr: false
  })

  // const { data, loading, error } = useQuery(qlGetCoupon, {
  //   variables: { couponId },
  //   fetchPolicy: "cache-and-network"
  // });

  const coupon = !loading && data ? data : undefined

  return { loading, data, error, coupon }
}
