import React from 'react'

export const PriceWidget = ({
  value,
  currencyCode
}: {
  value: number
  currencyCode?: string
}) => {
  if (!value) {
    return <span />
  }
  let currencySymbol
  if (currencyCode === "THB") {
    currencySymbol = "฿"
  }

  var dec = Number(value / 100).toFixed(0)

  return (
    <span>
      {dec}
      {currencySymbol}
    </span>
  )
}
