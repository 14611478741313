import gql from "graphql-tag"
import { useQuery } from "@apollo/client/react/hooks"

const qlGetUser = gql(
  `query user($userId: ID!) 
    { user(userId:$userId) 
      { 
      id 
      name 
     
      }
    }`
)

export const useUserQuery = (userId) => {
  const { data, loading, error } = useQuery(qlGetUser, {
    variables: { userId },
    fetchPolicy: "cache-and-network"
  })

  const user = !loading && data && data.user ? data.user : undefined

  return { loading, data, error, user }
}
