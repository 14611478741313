import React from 'react';
import styled from 'styled-components';

const Base = styled.h1``;
  
const Title = styled(Base)``;

const baseRender = (com, type) => {
  com.target = type;
  return com;
};
  
const withProps = (Component: any, { children, ...props }: any) => (
  <Component {...props} >{children}</Component>
);
  
export const ClapTitle = ({ type, ...props }) => withProps(baseRender(Title, type), props);