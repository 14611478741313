// /** @jsxImportSource @emotion/react */
// import 'twin.macro'

import { FullPageContainer } from '../../components/FullPageContainer'

export const TestPage = (props) => {
  return <FullPageContainer>TestPage


    <div className="text-xl" > text xl</div>
  </FullPageContainer>
}
