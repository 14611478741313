import styled from 'styled-components'

export const FeedPageStyled = styled.div`
  padding-top: 68px;

  // background: radial-gradient(
  //   94.03% 126.35% at 98.33% -26.2%,
  //   #7b00a6 0%,
  //   #3f0055 100%
  // );

  .Wrapper {
    max-width: 1020px;
    margin: 0 auto;
  }

  .Right {
    .Item {
      background-color: white;
      border: 1px solid #dadce0;
      margin-bottom: 15px;
      padding: 24px 16px;
      border-radius: 6px;
      min-height: 200px;
    }
  }

  .List {
    width: 600px;
    margin: 0 auto;
  }

  @media only screen and (max-width: 640px) {
    padding-top: 54px;

    .Wrapper {
      max-width: 100%;
      width: 100%;
      margin: 0 auto;
    }
    .List {
      width: 100%;
    }
  }
`
