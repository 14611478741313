import React from "react"
import styled from "styled-components"
import {
  EmailShareButton,
  FacebookShareButton,
  LineShareButton,
  LineIcon,
  FacebookIcon
} from "react-share"

const ShareButtonWrapper = styled.div`
  margin: 8px;
  border: 1px solid #ccc;
  border-radius: 50px;
  cursor: pointer;

  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 150ms ease-in;
  :hover {
    background-color: #f0f0f0;
  }
`
export const ShareBar = ({ url, quote }) => {
  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <FacebookShareButton url={url} quote={quote}>
        <ShareButtonWrapper title="Share on facebook">
          <FacebookIcon
            size={32}
            borderRadius={50}
            bgStyle={{ fill: "none" }}
            iconFillColor="#ddd"
          />
        </ShareButtonWrapper>
      </FacebookShareButton>
      <LineShareButton url={url}>
        <ShareButtonWrapper title="Share on LINE">
          <LineIcon
            size={32}
            borderRadius={50}
            bgStyle={{ fill: "none" }}
            iconFillColor="#ddd"
          />
        </ShareButtonWrapper>
      </LineShareButton>
    </div>
  )
}
