import gql from "graphql-tag"
import { useQuery } from "@apollo/client/react/hooks"

const qlGetReservation = gql(
  `query reservation($reservationId: ID!) 
    { reservation(reservationId:$reservationId) 
      { 
          id 
          orderedAt
          venue {
             id
             name
             description
             street
             media { 
                id type urls { w url }
             }
          }    
          lineItems {
             product {
               id
               name
               description
               media {
                 id
                 type 
                 urls { w url }
               }
             }
             quantity
             unitPrice
             totalPrice
          }
          stage
          accessLevel 
        }
       
    }`
)

export const useReservationQuery = (reservationId) => {
  const { data, loading, error } = useQuery(qlGetReservation, {
    variables: { reservationId },
    fetchPolicy: "cache-and-network"
  })

  const reservation = data && data.reservation ? data.reservation : undefined

  return { loading, data, error, reservation }
}
