import React, { useContext } from "react"

export type LocaleContextType = {
  lang: string
  country: string
  locale: string
}

export const LocaleContext = React.createContext({
  locale: "en",
  country: "th"

  // setLocale: (ar) => {}
})

export const LocaleContextProvider = ({ children, lang, country }) => {
  const value = {
    lang,
    country,
    locale: lang + "_" + country.toUpperCase()
  }
  return (
    <LocaleContext.Provider value={value}>{children}</LocaleContext.Provider>
  )
}

export const useLocaleContext = (): LocaleContextType =>
  useContext(LocaleContext) as LocaleContextType
