import { faPlus } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useLocation } from 'react-router-dom'

import ClapTypo from '../../../components/ClapTypo'
import { useVenueFeed } from '../../feed/api/useVenueFeed'
import { FeedCardRoman } from '../../feed/components/FeedCardRoman'

export const VenueSectionFeed = ({ venue }) => {
  const { loading, data, error, posts } = useVenueFeed(venue.id)
  let location = useLocation()
  // console.log("VenueSectionFeed", data, location)
  const items = data?.edges ? data.edges.map((e) => e.edge) : []

  return (
    <div className="my-2">
      <div className="mx-4">
        <ClapTypo.SectionTitle>FEED</ClapTypo.SectionTitle>
      </div>
      {data && (
        <>
          {items.length > 0 ? (
            <FeedCardRoman post={items[0]} />
          ) : (
            <div className="text-center">
              We don't have any posts for this shop yet
            </div>
          )}
        </>
      )}

      {items.length > 0 && (
        <Link to={`${location.pathname}/feed`}>
          <div className="flex flex-row justify-center py-2 font-bold text-xl cursor-pointer italic text-black">
            <div className="mr-2" style={{ color: "var(--clap-color-orange)" }}>
              <FontAwesomeIcon icon={faPlus} />
            </div>
            <div>Show more</div>
          </div>
        </Link>
      )}
    </div>
  )
}
