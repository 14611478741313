import React from 'react'
import { Link, useRouteMatch } from 'react-router-dom'

import { LoadingWidget } from '../../../components/LoadingWidget'
import { useLocale } from '../../../utils/useLocale'
import { useVenues } from '../api/VenuesListHoc'
import { VenueCard } from '../components/VenueCard'
import { VenuesListPageStyled } from './VenuesStyled'

// import { VenuesSearchBar } from "../components/VenuesSearchBar"
// import { VenuesListItemStyled } from "../components/VenuesListItemStyled";
// TODO: Removed unused code once confirm with Peter.
// const MockAddYours = (props: any) => {
//   return (
//     <VenuesListItemStyled>
//       <picture
//         className="Photo"
//         style={{
//           background: "linear-gradient(#fefefe, #cacaca)",
//           cursor: "pointer"
//         }}
//       >
//         {/* <div className="Photos__ImgWrapper"> */}
//         <div
//           style={{
//             fontSize: 100,
//             textAlign: "center"
//           }}
//         >
//           +
//         </div>
//         {/* </div> */}
//       </picture>
//       <figcaption className="Details">
//         <div className="Name">ADD YOUR VENUE HERE</div>
//         <div className="Address">
//           This block is to invite business to register
//         </div>
//       </figcaption>
//     </VenuesListItemStyled>
//   );
// };

export const VenuesPage = (props) => {
  const match = useRouteMatch<any>()
  const locale = useLocale()
  const { venues, loading } = useVenues(match?.params?.country ?? "th")

  return (
    <div className="pt-2 sm:pt-8 min-h-screen">
      <VenuesListPageStyled id="venuesListPageStyled">
        {/* <VenuesSearchBar
          searchQuery={this.props.searchQuery}
          onQueryChange={this.props.onQueryChange}
          onSortOptionChange={this.props.onSortOptionChange}
          sortOption={this.props.sortOption}
          onToggleCategory={this.props.onToggleCategory}
          selectedCategoryCodes={this.props.selectedCategoryCodes}
        /> */}
        {/* {this.props.isLoading && (
          <LoaderContainer>
            <LoadingWidget />
          </LoaderContainer>
        )} */}
        <div
          id="venuesListContainer"
          className="grid grid-cols-1 
                sm:grid-cols-3 sm:gap-6 min-h-screen"
        >
          {loading && (
            <div className="flex items-center justify-center col-span-12">
              <LoadingWidget size="4x" />
            </div>
          )}

          {venues &&
            venues.map((v: any) => {
              var cityCode = v.cityCode ? v.cityCode.toLowerCase() : "bangkok"
              cityCode = cityCode.replace("thailand#", "")

              return (
                <React.Fragment key={v.id}>
                  {/* <VenueTileWraper> */}
                  <div className="my-2 sm:my-0">
                    <Link
                      to={`/${locale.country}/${locale.lang}/${cityCode}/${
                        v.slug ?? v.id
                      }`}
                    >
                      <VenueCard key={v.id} venue={v} />
                    </Link>
                  </div>
                  {/* </VenueTileWraper> */}
                </React.Fragment>
              )
            })}
          {/* <MapContainer>
          <MapWrapper>
            <VenuesMap {...this.props} />
          </MapWrapper>
        </MapContainer> */}
        </div>
      </VenuesListPageStyled>
    </div>
  )
}
