import { faSpinner } from "@fortawesome/free-solid-svg-icons/faSpinner"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"

const StyledButton = styled.button<any>`
  border-radius: 8px;
  transition: background 120ms ease-in, opacity 120ms ease-in;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid #dadce0;
  white-space: nowrap;
  font-size: 14px;
  opacity: 1; // 0.95;
  padding: 12px 16px;

  letter-spacing: 0.5px;
  background: none;
  position: relative;

  transition: background 150ms ease-in-out;
  user-select: none;

  :focus {
    outline: none;
  }
  &:hover {
    background: #dadce080;
  }

  ${({ primary, submit }) =>
    (primary || submit) &&
    `
    background: linear-gradient(
      to left,
      var(--clap-color-orange),
      var(--clap-color-secondary)
    );
    color: rgba(255,255,255,0.95);
    border: none;

    box-shadow: 0 10px 20px #fc4f3350;
    &:hover {
        background: linear-gradient(to left, #c61b03, #bd4903);
      }
    `}

  ${({ secondary }) =>
    secondary &&
    `
    border: 0px;
    color: #667;

    :hover {
      background: #ddd;
    }
    `}

    ${({ small }) =>
    small &&
    `
    font-size: 0.8em;
    font-weight: bold;
    padding: 4px 8px;
    line-height: 1em;
  `}

  

    ${({ large }) =>
    large &&
    `
      font-size: 1.4em;
      font-weight: bold;
      padding: 12px 24px;
      line-height: 1.4em;
    `}

    
    ${({ grouped }) =>
    grouped &&
    `
      margin-left: 6px;
      margin-right: 6px;
    `}

  ${({ fullWidth }) => fullWidth && ` width: 100%; margin: 8px 0; `}

  ${({ textButton }) =>
    textButton &&
    `
    border: 0;
    background: initial;
    color: #333;
    font-weight: 600;
    transition: background 150ms ease-in;

    &:hover {
      background: rgba(50, 50, 100, 0.1);
    }
    `}

    ${({ disabled }) =>
    disabled &&
    ` 
     opacity: 0.8;
  `}
`

type ClapButtonProps = {
  primary?: boolean
  secondary?: boolean
  label?: string
  onClick?: Function
  isSaving?: boolean
  submit?: boolean
  width?: any
  style?: any
  icon?: any
  grouped?: boolean
  disabled?: boolean
  small?: boolean
  iconButton?: boolean
  className?: string
  linkTo?: string
  fullWidth?: boolean
  children?: any
  textButton?: boolean
}
export const ClapButton = (props: ClapButtonProps) => {
  const inner = (
    <StyledButton
      {...props}
      width={props.width}
      type={props.submit ? "submit" : "button"}
      style={props.style}
    >
      {props.icon && (
        <FontAwesomeIcon icon={props.icon} style={{ marginRight: 5 }} />
      )}
      {props.children ? props.children : ""}
      {props.label ? (
        <span
          style={{
            opacity: props.isSaving ? 0 : 1
          }}
        >
          {props.label}
        </span>
      ) : (
        ""
      )}
      {props.isSaving && (
        <span style={{ position: "absolute", left: 0, right: 0 }}>
          <FontAwesomeIcon icon={faSpinner} spin />
        </span>
      )}
    </StyledButton>
  )

  return props.linkTo ? <Link to={props.linkTo}>{inner}</Link> : inner
}
