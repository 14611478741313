import styled from "styled-components"

export const Container = styled.div`
  width: 960px;
  margin: auto;
  background-color: white;
  box-shadow: 0 30px 50px #dedfe4;
  color: #1b1b1d;
  border-radius: 10px;
  min-height: 100%;
  max-width: 100%;
  // For mobile only
  @media only screen and (max-width: 640px) {
    background-color: initial;
    width: 100%;
    flex-direction: column;
  }
  @media (max-width: 735px) {
    width: 100%;
  }
`

export const Section = styled.div`
  padding: 20px;
  @media only screen and (max-width: 640px) {
    padding: 20px 0;
  }
  @media (max-width: 735px) {
    background-color: white;
    margin-bottom: 8px;
    border-radius: 0;
    h2 {
      margin: 0 0 8px 0;
    }
  }
`

export const LeftBubble = styled.div`
  position: absolute;
  top: 20vw;
  left: 0;
  z-index: -1;
  height: 40vw;
  width: 40vw;

  @media only screen and (max-width: 640px) {
    display: none !important;
    position: absolute;
    top: 35vh;
    left: 0;
    z-index: -1;
    height: 70vw;
  }
`

export const Benefits = styled.div`
  color: #212b36;
  display: flex;
  flex-direction: row;
  font-size: 21px;
  font-weight: 800;
  line-height: 29px;
  letter-spacing: 0.3px;
`

export const Benefit = styled.div`
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black;
  flex: 1;

  &:hover {
    box-shadow: 0px 15px 30px rgba(149, 156, 183, 0.3);
  }
`

export const BenefitSeprator = styled.div`
  border-radius: 50px;
  cursor: pointer;
  width: 72px;
  height: 72px;
  box-shadow: 0px 15px 30px rgba(149, 156, 183, 0.2);
  background: #ebeaff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #8a85ea;
`

export const BenefitTitle = styled.div`
  color: #7a75d5;
  padding-top: 20px;
  font-size: 13px;
  font-weight: 600;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.3px;
`

export const MainContent = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 640px) {
    padding: 20px;
    box-sizing: border-box;
    flex-direction: column;
  }
  @media (max-width: 735px) {
    padding: 0;
  }
`

export const Left = styled.div`
  flex: 7;
  @media (max-width: 735px) {
    background-color: #eee;
  }
`

export const Right = styled.div`
  flex: 5;
  @media (max-width: 735px) {
    display: none;
  }
`

export const RightPanelSpacing = styled.div`
  padding: 0px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: rgba(50, 50, 100, 0.1) 0px 30px 50px 0px;
  margin: 8px;
`

export const CTAContainer = styled.div`
  border-radius: 12px;
  background: linear-gradient(
    to left,
    var(--clap-color-orange),
    var(--clap-color-secondary)
  );
  color: white;
  text-align: center;
  font-weight: 600;
  margin: 10px 0;
  padding: 10px 20px;
  box-shadow: 0 13px 16px #ff572220;
  cursor: pointer;
  &:hover {
    box-shadow: 0 13px 16px #ff572240;
  }
`

export const PlaceWrapper = styled.div`
  border-radius: 6px;
  margin-bottom: 16px;
  padding: 0px;
`

export const Place = styled.div`
  padding: 16px 16px 6px;

  .mobileActions {
    display: block;
  }
  @media (min-width: 735px) {
    .mobileActions {
      display: none;
    }
  }
`

export const PlaceName = styled.div`
  font-size: 28px;
  font-weight: 800;
  @media (max-width: 735px) {
    font-size: 22px;
  }
`

export const PlaceAddress = styled.div`
  color: #333;
  font-size: 16px;
  line-height: 20px;
  padding: 16px 0;
  @media (max-width: 735px) {
    padding: 8px 0;
    font-size: 12px;
  }
`

export const Categories = styled.div`
  padding: 6px 0;
  margin: 16px 0;
`

export const CategoryItem = styled.div`
  border-radius: 16px;
  padding: 4px 8px;
  color: white;
  background-color: blueviolet;
  margin-right: 6px;
  display: inline;
  font-size: 14px;
  line-height: 16px;
`

export const Discription = styled.div`
  border-top: 1px solid #ddd;
  padding: 16px;
  margin: 16px 0px;
`

export const VenuePageStyled = styled.div`

    h2 {
      font-size: 24px;
    }
    .sectionTitle {
      font-size: 20px;
      margin: 5px 0 5px 8px;
    }
    

    
  .LoadingWrapper {
    padding: 40px;
    display:flex; 
    align-items: center;
    justify-content: center
  }

    .categoryChip {
      border-radius: 16px;
      padding: 4px 8px;
      color: white;
      background-color: blueviolet;
      margin-right: 6px;
      display: inline;
      font-size: 14px;
      line-height: 16px;
    }

    h2 {
      font-size: 16px;
    }

    .Name {
      font-size: 28px;
      font-weight: 800;
    }
    .SectionTitle {
      font-size: 24px;
      line-height: 2em;
    }

    .Address {
      color: #333;
      font-size: 16px;
      line-height: 20px;
      padding: 16px 0;
    }

    .Categories {
       padding: 6px 0;
        margin: 16px 0;
    }

    .AdminPhotos {
      .List {
        display: flex;
        flex-direction: row;
        overflow: auto;
      }
      .Wrapper {
        min-width: 200px;
        max-width: 200px;
        height: 200px;
        margin: 5px;
        cursor: pointer;
        position: relative;
        &:hover {
          .VenuePage__AdminPhotos__Actions {
            display: initial;
            opacity: 0.9;
            background-color: white;
            border-radius: 5px;
            padding: 4px 6px;
            cursor: pointer;
          }
        }
      }
      .Photo {
        width: 200px;
        height: 200px;
      }
      .Actions {
        display: none;
        position: absolute;
        top: 20;
        right: 20;
      }
    }

    .Products {
      border-radius: 8px;
      box-shadow: 0 30px 50px #9695b740;

      .Item:last-child {
        border-bottom: none;
      }

      .Item {
       
      }
    }
    .Benefits {
      
    }
    .BenefitItem {
      box-sizing: border-box;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: black;
      flex: 1;

      &:hover {
        .VenuePage__BenefitItem__Inner {
          box-shadow: 0px 15px 30px rgba(149, 156, 183, 0.3);
        }
      }

      .Inner {
        border-radius: 50px;
        cursor: pointer;
        width: 72px;
        height: 72px;
        box-shadow: 0px 15px 30px rgba(149, 156, 183, 0.2);
        background: #ebeaff;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color: #8a85ea;
      }

      .Name {
        // border-right: 1px solid rgba(45, 54, 95, 0.2);
        // width: 62%;
        color: #7a75d5;
        padding-top: 20px;
        font-size: 13px;
        font-weight: 600;
        line-height: 23px;
        text-align: center;
        letter-spacing: 0.3px;
      }

      .Value {
        font-size: 36px;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
      }
    }
  }

  .VenueMiniMap {
    height: 300px;
    width: 100%;
    position: relative;
  }

  .VenueFeed {
  }

  // For mobile only
  @media only screen and (max-width: 640px) {
    .VenuePage {
      background-color: initial;
      .Container {
        background-color: initial;
        width: 100%;
        flex-direction: column;
      }
      .Content {
        padding: 20px;
        box-sizing: border-box;
        flex-direction: column;
      }
      
      .Photos {
        .InnerWrapper {
          width: 100%;
        }
        .Photo {
          width: 100%;
          margin: 0;
        }
      }
      .LeftBubble {
        position: absolute;
        top: 35vh;
        left: 0;
        z-index: -1;
        height: 70vw;
      }
    }


    

  @media (max-width: 735px) { 
    min-height: 100vh;
    // padding-top: 50px;
    background-color: #fff;

    .Section {
       background-color: white;
       margin-bottom: 8px;
       border-radius: 0;
       h2 {
         margin: 0 0 8px 0;
       }
    }

    .Container {
      width: 100%;
    }
      .Photos {
        padding: 0;
        width: 100%;
        height: 40vh;
        margin: 0; 

        .InnerWrapper {
          width: 100%;
        }
        .Photo {
          width: 100%;
          border-radius: 0px;
          margin: 0;
          div {
            border-radius:0;
          }
        }
      }

      .Content {
        padding: 0;

        .Left {
          .Name {
            font-size: 22px;
          }
          .Address {
            padding: 8px 0;
            font-size: 12px;
          }
          .Categories {

          }
        }
      }

      
  }
  

`
