import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { SignUpPopup } from '../modules/auth/SignUpPopup'

import { useLocale } from '../utils/useLocale'

const FooterStyledDiv = styled.div`
  //   background-color: $mps-content-background-grey;
  //   color: $mapspire-dark-grey-border;

  // background-color: #fceeeb;

  // color: white;
  // margin-top: 30px;
  position: relative;
  overflow: hidden;
  padding-bottom: 20px;

  .FooterContainer {
    
    margin: 0px auto;
    justify-content: center;
    font-size: 12px;
    // max-width: $content-max-width;
  }
  .FooterLogo {
    width: 120px;
    margin-bottom: 15px;
  }
  .FooterSection {
    flex: 1;
    .Title {
      font-weight: 800;
      margin-bottom: 20px;
    }
  }
  ${(props: any) =>
    props.home &&
    `
  background-color: #383838;
  color: #eee;
  padding: 20px 0 40px 0;
  `}

  &--withSpacing {
    margin: 20px 0 10px 0;
    // border-top: 1px solid $mapspire-light-border;
    padding-top: 20px;
  }
  .FooterItem {
    font-weight: 600;
    margin: 10px 0;
    a {
      text-decoration: none;
      color: initial;
    }
  }

  // For mobile only

  @media (max-width: 735px) {

    .FooterSectionLogo {
      display: none;
    }

    .Footer {
      margin-bottom: 15px;

      .Logo {
        width: 40vw;
        margin-bottom: 15px;
      }
      .Container {
        flex-direction: column;
        width: 100%;
        padding: 0;
      }
      .Section {
        text-align: center;
        &__Title {
          display: none;
        }
      }
      .Item {
        padding: 5px 0;
      }
    }
  }
`

export const Footer = (props: any) => {
  const locale = useLocale()
  const _link = (title: any, target: any) => {
    return (
      <div className="FooterItem">
        <Link to={`/${locale.country}/${locale.lang}/${target}`} id={target}>
          {title}
        </Link>
      </div>
    )
  }
  const handleLogoDblClick = () => {
    console.log('handleLogoDblClick')
  }
  return (
    <FooterStyledDiv
      {...props}
      className={`Footer 
        ${props.home ? "Footer-home" : ""}
        ${props.withSpacing ? "Footer--withSpacing" : ""}`}
    >
      <div className="FooterContainer 
        px-6 sm:m-auto
        sm:pt-4
        flex
       w-full sm:max-w-screen-xl">
        <div className="FooterSection FooterSectionLogo" onDoubleClick={handleLogoDblClick}>
          {/* <Link to="/"> */}
            <img
              src="/assets/2021/clap_logo_only_orange.svg"
              alt="ClapHere"
              title="ClapHere"
              className="FooterLogo w-8 h-8"
            />
          {/* </Link> */}
        </div>
        <div className="FooterSection">
          <div className="Title">Concept</div>
          {_link("About", "about")}
          {/* {_link("Work with us", "work-with-us")} */}
          {/* {_link("Careers", "careers")} */}
          {_link("Business", "business")}
          {_link("Contact", "contact")}
        </div>
        <div className="FooterSection">
          <div className="Title">Legal</div>
          {_link("T&C", "terms")}
          {_link("Privacy", "privacy")}
        </div>
        <div className="FooterSection">
          <div className="Title">Follow us</div>
          <div className="FooterItem">
            <a
              href="https://www.facebook.com/ClapHereApp"
              target="_blank"
              rel="noopener noreferrer"
            >
              Facebook
            </a>
          </div>
          {/* {process && process.env && (
            <div style={{ opacity: 0.5 }}>{process.env.REACT_APP_VERSION}</div>
          )} */}
        </div>
        {/* <div className="FooterSection">
          <div className="Title">Quick</div>
          {_link("Thai Near Me", "/th/thai-near-me")}
          {_link("Pizza Near Me", "/th/pizza-near-me")}
        </div> */}
      </div>
     
    </FooterStyledDiv>
  )
}
