import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ClapCardJuly } from "../../../components/july/ClapCardJuly"
import { VenueOrderNowButton } from "./VenueOrderNowButton"
import { faPhone } from "@fortawesome/fontawesome-free-solid"
import { ContactPage } from "../../contact/ContactPage"
import { faAt, faMailbox, faSpiderWeb } from "@fortawesome/pro-solid-svg-icons"
import { faInstagram, faLine } from "@fortawesome/free-brands-svg-icons"
export const VenueContact = ({ contact }) => {
  if (contact.type === "PHONE") {
    return (
      <>
        <div className="pr-2">
          <FontAwesomeIcon icon={faPhone as any} />
        </div>
        {contact.value}
      </>
    )
  }
  if (contact.type === "WEBSITE") {
    var url = contact.value
    if (url.indexOf("http") !== 0) {
      url = `https://${url}`
    }
    return (
      <a href={url} target="_blank" rel="noreferrer">
        <div className="flex flex-row">
          <div className="pr-2">
            <FontAwesomeIcon icon={faSpiderWeb as any} />
          </div>

          {contact.value}
        </div>
      </a>
    )
  }
  if (contact.type === "LINE") {
    var url = contact.value
    if (url.indexOf("https://line.me") !== 0) {
      url = `https://line.me/${url}`
    }
    return (
      <a href={url} target="_blank" rel="noreferrer">
        <div className="flex flex-row">
          <div className="pr-2">
            <FontAwesomeIcon icon={faLine as any} />
          </div>
          {contact.value}
        </div>
      </a>
    )
  }
  if (contact.type === "INSTAGRAM") {
    var url = contact.value
    if (url.indexOf("https://www.instagram") !== 0) {
      url = `https://www.instagram.com/${url}`
    }
    return (
      <a href={url} target="_blank" rel="noreferrer">
        <div className="flex flex-row">
          <div className="pr-2">
            <FontAwesomeIcon icon={faInstagram as any} />
          </div>
          {contact.value}
        </div>
      </a>
    )
  }
  if (contact.type === "EMAIL") {
    var mailUrl = `mailto://${contact.value}`

    return (
      <a href={mailUrl} target="_blank" rel="noreferrer">
        <div className="flex flex-row">
          <div className="pr-2">
            <FontAwesomeIcon icon={faAt as any} />
          </div>
          {contact.value}
        </div>
      </a>
    )
  }
  return <> {contact.value}</>
}
export const VenueCardDetails = ({ venue }) => {
  return (
    <ClapCardJuly caption="DETAILS">
      <div className="my-2 text-md font-medium">{venue.city}</div>
      <div className="my-2 text-md font-medium">{venue.street}</div>

      {venue.contacts &&
        venue.contacts.map((co) => (
          <div className="font-semibold my-1 text-black flex">
            <VenueContact contact={co} />
          </div>
        ))}

      <div className="w-full pt-2">
        <VenueOrderNowButton venue={venue} />
      </div>
    </ClapCardJuly>
  )
}
