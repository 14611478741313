import faBars from '@fortawesome/fontawesome-free-solid/faBars'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Link, useLocation, useRouteMatch } from 'react-router-dom'

import { useAuthContext } from '../modules/auth/authContext'
import { SignUpPopup } from '../modules/auth/SignUpPopup'
import { NavUserBar } from '../modules/nav/NavUserBar'
import { LocaleContext } from '../utils/localeContext'
import { useLocale } from '../utils/useLocale'
import { ClapButton } from './ClapButton'
import { ClapHereNavStyled, HeaderMenuItemStyled } from './ClapHereNavStyled'

const RegisterShopButton = () => (
  <a
    href="https://business.claphere.com/login"
    target="_blank"
    rel="noreferrer"
  >
    <ClapButton primary label="Register Shop for FREE" />
  </a>
)
export const ClapHereNav = (props: any) => {
  const location = useLocation()
  const authContext = useAuthContext()
  const [scrollDistance, setScrollDistance] = React.useState<number>(0)
  const [mobileMenuVisible, setMobileMenuVisible] =
    React.useState<boolean>(false)
  const localeContext = React.useContext(LocaleContext)
  const match = useRouteMatch()
  const intl = useIntl()
  const localeParams: any = useLocale()
  const [isShowingSignIn, setIsShowingSignIn] = useState(false)

  const _renderMenuItem = ({ label, url }: { label: any; url: any }) => {
    const isSelected = location.pathname === url
    return (
      <HeaderMenuItemStyled selected={isSelected}>
        <Link to={`/${localeParams.country}/${localeParams.lang}${url}`}>
          {label}
        </Link>
      </HeaderMenuItemStyled>
    )
  }

  const _renderMobileMenuItem = ({ label, url }: { label: any; url: any }) => {
    const isSelected = location.pathname === url
    return (
      <div className="menuItem">
        <Link
          to={url}
          onClick={() => {
            setMobileMenuVisible(false)
          }}
        >
          {label}
        </Link>
      </div>
    )
  }

  const toggleMobileMenu = () => {
    setMobileMenuVisible(!mobileMenuVisible)
  }
  const _setLocale = (newLocale) => {
    // localeContext.setLocale(newLocale)
  }

  const handleScroll = () => {
    setScrollDistance(Math.min(1, window.pageYOffset / 200))
    if (window.pageYOffset > 0) {
      // if (!this.state.className) {
      //   this.setState({ className: "blue" })
      // }
    } else {
      // if (this.state.className) {
      //   this.setState({ className: "" })
      // }
    }
  }

  useEffect(() => {
    if (window) {
      window.addEventListener("scroll", handleScroll)

      return () => {
        console.log("Remove event lister")
      }
    }
  }, [])

  const handleLoggedIn = () => {
    setIsShowingSignIn(false)
  }

  const generatePagePath = (forLocale) => {
    // console.log("generatePagePath", forLocale, match)
    // const params = { ...match.params }
    // const newParams = {
    //   ...routeParams,
    //   lang: forLocale
    // }
    const path = location.pathname.replace(
      `/${localeParams.country}/${localeParams.lang}`,
      `/${localeParams.country}/${forLocale}`
    )
    // const path = generatePath(location.pathname, newParams)
    // console.log("PAth:", forLocale, path, newParams)
    return path
  }

  var opacity = scrollDistance
  // var bgColor = "#490062" + opacity.toString
  // console.log("Header, match:", match)
  if (match.url.indexOf("/venues") >= 0 || match.url.length > 2) {
    opacity = 1
  }
  const style = {
    background: "#490062",
    opacity: opacity
  }
  return (
    <ClapHereNavStyled>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          ...style
        }}
      ></div>
      {/* <ClapHereNavStyled style={{}}> */}
      <div className="content">
        <div className="LogoWrapper">
          <Link
            to={`/${localeParams.country}/${localeParams.lang}`}
            style={{ textDecoration: "none !important" }}
          >
            <div className="HeaderLeft">
              <img
                src="/assets/2021/clap_logo_only_orange.svg"
                alt="ClapHere"
                title="ClapHere"
              />
              <div
                className="name"
                style={{ textDecoration: "none !important" }}
              >
                ClapHere
              </div>
            </div>
          </Link>
        </div>
        {/* <div style={{ flex: 1 }} /> */}
        <div className="PrimaryNav">
          {/* {_renderMenuItem({ label: "Feed", url: "/feed" })} */}
          {_renderMenuItem({
            label: intl.formatMessage({
              id: "discover",
              defaultMessage: "Discover"
            }),
            url: "/discover"
          })}
          {/* {_renderMenuItem({
            label: intl.formatMessage({
              id: "blog",
              defaultMessage: "Blog"
            }),
            url: "/blog"
          })} */}
          {_renderMenuItem({
            label: intl.formatMessage({
              id: "forBusiness",
              defaultMessage: "For Business"
            }),
            url: "/pricing"
          })}
          {_renderMenuItem({
            label: intl.formatMessage({
              id: "about",
              defaultMessage: "About"
            }),
            url: "/about"
          })}
          <div
            style={{ width: 20, height: 20 }}
            onDoubleClick={(e: any) => {
              setIsShowingSignIn(true)
            }}
          />
          <div>
            <RegisterShopButton />
          </div>
          <NavUserBar />
          {/* {this._renderMenuItem({ label: "My Posts", url: "/my_post s" })} */}
          {/* {this._renderLoginMenu()} */}
          {/* {_renderMenuItem({ label: "Sign In", url: "/sign in" })} */}
          {/* {_renderMenuItem({ label: "About", url: "/about" })} */}
        </div>

        <div
          className={`MobileNavWrapper ${mobileMenuVisible ? "visible" : ""}`}
        >
          <div className="MobileNav">
            {_renderMobileMenuItem({ label: "Discover", url: "/discover" })}
            {/* {_renderMobileMenuItem({ label: "Blog", url: "/blog" })} */}
            {_renderMobileMenuItem({ label: "For Business", url: "/pricing" })}
            {_renderMobileMenuItem({ label: "About", url: "/about" })}
          </div>
        </div>
        <div
          className="Burger"
          onClick={() => {
            toggleMobileMenu()
          }}
        >
          {/* <Link to="/venues"> */}
          <FontAwesomeIcon
            icon={faBars as any}
            style={{ width: 26, height: 26 }}
          />
          {/* <img
                src="/assets/icons/burger-icon.svg"
                alt="ClapHere"
                title="ClapHere"
              /> */}
          {/* </Link> */}
        </div>

        <div className="LangSelect">
          <Link
            to={generatePagePath("th")}
            className="Item"
            style={{
              color: "white",
              opacity: localeContext.locale === "th" ? 1 : 0.5
            }}
            onClick={() => {
              _setLocale("th")
            }}
          >
            TH
          </Link>
          {" / "}
          <Link
            to={generatePagePath("en")}
            className="Item"
            style={{
              color: "white",
              opacity: localeContext.locale === "en" ? 1 : 0.5
            }}
            onClick={() => {
              _setLocale("en")
            }}
          >
            ENG
          </Link>
        </div>
        {isShowingSignIn && (
          <SignUpPopup
            onCancel={() => setIsShowingSignIn(false)}
            onLoggedIn={handleLoggedIn}
          />
        )}
      </div>
    </ClapHereNavStyled>
  )
}
