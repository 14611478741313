import styled from 'styled-components'

const ClapCardJulyStyled = styled.div<any>`
  box-shadow: var(--clap-shadow-card-21);
  background-color: white;

  .caption {
    color: var(--clap-color-orange);
  }

  ${(props) =>
    props.fullHeight &&
    `
    height: 100%;
  `}
`

export const ClapCardJuly = ({
  children,
  caption,
  fullHeight
}: {
  children: any
  caption?: string
  fullHeight?: boolean
}) => {
  return (
    <ClapCardJulyStyled
      fullHeight={fullHeight}
      className={`${fullHeight ? `h-full` : ""} 
       p-4 sm:p-8
       rounded-2xl
       `}
    >
      {caption && <div className="caption uppercase font-bold ">{caption}</div>}
      {children}
    </ClapCardJulyStyled>
  )
}
