import { useState } from 'react'
import { useIntl } from 'react-intl'

import { ClapButton } from '../../../../components/ClapButton'

export const VenuesSearchSection = (props) => {
  const intl = useIntl()
  const [query, setQuery] = useState<any>("")
  return (
    <div className="my-4 sm:w-1/2">
      <div
        style={{ boxShadow: "var(--clap-shadow-july)" }}
        className="p-2 sm:p-6 rounded-2xl flex flex-row"
      >
        <input
          className="w-full p-4 rounded-2xl mr-4"
          style={{
            color: "#808080",
            outline: "none",
            backgroundColor: "#F2F4F8"
          }}
          placeholder="Search by shop name, category or service"
          onChange={(event) => setQuery(event.target.value)}
          value={query}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              props.onSearch(query)
            }
          }}
        ></input>
        <div>
          <ClapButton
            label={intl.formatMessage({
              id: "search",
              defaultMessage: "Search"
            })}
            primary
            onClick={() => {
              props.onSearch(query)
            }}
          />
        </div>
      </div>
    </div>
  )
}
