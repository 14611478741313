import { ClapModal } from "../../../components/ClapModal"
import { useLocale } from "../../../utils/useLocale"
import { VenueQR } from "./VenueQR"

export const OrderOnMobilePopup = ({
  venue,
  isOpen,
  onCancel,
  post,
  url
}: {
  venue: any
  isOpen: boolean
  onCancel: Function
  post?: any
  url?: string
}) => {
  const locale = useLocale()
  var cityCode = venue.cityCode ? venue.cityCode.toLowerCase() : "bangkok"

  //   const orderingUrl = `https://www.claphere.com/${locale.country}/${
  //     locale.lang
  //   }/${cityCode}/${venue.slug ?? venue.id}`
  //   const orderingUrl = `https://www.claphere.com/shop/${
  //     venue.slug ?? venue.id
  //   }?clap_venue_id=${venue.slug ?? venue.id}&clap_lt=venue`

  return (
    <ClapModal
      isOpen={isOpen}
      onCancel={onCancel}
      title={post ? "Order same same" : "Order on mobile"}
    >
      <div className="my-4 text-center font-medium">
        {/* <div>{orderingUrl}</div> */}
        {post ? (
          <>
            Scan the code below and <strong>order same same</strong> in the
            ClapHere mobile app
          </>
        ) : (
          "To order products - use ClapHere mobile app"
        )}
        {/* {orderingUrl} */}
      </div>
      <div className="m-2 flex flex-row items-center justify-center w-full m-auto">
        <div style={{ width: 200, height: 200 }}>
          <VenueQR venue={venue} url={url} />
        </div>
      </div>
    </ClapModal>
  )
}
