import React from "react"
import styled from "styled-components"
import { Footer } from "../../../components/footer"
import { ClapTitle } from "../../../components/ClapTitle"

const Container = styled.div`
  padding-top: 70px;

  h1 {
    line-height: 60px;
    font-size: 48px;
  }

  h4 {
  }
  p {
    margin-bottom: 24px;
  }
`

const Row = styled.div`
  display: flex;
  @media (max-width: 735px) {
    display: block;
  }
`

const Img = styled.img`
  max-width: 500px;
  @media (max-width: 735px) {
    max-width: 300px;
  }
`

const Content = styled.div`
  max-width: 1024px;
  margin: auto;
  font-size: 20px;
  line-height: 30px;
  color: rgba(40, 40, 40, 1);
`

const CenteredWrapper = styled.div`
  max-width: 780px;
  margin: auto;
`

type BlogArticleProps = {
  title?: string
  url?: string
  subtitle?: string
  createdAt?: string
  image?: string
  description?: string
  wordCount?: Number
  author?: string
  children?: any
}

export const BlogArticle: React.FC<BlogArticleProps> = (props) => {
  const { title, image, subtitle } = props
  return (
    <Container>
      <CenteredWrapper>
        <Row>
          <ClapTitle type="h1">{title}</ClapTitle>
        </Row>
        <Row>
          <ClapTitle type="h2">{subtitle}</ClapTitle>
        </Row>
        <Row>{image && <Img src={image} alt="clap blog" />}</Row>
        <Content>
          {/* <BlogMarkdown markdown={description} /> */}

          {props.children}
        </Content>
        {/* <Row>
        {blog.author} | {blog.createdAt}
      </Row> */}

        <Footer />
      </CenteredWrapper>
    </Container>
  )
}
