import { match, useLocation, useRouteMatch } from 'react-router-dom'

import { ClapButton } from '../../../components/ClapButton'
import ClapTypo from '../../../components/ClapTypo'
import { ClapCardJuly } from '../../../components/july/ClapCardJuly'
import { LoadingWidget } from '../../../components/LoadingWidget'
import OgMeta from '../../../components/OgMeta'
import { PageContainer } from '../../../components/PageContainer'
import { PhotoWidget } from '../../../components/PhotoWidget'
import { PriceWidget } from '../../../components/PriceWidget'
import { useCouponQuery } from '../api/useCouponQuery'

interface UserPageMatch {
  couponId?: string
}
function paramsToObject(entries) {
  const result = {}
  for (const [key, value] of entries) {
    // each 'entry' is a [key, value] tupple
    result[key] = value
  }
  return result
}
const CouponMedia = ({ coupon }) => {
  return (
    <div className="max-h-80 rounded-2xl">
      {coupon.media?.length > 0 ? (
        <>
          <PhotoWidget
            photo={coupon.media[0]}
            className="rounded-2xl max-h-80"
          />
        </>
      ) : (
        <>Photo</>
      )}
    </div>
  )
}

const CouponValue = ({ coupon }) => {
  return (
    <div className="h-40 mr-4 rounded-2xl flex justify-center my-2">
      {coupon?.type === "VALUE" && coupon.value > 0 && (
        <>
          <div
            className="flex items-center justify-center w-40 h-40 text-4xl font-bold"
            style={{
              borderRadius: 100,
              backgroundColor: "var(--clap-color-orange)",
              color: "white"
            }}
          >
            <PriceWidget value={coupon.value} currencyCode="THB" />
          </div>
        </>
      )}
    </div>
  )
}

export const CouponPage = (props) => {
  const qmatch: match<UserPageMatch> = useRouteMatch<UserPageMatch>()
  let query = useLocation()
  console.log("qmatch", query)
  const params = paramsToObject(new URLSearchParams(query.search))
  console.log("params", params)
  const { coupon, loading } = useCouponQuery(qmatch.params.couponId, params)

  console.log("coupon page", coupon, loading)
  return (
    <PageContainer>
      <OgMeta />

      <div className="container m-auto max-w-xl mb-8 ">
        <div className="my-8">
          <ClapCardJuly caption="Coupon">
            {loading && <LoadingWidget />}
            {!loading && !coupon && (
              <>
                <div>Coupon not found</div>
              </>
            )}
            {coupon && (
              <>
                {coupon.venue?.name ? (
                  <ClapTypo.HeaderVenuePageName>
                    {coupon.venue?.name}
                  </ClapTypo.HeaderVenuePageName>
                ) : (
                  <>
                    {coupon.merchant?.name && (
                      <div className="my-4">
                        <ClapTypo.Caption>
                          Valid for all branches of
                        </ClapTypo.Caption>
                        <ClapTypo.HeaderVenuePageName>
                          {coupon.merchant?.name}
                        </ClapTypo.HeaderVenuePageName>
                      </div>
                    )}
                  </>
                )}

                <div className="my-4">
                  <CouponMedia coupon={coupon} />
                </div>
                <div className="my-4">
                  <CouponValue coupon={coupon} />
                </div>

                {coupon.isPinValid && (
                  <>
                    <ClapButton
                      label="Accept in ClapHere App"
                      onClick={(e: any) => {
                        var url = `https://claphere.app.link/coupon/${coupon.id}${query.search}`
                        if (window) {
                          window.open(url, "_blank")
                        }
                      }}
                    />
                  </>
                )}
              </>
            )}
          </ClapCardJuly>
        </div>
      </div>
      {/*           
                
              
                <div>
                  Merchant: <strong>{coupon.merchant?.name}</strong>
                </div>
                <div>
                  For: <strong>{coupon.designation?.noteText}</strong>
                </div>
           
               <div className="Right">
              */}
    </PageContainer>
  )
}
