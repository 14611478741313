import { ClapCardJuly } from '../../../components/july/ClapCardJuly'

export const VenueCardAbout = ({ venue }) => {
  return (
    <ClapCardJuly caption={`${venue.category?.code ?? "OVERVIEW"}`} fullHeight>
      <div className="flex flex-row mt-2">
        {venue?.merchant.logoImageUrl && (
          <div className="mr-8">
            <img
              src={venue?.merchant.logoImageUrl}
              alt="Logo"
              className="w-16 h-16 
              sm:w-32 sm:h-32"
              style={{
                borderRadius: 120
              }}
            />
          </div>
        )}
        <div className=" flex-1">
          <div
            className="mb-2 text-2xl font-bold"
            style={{ color: "var(--clap-color-violet)" }}
          >
            {venue.name}
          </div>
          <div className="my-2 text-xl font-medium">{venue.description}</div>
        </div>
      </div>
    </ClapCardJuly>
  )
}
