import useAxios from '../../../axios/axios-hooks'

export const useItemTypes = () => {
  const url = process.env.REACT_APP_CLAPHERE_REST_API + "/itemTypes"
  const [{ data, loading, error }, refetch] = useAxios(url, {
    // useCache: false
  })

  return {
    data,
    loading,
    error,
    refetch,
    itemTypes: data && data.edges ? data.edges.map(({ edge }) => edge) : []
  }
}

export const useItemType = (id) => {
  //   console.log("useItemType", id)
  const url = process.env.REACT_APP_CLAPHERE_REST_API + `/itemTypes/${id}`
  const [{ data, loading, error }, refetch] = useAxios(url, {
    // useCache: false
  })

  return {
    data,
    loading,
    error,
    refetch,
    itemType: data
  }
}
