import React from 'react'
import { match, useRouteMatch } from 'react-router-dom'

import { ClapButton } from '../../../components/ClapButton'
import { ClapDocumentActions, ClapDocumentHeader, ClapDocumentLineItem } from '../../../components/ClapDocumentPage'
import { FullPageContainer } from '../../../components/FullPageContainer'
import { LoadingWidget } from '../../../components/LoadingWidget'
import OgMeta from '../../../components/OgMeta'
import { PhotoWidget } from '../../../components/PhotoWidget'
import { PriceWidget } from '../../../components/PriceWidget'
import { useOrderQuery } from '../api/useOrderQuery'

interface UserPageMatch {
  orderId: string
}
export const OrderPage = (props) => {
  const matchParams: match<UserPageMatch> = useRouteMatch<UserPageMatch>()

  const { order, loading, data, error } = useOrderQuery(
    matchParams.params.orderId
  )
  console.log("Render OrderPage", loading, order)
  const _renderLineItems = () => {
    if (!order || !order.lineItems) {
      return <div>Empty</div>
    }

    return (
      <div>
        {order.lineItems.map((lineItem) => {
          return (
            <ClapDocumentLineItem>
              <div className="productPhoto">
                {lineItem.product.media && lineItem.product.media.length > 0 && (
                  <React.Fragment>
                    <PhotoWidget
                      photo={lineItem.product.media[0]}
                      width={200}
                    />
                  </React.Fragment>
                )}
              </div>

              <div className="product ">
                <div className="productName">
                  {lineItem.product && lineItem.product
                    ? lineItem.product.name
                    : "Product Name"}
                </div>
                <div className="productDescription">
                  {lineItem.product ? lineItem.product.description : ""}
                </div>
              </div>

              <div className="quantity">{lineItem.quantity}</div>

              <div className="totalPrice">
                {lineItem.totalPrice && (
                  <React.Fragment>
                    <PriceWidget
                      value={lineItem.totalPrice}
                      currencyCode="THB"
                    />
                  </React.Fragment>
                )}
              </div>
            </ClapDocumentLineItem>
          )
        })}

        <div></div>
      </div>
    )
  }

  const _orderHeader = () => {
    return (
      <ClapDocumentHeader>
        <div className="Title">Order</div>

        {order && order.venue && order.venue.name && (
          <div className="Venue">{order.venue.name}</div>
        )}
      </ClapDocumentHeader>
    )
  }

  const _renderActions = () => {
    return (
      <ClapDocumentActions>
        <form
          action={`claphere://claphere.com/orders/ordersame/${order.id}`}
          target="_blank"
        >
          <ClapButton primary label="Order Same Same" fullWidth submit />
        </form>
      </ClapDocumentActions>
    )
  }
  return (
    <FullPageContainer>
      <OgMeta />
      <div className="Container">
        {loading && (
          <div style={{ padding: 24, textAlign: "center" }}>
            <LoadingWidget />
          </div>
        )}

        {!loading && order && (
          <React.Fragment>
            <div className="Header">{_orderHeader()}</div>
            <div className="Right">{_renderActions()}</div>
            <div className="Content">
              <div className="Left">
                <div className="SectionTitle">Order Details</div>
                {_renderLineItems()}
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    </FullPageContainer>
  )
}
