import { isEmpty } from "lodash"
import React from "react"
import { Helmet } from "react-helmet"
import { useLocaleContext } from "../utils/localeContext"

import { OgData, OgUtils } from "../utils/ogUtils"
import { useLocale } from "../utils/useLocale"

export const PostOgMeta = ({ post }) => {
  const localeParams: any = useLocale()
  const postOgMeta = { ...OgUtils.generateOgForPost(post, localeParams) }
  return <OgMeta {...postOgMeta} />
}
const OgMeta: React.FC<any> = (meta: any) => {
  const metadata: OgData = !isEmpty(meta) ? meta : OgUtils.getDefaultOg()
  // console.log("ogMeta", meta, metadata)
  const localeContext: any = useLocaleContext()
  console.log("OgMeta localeContext", localeContext)
  return (
    <Helmet>
      <title>{metadata.title}</title>
      <meta name="description" content="ClapHere" />
      {/* {metadata.url && <meta property="og:url" content={metadata.url} />} */}
      {metadata.type && <meta property="og:type" content={metadata.type} />}
      {metadata.title && <meta property="og:title" content={metadata.title} />}
      {metadata.description && (
        <meta property="og:description" content={metadata.description} />
      )}

      {metadata.images &&
        metadata.images.map((image: any) => {
          return [
            <meta property="og:image" content={image.url} />,
            <meta property="og:image:width" content={image.width} />
          ]
        })}
      {metadata.siteName && (
        <meta property="og:site_name" content={metadata.siteName} />
      )}
      {metadata.alIosUrl && (
        <meta property="al:ios:url" content={metadata.alIosUrl} />
      )}
      {metadata.alIosAppName && (
        <meta property="al:ios:app_name" content={metadata.alIosAppName} />
      )}
      {metadata.alAndroidUrl && (
        <meta property="al:android:url" content={metadata.alAndroidUrl} />
      )}
      {metadata.alAndroidAppName && (
        <meta
          property="al:android:app_name"
          content={metadata.alAndroidAppName}
        />
      )}
      <meta property="og:locale" content={localeContext.locale} />
    </Helmet>
  )
}

export default OgMeta
