import QRCode from "qrcode.react"
export const VenueQR = ({ venue, url }: { venue: any; url?: string }) => {
  var orderingUrl =
    url ??
    `https://claphere.app.link/th/shop?clap_venue_id=${
      venue.slug ?? venue.id
    }&clap_action=order&~channel=claphere.com`
  console.log("Venue qr", orderingUrl)
  return <QRCode value={orderingUrl} height="100%" />
}
