import styled from 'styled-components'

export const VenuesListPageStyled = styled.div`
  display: flex;
  flex-direction: column;

  // background: radial-gradient(
  //   94.03% 126.35% at 98.33% -26.2%,
  //   #7b00a6 0%,
  //   #3f0055 100%
  // );

  // padding-right: 38%;
  // border-radius: 20px;
`

export const ListContainer = styled.div`
  padding: 0 20px;
  a {
    color: inherit;
    text-decoration: none;
  }
  .Header {
    padding: 0 50px;
  }

  // max-width: 1020px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  min-height: 100vh;

  display: grid !important;
  grid-template-columns: repeat(12, 1fr) !important;
  gap: 32px 12px !important;

  // For mobile only
  @media (max-width: 735px) {
    padding: 0;
  }
`

export const VenueTileWraper = styled.div`
  grid-column: span 4 / auto !important;
  @media (max-width: 735px) {
    grid-column: span 12 / auto !important;
  }
`

export const MapContainer = styled.div`
  position: fixed;
  right: 0;
  width: 38%;
  height: calc(100vh - 68px);
  top: 68px;
  padding: 15px;
  border-radius: 20px;
  box-sizing: border-box;
  // For mobile only
  @media (max-width: 735px) {
    display: none;
  }
`

export const MapWrapper = styled.div`
  box-shadow: 0 30px 50px 0px rgba(45, 54, 95, 0.2);
  height: 100%;
  width: 100%;
  border-radius: 20px;
  > div {
    border-radius: 20px;
    > div {
      border-radius: 20px;
      > div {
        border-radius: 20px;
      }
    }
  }
`

export const LoaderContainer = styled.div`
  text-align: center;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`
