import React from "react"
import styled from "styled-components"
import { useLocation, useRouteMatch, match } from "react-router-dom"
import { FullPageContainer } from "../../../components/FullPageContainer"
import { LoadingWidget } from "../../../components/LoadingWidget"
import { ClapButton } from "../../../components/ClapButton"
import { PhotoWidget } from "../../../components/PhotoWidget"
import { PriceWidget } from "../../../components/PriceWidget"
import {
  ClapDocumentHeader,
  ClapDocumentLineItem,
  ClapDocumentActions
} from "../../../components/ClapDocumentPage"
import { useReservationQuery } from "../api/useReservationQuery"

interface ReservationPageMatch {
  reservationId: string
}
export const ReservationPage = (props) => {
  const location = useLocation()
  const match: match<ReservationPageMatch> = useRouteMatch<
    ReservationPageMatch
  >()

  const { reservation, loading, data, error } = useReservationQuery(
    match.params.reservationId
  )
  console.log("Render ReservationPage", loading, reservation)
  const _renderLineItems = () => {
    if (!reservation || !reservation.lineItems) {
      return <div>Empty</div>
    }

    return (
      <div>
        {reservation.lineItems.map((lineItem) => {
          return (
            <ClapDocumentLineItem>
              <div className="productPhoto">
                {lineItem.product.media && lineItem.product.media.length > 0 && (
                  <React.Fragment>
                    <PhotoWidget
                      photo={lineItem.product.media[0]}
                      width={200}
                    />
                  </React.Fragment>
                )}
              </div>

              <div className="product ">
                <div className="productName">
                  {lineItem.product && lineItem.product
                    ? lineItem.product.name
                    : "Product Name"}
                </div>
                <div className="productDescription">
                  {lineItem.product ? lineItem.product.description : ""}
                </div>
              </div>

              <div className="quantity">{lineItem.quantity}</div>

              <div className="totalPrice">
                {lineItem.totalPrice && (
                  <React.Fragment>
                    <PriceWidget
                      value={lineItem.totalPrice}
                      currencyCode="THB"
                    />
                  </React.Fragment>
                )}
              </div>
            </ClapDocumentLineItem>
          )
        })}

        <div></div>
      </div>
    )
  }

  const _orderHeader = () => {
    return (
      <ClapDocumentHeader>
        <div className="Title">Reservation</div>

        {reservation && reservation.venue && reservation.reservation.name && (
          <div className="Venue">{reservation.venue.name}</div>
        )}
      </ClapDocumentHeader>
    )
  }

  const _renderActions = () => {
    return (
      <ClapDocumentActions>
        <ClapButton primary label="Order Same Same" />
      </ClapDocumentActions>
    )
  }
  return (
    <FullPageContainer>
      <div className="Container">
        {loading && (
          <div style={{ padding: 24, textAlign: "center" }}>
            <LoadingWidget />
          </div>
        )}

        <React.Fragment>
          <div className="Header">{_orderHeader()}</div>
          <div className="Right">{_renderActions()}</div>
          <div className="Content">
            <div className="Left">
              <div className="SectionTitle">Reservation Details</div>
              {_renderLineItems()}
            </div>
          </div>
        </React.Fragment>
      </div>
    </FullPageContainer>
  )
}
