import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const ListItemDiv: any = styled.div`
  padding: 15px 20px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  transition: background-color 100ms ease-in;

  :last-child {
    border-bottom: none;
  }
  &:hover {
    background-color: rgba(100, 100, 110, 0.03);
  }
  &-Expanded {
  }
  .name {
    font-weight: 600;
  }
  .description {
    font-size: 0.8em;
    color: #999;
    padding-top: 3px;
  }
  .price {
    font-size: 1.4em;
    font-weight: 700;
  }

  ${(props: any) =>
    props.isEdited &&
    `
    transform: scale(1.02);
    background-color: white !important;
    box-shadow: 0 5px 20px 5px rgba(50, 50, 100, 0.1);
    opacity: 1 !important;
  `}
`;

const ClapListItemContentStyled = styled.div`
  flex-direction: row;
  display: flex;
  width: 100%;

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .title {
    font-weight: bold;
  }

  .subtitle {
    color: #bbb;
  }
  .state {
    padding: 4px;
    border-radius: 5px;
    background-color: #dfe8e9;
  }
`;
type ClapListItemProps = {
  children?: any;
  isEdited?: boolean;
  onClick?: Function;
  linkTo?: string;
  optionsRenderer?: Function;

  title?: string;
  subtitle?: string;
  state?: string;
};

export const ClapListItem: React.SFC<ClapListItemProps> = ({
  children,
  title = undefined,
  subtitle = undefined,
  state = undefined,
  isEdited = false,
  onClick = undefined,
  linkTo = undefined
}) => {
  var content = children;
  if (!children) {
    content = (
      <ClapListItemContentStyled>
        <div className="content">
          <div className="title">{title}</div>
          <div className="subtitle">{subtitle}</div>
        </div>
        <div>
          <div className="state">{state}</div>
        </div>
      </ClapListItemContentStyled>
    );
  }

  return (
    <ListItemDiv isEdited={isEdited} onClick={onClick}>
      {linkTo ? (
        <Link to={linkTo} style={{ display: "inline-block", width: "100%" }}>
          {content}
        </Link>
      ) : (
        content
      )}
    </ListItemDiv>
  );
};
