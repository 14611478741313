import React from 'react'
import { Link } from 'react-router-dom'

export const PostTools = ({ post }) => {
  const handleClickShare = () => {
    console.log("handleClickShare")
    // this.props.onClickShare(this.props.post);
  }
  const handleClickBookmark = async () => {
    console.log("handleClickBookmark")

    // try {
    //   await this.props.onToggleBookmark(
    //     this.props.post.feedUid,
    //     !this.props.post.bookmark
    //   );
    // } catch (err) {
    //   console.log("ERROR creating bookmark");
    // }
  }

  return (
    <div className="Options">
      <div className="Option">
        {/* <img src="/assets/clap-icon.png" style={{ width: 24, height: 24 }} /> */}
        {/* <FontAwesomeIcon
              title="12 People liked this recommendation"
              icon={faHeart}
              size="lg"
              style={{ color: 'red', marginRight: 5 }}
            /> */}
        {/* <div>12</div> */}
      </div>
      <div className="Option">
        {/* <FontAwesomeIcon
            title="20 People used this recommendation"
            icon="faDollarSign"
            size="lg"
          /> */}
        {/* <div>22</div> */}
      </div>
      <div className="Option" onClick={handleClickShare} title="Share">
        {/* <FontAwesomeIcon title="Share" icon="faShare" size="lg" /> */}
      </div>

      <div className="Option" title="Open in new window">
        <Link to={`/posts/${post.id}`}>
          {/* <FontAwesomeIcon icon={faExternalInk} size="lg" /> */}
        </Link>
      </div>

      <div
        className="Option"
        title={
          post.bookmark
            ? "Bookmarked at " + post.bookmark.createdAt + " - click to remove"
            : "Click to bookmark"
        }
        onClick={handleClickBookmark}
      >
        {/* <FontAwesomeIcon
            icon={post.bookmark ? faBookmarkOn : faBookmark}
            size="lg"
          /> */}
      </div>
    </div>
  )
}
