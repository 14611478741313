// /** @jsxImportSource @emotion/react */
// import tw from 'twin.macro'

// change defailt color to #212b36
const TypeTileTitle = (props) => (
  <div
    className="font-normal font-extrabold leading-4"
    style={{ color: "var(--clap-color-black-violet)" }}
  >
    {props.children}
  </div>
)

const ProductName = (props) => (
  <div
    className="font-normal font-bold leading-4 md:text-2xl"
    style={{ color: "var(--clap-color-blue)" }}
  >
    {props.children}
  </div>
)

const ProductDescription = (props) => (
  <div
    className="font-normal leading-4 md:text-xl"
    style={{ color: "var(--clap-color-grey)" }}
  >
    {props.children}
  </div>
)

const HeaderVenuePageName = (props) => (
  <div
    className="text-2xl font-bold"
    style={{ color: "var(--clap-color-violet)" }}
  >
    {props.children}
  </div>
)

const Caption = (props) => (
  <div className="text-sm font-normal text-grey">{props.children}</div>
)

const SectionTitle = (props) => (
  <div
    className="my-4 font-bold text-xl sm:ml-2"
    style={{ color: "var(--clap-color-violet)" }}
  >
    {props.children}
  </div>
)

const PageTitleH1 = (props) => (
  <div
    style={{ color: "var(--clap-color-violet)" }}
    className="text-3xl sm:text-6xl 
      my-2 sm:my-4
      italic font-black"
  >
    {props.children}
  </div>
)
const ClapTypo = {
  PageTitleH1,
  TypeTileTitle,
  HeaderVenuePageName,
  Caption,
  SectionTitle,
  ProductDescription,
  ProductName
}
export default ClapTypo
