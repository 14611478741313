import React from "react"
import { useLocation } from "react-router"
import { Route, Switch, useRouteMatch, withRouter } from "react-router-dom"

import { Footer } from "../../../components/footer"
import { HrefLang } from "../../../components/HrefLang"
import { ClapCardJuly } from "../../../components/july/ClapCardJuly"
import { LoadingWidget } from "../../../components/LoadingWidget"
import OgMeta from "../../../components/OgMeta"
import { PageContainer } from "../../../components/PageContainer"
import { OgUtils } from "../../../utils/ogUtils"
import { useVenueQuery } from "../api/VenuePageHoc"
import { VenueCardAbout } from "../components/VenueCardAbout"
import { VenueCardDetails } from "../components/VenueCardDetails"
import { VenueMenuNew } from "../components/VenueMenuNew"
import { VenuePageFeed } from "../components/VenuePageFeed"
import { VenuePageSectionQR } from "../components/VenuePageSectionQR"
import { VenueSectionFeed } from "../components/VenueSectionFeed"
import VenuesMediaGallery from "../components/VenuesMediaGallery"
import { VenueClapMenu } from "./VenueClapMenu"
import { Benefit, BenefitSeprator, BenefitTitle } from "./VenueDetailStyled"

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

export const VenuePageInner = ({
  venueId,
  whitelabel,
}: {
  whitelabel?: boolean
  venueId: string
}) => {
  const { venue, loading, data, error } = useVenueQuery(venueId)

  const route = useRouteMatch()

  // const renderBenefit = (benefit) => {
  //   return (
  //     <Benefit>
  //       <BenefitSeprator />
  //       <BenefitTitle>{benefit.name || ""}</BenefitTitle>
  //     </Benefit>
  //   )
  // }

  // console.log("Render Venue page, venue:", venue, route)
  if (!venue) {
    return (
      <PageContainer>
        <div className="container m-auto max-w-screen-xl min-h-screen">
          <div className="flex items-center justify-center min-h-screen">
            <LoadingWidget size="4x" />
          </div>
        </div>
      </PageContainer>
    )
  }

  return (
    <Switch>
      <Route path={`${route.url}/menu`}>
        <VenueClapMenu venue={venue} />
      </Route>
      <Route path={`${route.url}/feed`}>
        <VenuePageFeed venue={venue} />
      </Route>
      <Route>
        <PageContainer
          style={{
            paddingLeft: 0,
            paddingRight: 0,
            backgroundColor: "var(--clap-color-orange-100)",
          }}
        >
          <div className="">
            {venue && (
              <>
                <div
                  className="container 
                  sm:mx-2 
                  xl:m-auto 
                  max-w-screen-xl mb-8 "
                >
                  <VenuesMediaGallery media={venue ? venue.media : []} />
                </div>

                <div
                  className="container 
                  m-auto max-w-screen-xl mb-8 mt-8"
                >
                  <div className="mx-4 grid grid-cols-1 sm:grid-cols-12 gap-8 ">
                    <div className="sm:col-span-7 sm:row-span-3">
                      <VenueCardAbout venue={venue} />
                    </div>

                    <div className="sm:col-span-5">
                      <VenuePageSectionQR venue={venue} />
                    </div>
                    <div className="sm:col-span-5">
                      <VenueCardDetails venue={venue} />
                    </div>
                    <div className="sm:col-span-5">
                      <VenueSectionFeed venue={venue} />
                    </div>
                  </div>
                </div>

                <div
                  className="container 
                  m-auto max-w-screen-xl"
                >
                  <div
                    className="mx-4  rounded-lg"
                    style={{
                      boxShadow: "0 30px 50px #dedfe4",
                      color: "#1b1b1d",
                    }}
                  >
                    <ClapCardJuly>
                      <OgMeta {...OgUtils.generateOgForVenue(venue)} />
                      <HrefLang />
                      {/* <div >
              <LeftBubble>
                <ClapBubble />
              </LeftBubble>
            </div> */}

                      <section className=" sm:px-12 " id="venueMenuWrapper">
                        {venue && <VenueMenuNew venue={venue} />}
                      </section>
                    </ClapCardJuly>
                  </div>
                  {/* {loading && (
              <div className="LoadingWrapper" id="loadingWrapper">
                <LoadingWidget />
              </div>
            )} */}
                </div>
              </>
            )}
            {!whitelabel && <Footer />}
            {whitelabel && <div style={{ height: 20 }} />}
          </div>
        </PageContainer>
      </Route>
    </Switch>
  )
}

export const VenuePage = withRouter((props) => {
  const location = useLocation()
  const query = useQuery()
  const queryVenueId = query.get("clap_venue_id")
  // console.log("queryVenueId", queryVenueId)
  // console.log("location", location)
  const venueId = props.match.params.venueId ?? queryVenueId

  if (!venueId) {
    return <div>Can't find this shop</div>
  }
  return <VenuePageInner venueId={venueId} />
  // const renderBenefits = () => (
  //   <Section>
  //     <h2>Benefits</h2>
  //     {venue && venue.benefits && venue.benefits.length > 0 ? (
  //       <Benefits>{venue.benefits.map(renderBenefit)}</Benefits>
  //     ) : (
  //       <div>There are no special benefits at this venue now</div>
  //     )}
  //   </Section>
  // )

  // const renderGames = () => (
  //   <Section>
  //     <h2>Challenges and Missions</h2>
  //     {venue && venue.availableGames && venue.availableGames.length > 0 ? (
  //       <Benefits>
  //         <VenueGamesList games={venue.availableGames} />
  //       </Benefits>
  //     ) : (
  //       <div>There are no missions at this venue now</div>
  //     )}
  //   </Section>
  // )
  // function renderInner() {
  //   return <></>
  //   return (
  //     <React.Fragment>
  //       <Left>
  //         <Section
  //           className={`MpsMdCard MpsMdCard--xl ${styles.VenueDetail}`}
  //           style={{
  //             // boxShadow: '0 8px 16px 1px rgba(60,64,67,0.30)',
  //             borderRadius: 6,
  //             marginBottom: 16,
  //             padding: 0
  //           }}
  //         >
  //           <PlaceWrapper>
  //             <Place>
  //               <PlaceName>{venue.name}</PlaceName>
  //               <PlaceAddress>
  //                 {venue.city} {venue.street}
  //               </PlaceAddress>
  //               <Categories>
  //                 <CategoryItem>Restaurant</CategoryItem>
  //               </Categories>
  //               <div className="mobileActions">
  //                 <CTAContainer onClick={handleOrderNow}>
  //                   <FormattedMessage
  //                     id="orderNow"
  //                     defaultMessage="Order Now"
  //                   />
  //                 </CTAContainer>
  //               </div>
  //             </Place>
  //           </PlaceWrapper>
  //           <Discription>
  //             {venue.description
  //               ? venue.description
  //               : "There is no description for this venue yet"}
  //           </Discription>
  //         </Section>
  //         {/* {renderBenefits()}
  //         {renderGames()} */}
  //         <Section>
  //           <h2>Claps for {venue.name}</h2>
  //           <VenueFeed venueId={venue.id} venue={venue} />
  //         </Section>
  //       </Left>
  //       <Right>
  //         <RightPanelSpacing />
  //         <CTAContainer onClick={handleOrderNow}>
  //           <FormattedMessage id="orderNow" defaultMessage="Order Now" />
  //         </CTAContainer>
  //         <section>
  //           <VenueMenu venueId={venue.id} venue={venue} />
  //         </section>
  //       </Right>
  //     </React.Fragment>
  //   )
  // }
})
