import useAxios from 'axios-hooks'

// const qlGetOrder = gql(
//   `query order($orderId: String!)
//     { order(orderId:$orderId)
//       {
//           id
//           orderedAt
//           venue {
//              id
//              name
//              description
//              street
//              media {
//                 id type urls { w url }
//              }
//           }
//           lineItems {
//              product {
//                id
//                name
//                description
//                media {
//                  id
//                  type
//                  urls { w url }
//                }
//              }
//              quantity
//              unitPrice
//              totalPrice
//           }
//           stage
//           accessLevel
//         }

//     }`
// );

export const useOrderQuery = (orderId) => {
  const url =
    process.env.REACT_APP_CLAPHERE_REST_API +
    "/ordering/v2/CLAP/order/" +
    orderId +
    "?role=public"
  const [{ data, loading, error }, refetch] = useAxios(url, {
    useCache: false
  })

  // const { data, loading, error } = useQuery(qlGetOrder, {
  //   variables: { orderId },
  //   fetchPolicy: "cache-and-network"
  // });

  // const order = !loading && data && data.order ? data.order : undefined;

  return { loading, data, error, order: data, refetch }
}
