// /** @jsxImportSource @emotion/react */
// import tw as
import React from "react"

import ClapTypo from "../../../components/ClapTypo"
import { PhotosScroll } from "../../../components/PhotoScroll"

export const VenueCard = (props: any) => {
  const { venue } = props

  const hasPhotos = venue.media && venue.media.length > 0
  return (
    <div
      className="
        flex 
        flex-row sm:flex-col
        h-28 sm:h-64
        bg-white
        rounded-xl
        overflow-hidden
        shadow-lg
        hover:shadow-xl
        transform 
        transition-transform
        hover:-translate-y-1
      "
      // style={{
      //   boxShadow: "0 30px 50px 0px rgba(45, 54, 95, 0.05)"
      // }}
    >
      {/* Photo wrapper */}
      <div
        className="
        m-3 sm:m-0 
        sm:p-0 
        rounded-xl sm:rounded-none
        w-24 sm:w-full overflow-hidden"
        style={{
          height: "61.8%"
        }}
      >
        {/* <div className="w-full relative m-auto " style={{height:162, minHeight:162}}> */}
        {hasPhotos && (
          <picture
            className="Photo w-full h-full rounded-xl sm:rounded-none overflow-hidden"
            //  style={{height:162,minHeight:162}}
          >
            <PhotosScroll photos={venue.media} width={400} scroll={false} />
          </picture>
        )}
        {!hasPhotos && (
          <React.Fragment>
            <div
              className="Photo w-full h-full"
              style={{
                width: "100%",
                position: "relative",
                backgroundColor: "#dedede",
                padding: 20,
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              No photos yet
            </div>
          </React.Fragment>
        )}
      </div>
      <div
        className="Details flex-1 
        px-2 sm:px-4 
        py-1 sm:py-2
      "
      >
        {/* <div className="flex flex-col px-6 h-full"> */}
        {/* <div className="Name">{venue.name}</div> */}
        <div className="pt-2 pb-1 pr-1 overflow-hidden text-black">
          <ClapTypo.TypeTileTitle
          // style={{ color: "#212b36" }}
          >
            {venue.name}
          </ClapTypo.TypeTileTitle>
        </div>
        <div
          className="flex-1 text-xs"
          // style={{ color: "#8a92a6" }}
        >
          {venue.city}
          {venue.street ? ", " + venue.street : ""}
        </div>
        {/* <div className="Address"> */}
        {/* {venue.city}
          {venue.street ? ", " + venue.street : ""} */}
        {/* </div> */}
        {/* <div className="Footer"> */}
        {/* <img
            src="/assets/clap-icon.png"
            style={{ width: 24, height: 24 }}
            alt=""
          />
          <div>25 claps</div> */}
        {/* </div> */}
      </div>
      {/* <div className="Footer">
        </div> */}
    </div>
    // </VenuesListItemStyled>
  )
}
