import { Switch } from 'react-router-dom'

import { ClapHereNav } from './components/ClapHereNav'
import { GlobalStyles } from './styles/GlobalStyles'

const App = (props) => {
  console.log("app (6)")
  return (
    <div
      className="App"
      // style={{
      //   background:
      //     "radial-gradient(94.03% 126.35% at 98.33% -26.2%, rgb(123, 0, 166) 0%, rgb(63, 0, 85) 100%)"
      // }}
    >
      <GlobalStyles />
      <Switch>
        <ClapHereNav />
      </Switch>
    </div>
  )
}

export default App
