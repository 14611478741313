import axios from 'axios'
import { Fragment, useEffect, useState } from 'react'
import { Route, Switch, useRouteMatch } from 'react-router'
import { Link } from 'react-router-dom'

import { ClapButton } from '../../components/ClapButton'
import ClapTypo from '../../components/ClapTypo'
import { Footer } from '../../components/footer'
import { PageContainer } from '../../components/PageContainer'
import { useLocale } from '../../utils/useLocale'
import { FeedPage } from '../feed/pages/FeedPage'
import { VenueCard } from '../venues/components/VenueCard'
import { VenuesSearchSection } from '../venues/pages/components/VenuesSearchSection'
import { VenuesPage } from '../venues/pages/VenuesPage'
import { DiscoverTabSwitch } from './DiscoverTabSwitch'

export const DiscoverPage = () => {
  const [isSearching, setIsSearching] = useState(false)
  const [searchResults, setSearchResults] = useState<any>()
  const locale = useLocale()

  const route = useRouteMatch()

  const handleDoSearch = async (queryString) => {
    console.log("handleDoSearch", queryString)
    setIsSearching(true)
    try {
      var url =
        process.env.REACT_APP_CLAPHERE_REST_API +
        `/venues/v3/CLAP/search?query=${queryString}&country=THAILAND`

      var res = await axios(url)
      if (res.status === 200) {
        console.log("Got res.dat", res.data)
        if (res.data.results) {
          setSearchResults(res.data)
        }
      }
    } catch (err) {}
    setIsSearching(false)
  }
  useEffect(() => {
    // this.props.onSearch();
    if (window && window.scrollTo) {
      window.scrollTo(0, 0)
    }
  }, [])

  return (
    <PageContainer>
      <div
        className="pt-2 sm:pt-8 
        min-h-screen"
      >
        <div
          className="container m-auto max-w-screen-xl 
            px-0 sm:px-0"
        >
          <div className="text-center">
            <ClapTypo.PageTitleH1>Discover</ClapTypo.PageTitleH1>
          </div>

          <div className="flex flex-col items-center">
            <VenuesSearchSection onSearch={handleDoSearch} />
          </div>

          {searchResults !== undefined && (
            <div className="my-4 text-center">
              <ClapTypo.HeaderVenuePageName>
                <div>
                  This is what we found for you for for "{searchResults.query}"
                </div>
              </ClapTypo.HeaderVenuePageName>
              <div>
                <ClapButton
                  textButton
                  label="Back to list"
                  onClick={() => {
                    setSearchResults(undefined)
                  }}
                />
              </div>
            </div>
          )}

          {searchResults?.results !== undefined && (
            <div
              id="venuesListContainer"
              className="grid grid-cols-1 
                  sm:grid-cols-3 sm:gap-6 min-h-screen"
            >
              {searchResults.results.map((v: any) => {
                var cityCode = v.cityCode ? v.cityCode.toLowerCase() : "bangkok"
                cityCode = cityCode.replace("thailand#", "")

                return (
                  <Fragment key={v.id}>
                    <div className="my-2 sm:my-0">
                      <Link
                        to={`/${locale.country}/${locale.lang}/${cityCode}/${
                          v.slug ?? v.id
                        }`}
                      >
                        <VenueCard key={v.id} venue={v} />
                      </Link>
                    </div>
                  </Fragment>
                )
              })}
            </div>
          )}
          {!searchResults?.results && (
            <Switch>
              <Route path={`${route.url}/feed`}>
                <>
                  <DiscoverTabSwitch rootUrl={route.url} page="feed" />
                  <FeedPage />
                </>
              </Route>
              <Route>
                <>
                  <DiscoverTabSwitch rootUrl={route.url} page="shops" />
                  <VenuesPage />
                </>
              </Route>
            </Switch>
          )}
        </div>
      </div>
      <Footer />
    </PageContainer>
  )
}
