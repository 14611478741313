import { ClapCardJuly } from "../../../components/july/ClapCardJuly"
import { PageContainer } from "../../../components/PageContainer"
import { VenueMenuNew } from "../components/VenueMenuNew"

export const VenueClapMenu = ({ venue }) => {
  return (
    // <PageContainer>

    <div className="container m-auto max-w-screen-xl min-h-screen">
      {/* <div className="bg-white">clap menu</div> */}
      <ClapCardJuly>
        <VenueMenuNew venue={venue} />
      </ClapCardJuly>
    </div>

    // </PageContainer>
  )
}
