import { useState } from "react"

import useAxios from "../../../axios/axios-hooks"

export const useVenues = (countryCode?: string) => {
  var url = process.env.REACT_APP_CLAPHERE_REST_API + "/venues/v2/CLAP/venues"
  if (countryCode?.toLowerCase() === "pl") {
    url += "?countryCode=POLAND"
  }
  const [{ data, loading }, refetch] = useAxios(url, {
    // useCache: false
  })
  let venues
  if (data && data.edges) {
    venues = data.edges.map((i) => i.edge)
  }

  return { venues, loading, refetch }
}

const venuesListHoc = (WrappedComponent: any): any => {
  const VenuesListInner = (props) => {
    const [selectedCategoryCodes, setSelectedCategoryCodes] = useState({})
    const [searchQuery] = useState<string | undefined>(undefined)
    const url =
      process.env.REACT_APP_CLAPHERE_REST_API + "/venues/v2/CLAP/venues"
    const [{ data, loading }] = useAxios(url, {
      // useCache: false
    })
    let venues
    if (data && data.edges) {
      venues = data.edges.map((i) => i.edge)
    }

    // const startSearch = async () => {
    //   console.log("Start List Venues");

    //   const search = {};
    //   try {
    //     const res = await this.props.client.query({
    //       query: qlListAllVenues,
    //       variables: {},
    //       fetchPolicy: "no-cache",
    //       context: {
    //         mpsModule: "public-venues"
    //       }
    //     });
    //     console.log("Got all_venues result", res);
    //     let venues = [];
    //     if (res && res.data && res.data.venues) {
    //       venues = res.data.venues.edges.map((e: any) => e.edge);
    //       console.log("venues", venues);
    //     }

    //     console.log("Set state");
    //     await this.setStateAsync({ isLoading: false, venues });
    //   } catch (err) {
    //     console.log("Errr", err);
    //     await this.setStateAsync({ isLoading: false });
    //   }
    // };

    const handleToggleCategory = (code: any) => {
      const newSelectedCategories = { ...selectedCategoryCodes }
      if (newSelectedCategories[code]) {
        delete newSelectedCategories[code]
      } else {
        newSelectedCategories[code] = true
      }
      setSelectedCategoryCodes(newSelectedCategories)
    }

    const handleQueryChange = (query: any) => {
      // this.setState({ searchQuery: query });
    }
    // const handleSearch = () => {
    //   // this.startSearch();
    //   // debounce(() => this.startSearch, 3000)
    // }
    // const handleSearchQueryChange = (query: any) => {}
    // const handleSortOptionChange = (sortOption: any) => {}
    return (
      <WrappedComponent
        {...props}
        // onSearch={this.handleSearch}
        venues={venues}
        isLoading={loading}
        onQueryChange={handleQueryChange}
        searchQuery={searchQuery}
        // sortOption={sortOption}
        // onSortOptionChange={this.handleSortOptionChange}
        onToggleCategory={handleToggleCategory}
        selectedCategoryCodes={selectedCategoryCodes}
      />
    )
  }

  return VenuesListInner
}

export default venuesListHoc
