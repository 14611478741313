import { Helmet } from "react-helmet"
import { useRouteMatch } from "react-router"

export const HrefLang = () => {
  const router = useRouteMatch<any>()
  console.log("HrefLang", router)
  var links: any = []
  if (router.params?.country && router.params?.lang) {
    const parts = router.url.split("/")
    parts[2] = "en"
    links.push({
      href: `https://www.claphere.com${parts.join("/")}`,
      lang: "en"
    })
    parts[2] = "th"
    links.push({
      href: `https://www.claphere.com${parts.join("/")}`,
      lang: "th"
    })
    console.log("links", links)
  }
  return (
    <Helmet>
      {links.map((l: any) => (
        <link rel="alternate" href={l.href} hrefLang={l.lang} />
      ))}
    </Helmet>
  )
}
