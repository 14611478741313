import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import { calculatePhotoUrl } from '../utils/imageUrl'

const Img = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`

interface ITagProps {
  top?: number
  left?: number
}

const Tag = styled.div<ITagProps>`
  position: absolute;
  top: ${({ top }: any) => top && ` ${top}px; `};
  left: ${({ left }: any) => left && ` ${left}px; `};
  color: #7c28bb;
  background: #fff;
  padding: 8px;
  border-radius: 25px;
  font-size: 12px;
  font-weight: bold;
  max-width: 120px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  transition: background 150ms ease-in;

  &:hover {
    background: #e7dfed;
  }

  .name {
    flex: 1;
  }
  .dot {
    width: 8px;
    height: 8px;
    margin: 4px;
    background-color: #7c28bb;
    border-radius: 16px;
  }
`

export const PhotoWithTags = ({
  photo,
  tags,
  size
}: {
  photo: any
  tags: any
  size?: number
}) => {
  const elementRef: any = useRef(null)
  const [height, setHeight] = useState(293)
  const [width, setWidth] = useState(0)

  // useEffect(() => {
  //   setHeight(elementRef?.current?.clientHeight);
  // }, [elementRef]);

  useEffect(() => {
    // console.log(elementRef)
    // console.log(elementRef.current.offsetWidth, "client width")
    // console.log(elementRef.current.clientHeight, "client width clientHeight")
    const { clientWidth } = elementRef.current
    setWidth(clientWidth)
  }, [elementRef])

  const picture = calculatePhotoUrl(photo)
  return (
    <div className="relative rounded-2xl overflow-hidden">
      <Img ref={elementRef} src={picture.url} alt="" />
      {tags.map((tag: any, idx: number) => (
        <Tag key={idx} top={tag.x * width} left={tag.y * height}>
          <div className="dot" />
          <div className="name">{tag?.product?.name || tag.label}</div>
        </Tag>
      ))}
    </div>
  )
}
