import { useRouteMatch } from "react-router"
import { ClapButton } from "../../../components/ClapButton"
import { ClapModal, ClapModalContent } from "../../../components/ClapModal"
import ClapTypo from "../../../components/ClapTypo"
import OgMeta from "../../../components/OgMeta"
import { PhotosScroll } from "../../../components/PhotoScroll"
import { OgUtils } from "../../../utils/ogUtils"
import { VenuePageSectionQR } from "../components/VenuePageSectionQR"
import { VenueQR } from "../components/VenueQR"

const ProductQr = ({ product, venue }) => (
  <div
    className="h-42 w-full bg-white rounded-2xl flex"
    style={{
      boxShadow: "var(--clap-shadow-card-21)"
    }}
  >
    {/* <div
      className="flex-1 text-white p-4 rounded-2xl items-center flex flex-col"
      style={{
        backgroundColor: "var(--clap-color-orange)",
        boxShadow: "var(--clap-shadow-card-21)"
      }}
    >
      <div className="font-normal text-sm">Open in</div>
      <div className="font-black text-sm">ClapHere App</div>
      <div className="flex-1" />
      {/* <div className="p-2 flex">
      <AppIcon
        label="Get it on"
        img="/assets/img/google_play_with_logo_white.svg"
        alt="Google Play"
        href="https://claphere.onelink.me/UAtl/installwebsite"
      />
      <AppIcon
        label="Get it on"
        img="/assets/img/app_store_with_logo_white.svg"
        alt="Google Play"
        href="https://claphere.onelink.me/UAtl/installwebsite"
      />
    </div> 
    </div> */}
    <div className="w-48 p-1 flex flex-col items-center">
      <VenueQR venue={venue} />
    </div>
  </div>
)
export const VenueMenuItemPopup = ({ product, onCancel, venue }) => {
  const route = useRouteMatch()
  return (
    <ClapModal isOpen title={product.name} onCancel={onCancel}>
      <OgMeta {...OgUtils.generateOgForProduct(product, venue, route.url)} />
      {/* <ClapModalContent title={product.name} onCancel={onCancel}> */}
      <div id="VenueMenuItemPopupDiv" style={{ width: "100%" }}>
        <div style={{ margin: "0 -1px" }} className="h-48 md:h-80 relative">
          <PhotosScroll photos={product.media} width="100%" />
          <div className="absolute" style={{ right: 0, bottom: 0 }}>
            {/* <ProductQr product={product} venue={venue} /> */}
          </div>
        </div>
        <div className="px-6 py-6">
          <div className="pb-6">
            <div className="flex flex-row">
              <div className="flex-1">
                <ClapTypo.ProductName>{product.name}</ClapTypo.ProductName>
              </div>
              {/* <div className="w-24"></div> */}
            </div>
          </div>
          <div className="pb-6">
            <ClapTypo.ProductDescription>
              {product.description}
            </ClapTypo.ProductDescription>
          </div>
        </div>
        <div className="px-6 py-6"></div>
        <div className="p-6">
          <ClapButton primary label="OK" fullWidth onClick={onCancel} />
        </div>
      </div>
      {/* </ClapModalContent> */}
    </ClapModal>
  )
}
