import React, { Component } from 'react'

import { FeedCardRoman } from '../components/FeedCardRoman'
import { SectionFeedHoc } from './SectionFeedHoc'

// import PostItem from "./feed-item.jsx";
// import SharePopup from "./share-popup.jsx";
class RecommendationsFeed extends Component<any, any> {
  componentDidMount() {
    // this.props.onSearch()
    if (window) {
      window.addEventListener("scroll", this.handleOnScroll)
    }
  }

  handleStartShare = (post) => {
    console.log("handleStartShare", post)
    this.setState({ hasSharePopup: true, sharePost: post })
  }
  handleCloseReshare = () => {
    this.setState({ hasSharePopup: false, sharePost: undefined })
  }

  componentWillUnmount = () => {
    window.removeEventListener("scroll", this.handleOnScroll)
  }
  handleOnScroll = () => {
    // console.log("handleOnScroll");
    // http://stackoverflow.com/questions/9439725/javascript-how-to-detect-if-browser-window-is-scrolled-to-bottom
    var scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop
    var scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight
    var clientHeight =
      document.documentElement.clientHeight || window.innerHeight
    var scrolledToBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight
    if (scrolledToBottom && this.props.hasNextPage) {
      this.props.onLoadMore()
    }
  }

  renderList(posts) {
    return (
      <div className="List">
        {posts.map((post) => (
          <div className="mb-4">
            <FeedCardRoman post={post} />
          </div>
          // <FeedItem2
          //   key={post.feedUid}
          //   post={post}
          //   onClickShare={this.handleStartShare}
          //   onToggleBookmark={this.props.onToggleBookmark}
          // />
        ))}
      </div>
    )
  }

  render() {
    let { posts } = this.props
    console.log("SectionFeed.render", this.props, this.state)
    if (posts) {
      posts = posts.map((p) => p.edge)
    }

    if (this.props.mockMix && posts) {
      // mix with 'consumption' posts
      const res: any = []
      posts.forEach((post) => {
        res.push({ ...post, type: "post" })
        res.push({
          ...post,
          type: "consumption"
        })
      })
      posts = res
    }
    return (
      <React.Fragment>
        {posts && this.renderList(posts)}
        {/* <SharePopup
          post={this.state.sharePost}
          isOpen={this.state.hasSharePopup}
          onClose={this.handleCloseReshare}
        /> */}
      </React.Fragment>
    )
  }
}

export const SectionFeed = SectionFeedHoc(RecommendationsFeed)
