import styled from "styled-components";

export const ClapDocumentPage = styled.div``;

export const ClapDocumentHeader = styled.div`
  padding: 24px;
  margin: 16px 0;

  .Title {
    font-size: 1.2rem;
    margin: 8px 0;
    color: #aaa;
  }
  .Venue {
    font-size: 2rem;
    font-weight: bold;
    margin: 16px 0;
  }

  @media (max-width: 725px) {
    padding: 8px 16px;
    margin: 0;

    .Title {
      font-size: 1rem;
      margin: 4px 0 0 0;
    }

    .Venue {
      font-size: 1.6rem;
      font-weight: bold;
      margin: 8x 0;
    }
  }
`;

export const ClapDocumentSection = styled.div``;

export const ClapDocumentActions = styled.div`
  margin: 24px 16px;

  .Action {
    margin: 0 4px;
  }

  @media (max-width: 725px) {
    margin: 8px 16px;
    .Action {
    }
  }
`;

export const ClapDocumentLineItem = styled.div`
  display: flex;
  flex-direction: row;

  padding: 15px 20px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  -webkit-transition: background-color 100ms ease-in;
  transition: background-color 100ms ease-in;

  // border-bottom: 1px solid #ccc;

  .product {
    flex: 1;
  }
  .productPhoto {
    width: 60px;
    height: 60px;
    border-radius: 10px;
    margin-right: 16px;
    overflow: hidden;
  }
  .productName {
    font-weight: bold;
  }
  .productDescription {
    margin: 8px 0;
    font-size: 0.8rem;
    color: #bbb;
  }
  .quantity {
    width: 150px;
    margin: 0 16px;
  }

  .totalPrice {
    font-weight: bold;
    font-size: 1.2rem;
    margin: 0 16px;
  }
  .summary {
  }

  @media (max-width: 725px) {
    padding: 0;

    .quantity {
      width: 50px;
    }
  }
`;
