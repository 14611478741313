import { FormattedMessage } from 'react-intl'

import { Footer } from '../../components/footer'
import OgMeta from '../../components/OgMeta'
import { PageContainer } from '../../components/PageContainer'

export const VisionPage = (props) => {
  return (
    <PageContainer>
      <div className="max-w-screen-lg m-auto">
      {/* <ClapHereNav /> */}
      <OgMeta />
      <div className="pt-2 sm:pt-8">
        <div className="Content" style={{ fontSize: "1.5rem" }}>
          <div>
            <h1 style={{ fontWeight: 900 }}>
              <FormattedMessage
                id="mission.title"
                defaultMessage="Vision"
              />
            </h1>
            <p>
              <FormattedMessage
                id="mission.p1"
                defaultMessage="ClapHere brings a healthier balance to social media, rewarding
              good service and long-term relationships. It lets users recommend
              their favorite shops and restaurants to their friends, while
              earning rewards for spreading a positive word. ClapHere also gives
              businesses the opportunity to keep in touch with their most loyal
              customers, offering special deals and promotions as a way to say
              thanks and encourage repeat business."
              />
            </p>

            <p className="my-2">
              <FormattedMessage
                id="mission.p2"
                defaultMessage="This ‘pay-it-forward’ system of community-based connection turns
              impersonal transactions into real relationships, helping customers
              and businesses get to know each other better along the way. It
              also features extra games and contests, making it even easier for
              businesses to deliver a fulfilling customer journey."
              />
            </p>
            <p className="my-2">
              <FormattedMessage
                id="mission.p3"
                defaultMessage="For businesses, ClapHere represents a welcome alternative to
              existing digital marketing solutions - social networks and search
              engines that use paid advertising requirements to funnel away a
              percentage of your revenue."
              />
            </p>
            <p style={{ fontWeight: 800 }} className="my-4">
              <FormattedMessage
                id="mission.p4"
                defaultMessage="ClapHere is our effort to build a future for social media that
              promotes positivity over polarization, and uses the power of
              incentives to transform strong passions into genuine community
              support."
              />
            </p>
          </div>
        </div>
      </div>

      <Footer />
      </div>
    </PageContainer>
  )
}
