import React from "react"
import { IntlProvider } from "react-intl"
import { Redirect, Route, Switch, useParams, useRouteMatch } from "react-router"
import { StaticRouter } from "react-router-dom"

import App from "./App"
import localeTh from "./lang/th.json"
import { BlogList } from "./modules/blog/pages/BlogList"
import { SaveYourBusinessBlogArticle } from "./modules/blog/pages/save-your-business-with-customers-loyalty"
import { BusinessPage } from "./modules/business/BusinessPage"
import { CarreersPage } from "./modules/careers/CareersPage"
import { ContactPage } from "./modules/contact/ContactPage"
import { CouponPage } from "./modules/coupons/pages/CouponPage"
import { DiscoverPage } from "./modules/discover/DiscoverPage"
import { FeedPage } from "./modules/feed/pages/FeedPage"
import { ItemTypesPage } from "./modules/game/pages/ItemTypesPage"
import { Home4 } from "./modules/home/Home4"
import { OrderPage } from "./modules/orders/pages/OrderPage"
import { PostPage } from "./modules/posts/pages/PostPage"
import { PricingPage } from "./modules/pricing/PricingPage"
import { ReservationPage } from "./modules/reservations/pages/ReservationPage"
import { PrivacyPage } from "./modules/terms/PrivacyPage"
import { TermsPage } from "./modules/terms/TermsPage"
import { TestPage } from "./modules/test/TestPage"
import { UserPage } from "./modules/users/pages/UserPage"
import { VenuePage } from "./modules/venues/pages/VenuePage"
import { VisionPage } from "./modules/vision/VisionPage"
import { LocaleContext, LocaleContextProvider } from "./utils/localeContext"
import { useLocale } from "./utils/useLocale"

// import BootstrapProvider from "@bootstrap-styled/v4/provider";
// import locale_en from 'react-intl/locale-data/en';
// addLocaleData([...locale_th])

export const AppRootServer = (props) => (
  <StaticRouter>
    <AppRouter />
  </StaticRouter>
)

export const RootLangProvider = (props) => {}

const Redirector = (props) => {
  const match = useRouteMatch()
  const params = useParams()
  console.log("Redirector, ", match, params)
  console.log("Do redirect")
  return <Redirect to={`/th/th${match.url}`} />
}

const LocalizedContent = (props) => {
  const localeParams: any = useLocale()
  const { user, signOut, signInWithGoogle } = props
  const handleSetLocale = (newLocale) => {
    // build redirect
    console.log("handle set locale", newLocale)
    // setLocale(newLocale)
    // if (window.localStorage) {
    //   window.localStorage.setItem("locale", newLocale)
    // }
  }
  console.log("LocalizedContent", localeParams)
  return (
    <LocaleContextProvider
      lang={localeParams.lang}
      country={localeParams.country}
    >
      <IntlProvider
        messages={localeParams.lang === "en" ? {} : localeTh}
        locale={localeParams.lang}
        defaultLocale="en"
      >
        {/* <Helmet
              title="ClapHere - Recommendations with Benefits"
              htmlAttributes={{ lang: "en", amp: undefined }}
              meta={[
                {
                  name: "description",
                  content: "Recommendations with Benefits"
                }
              ]}
            /> */}

        <Switch>
          {/* <Route path="/business" component={BusinessIndex} /> */}
          <React.Fragment>
            {/* <Route
              exact
              path="*"
              render={(routerProps) => (
               
              )}
            /> */}
            {/* <LandingPage /> */}
            <App
              // {...routerProps}
              user={user}
              signOut={signOut}
              firebase={props.firebase}
              signInWithGoogle={signInWithGoogle}
            />
            <Switch>
              <Route path="/:country/:lang/test1" component={TestPage} />

              {/* <Route exact path="/home2" component={Home2} /> */}
              <Route
                path="/:country/:lang/orders/:orderId"
                component={OrderPage}
              />
              <Route
                path="/:country/:lang/posts/:postId"
                component={PostPage}
              />
              <Route
                path="/:country/:lang/users/:userId"
                component={UserPage}
              />
              <Route path="/coupon/:couponId">
                <Redirector />
              </Route>
              <Route
                path="/:country/:lang/coupon/:couponId"
                component={CouponPage}
              />

              <Route path="/:country/:lang/terms" component={TermsPage} />
              <Route path="/:country/:lang/privacy" component={PrivacyPage} />
              <Route path="/:country/:lang/careers" component={CarreersPage} />
              <Route path="/:country/:lang/business" component={BusinessPage} />
              <Route path="/:country/:lang/about" component={VisionPage} />
              <Route path="/:country/:lang/pricing" component={PricingPage} />

              <Route path="/:country/:lang/contact" component={ContactPage} />
              <Route path="/contact" component={ContactPage} />
              {/* Other urls - venues */}

              {/* Final */}
              <Route
                path="/:country/:lang/:city/discover"
                component={DiscoverPage}
              />
              <Route
                path="/:country/:lang/:city/discover/:discoverType"
                component={DiscoverPage}
              />
              <Route
                path="/:country/:lang/venues/:city/:venueId"
                component={VenuePage}
                exact
              />

              <Route
                path="/:country/:lang/shops/:city/:venueId"
                component={VenuePage}
                exact
              />
              <Route
                path="/:country/:lang/shop/:city/:venueId"
                component={VenuePage}
                exact
              />
              <Route
                path="/:country/:lang/venues/:venueId"
                component={VenuePage}
                exact
              />
              <Route
                path="/:country/:lang/shops/:venueId"
                component={VenuePage}
                exact
              />
              <Route path="/:country/:lang/shops" component={VenuePage} exact />
              <Route
                path="/:country/:lang/:city/venues/:venueId"
                component={VenuePage}
                exact
              />
              <Route
                path="/:country/:lang/:city/shops/:venueId"
                component={VenuePage}
                exact
              />
              <Route
                path="/:country/:lang/:city/shop/:venueId"
                component={VenuePage}
                exact
              />

              {/* 3 parts paths */}

              <Route path="/:country/:lang/discover" component={DiscoverPage} />
              <Route
                path="/:country/:lang/discover/:discoverType"
                component={DiscoverPage}
              />

              {/* Main shop path */}
              <Route
                path="/:country/:lang/:city/:venueId"
                component={VenuePage}
              />

              <Route path="/:country/:lang/:venueId" component={VenuePage} />

              <Route path="/feed" component={FeedPage} />

              <Route
                path="/:country/:lang/blog/save-your-business-with-customer-loyalty"
                component={SaveYourBusinessBlogArticle}
              />
              <Route path="/:country/:lang/blog" component={BlogList} />

              {/* 2 segments paths */}
              <Route
                path="/reservations/:reservationId"
                component={ReservationPage}
              />
              <Route path="/game/item-types" component={ItemTypesPage} />
              <Route path="/post/:postId" component={PostPage} />
              <Route exact path="/:country" component={Home4} />

              <Route exact path="/:country/:lang" component={Home4} />

              {/* <Route path="/:country/:lang/:venueId" component={VenuePage} /> */}

              <Route exact path="/" component={Home4} />
              <Redirect exact from="*" to="/" />
            </Switch>
          </React.Fragment>
        </Switch>
        {/* <ReduxToastr
                preventDuplicates
                progressBar
                timeOut={4000}
                newestOnTop={false}
                position="top-left"
                transitionIn="fadeIn"
                transitionOut="fadeOut"
              /> */}
      </IntlProvider>
    </LocaleContextProvider>
  )
}

export const AppRouter = (props) => {
  // console.log("Render AppRouter" , props)
  // const [locale, setLocale] = React.useState(props.locale ?? "th")

  // const url = process.env.REACT_APP_CLAPHERE_REST_API + "/venues"
  // console.log("load url", url)
  // const [{ data, loading, error }, refetch] = useAxios(url)
  // console.log("AppRouter is loading ", loading, data)

  // const checkLocale = React.useEffect(() => {
  //   try {
  //     if (window.localStorage) {
  //       var saved = window.localStorage.getItem("locale")
  //       if (saved != null) {
  //         setLocale(saved)
  //       }
  //     }
  //   } catch (err) {
  //     console.log("Error loading locale")
  //   }
  // }, [])

  // console.log("Render app-root", localeParams)
  // console.log("app-root routeParams", routeParams)

  var defaultCountry = props.country ?? "th"
  var defaultLang = props.locale ?? "th"
  return (
    // <Router
    // history={this.props.history}
    // location={this.props.location}
    // context={this.props.context}
    // >
    // <ApolloProvider client={this.client}>
    // <BootstrapProvider theme={{ "$brand-danger": "green" }}>

    <div className="router-root">
      <Switch>
        <Route path="/orders/:orderId" component={OrderPage}>
          <Redirector />
        </Route>
        <Route path="/posts/:postId" component={PostPage}>
          <Redirector />
        </Route>
        <Route path="/venues/:venueId">
          <Redirector />
        </Route>
        <Route path="/shops/:venueId">
          <Redirector />
        </Route>
        <Route path="/shop/:venueId">
          <Redirector />
        </Route>
        <Route path="/:country/:lang">
          <LocalizedContent />
        </Route>
        <Route path="/" exact>
          <Redirect to={`/${defaultCountry}/${defaultLang}`} />
        </Route>
        {/* <Route path="/about" exact math>
          <Redirect to="/th/th/about" />
        </Route> */}
        {/* <Route path="/:country" exact math>
          <Redirect to="/th/th" />
        </Route> */}
        <Route path="*">
          <Redirector />
        </Route>
      </Switch>
    </div>
    // </BootstrapProvider>
    // </ApolloProvider>
    // </Router>
  )
}
