import { calculatePhotoUrl } from '../utils/imageUrl'

export const PhotoWidget = ({
  photo,
  width,
  className,
  alt
}: {
  photo: any
  width?: number
  className?: string
  alt?: string
}) => {
  if (!photo) {
    return <div></div>
  }

  const picture = calculatePhotoUrl(photo)
  return (
    <img
      src={picture.url}
      style={{ objectFit: "cover", width: "100%", height: "100%" }}
      alt={alt}
      className={className}
    />
  )
}
