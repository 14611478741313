import React from "react"
import { ClapButton } from "../../components/ClapButton"

import { Footer } from "../../components/footer"
import OgMeta from "../../components/OgMeta"
import { PageContainer } from "../../components/PageContainer"

export const BusinessPage = (props) => {
  return (
    <PageContainer>
      <OgMeta />
      <div className="container m-auto max-w-screen-xl min-h-screen">
        <div className="sm:pt-10">
          <div
            className="text-lg text-center bg-white rounded-xl p-10"
            style={{
              minHeight: "calc( 100vh - 120px - 162px)"
            }}
          >
            <div className="text-xl font-bold my-2">
              To start using ClapHere at your shop
            </div>

            <div className="my-4">
              <a href="https://business.claphere.com/" target="_blank">
                <ClapButton primary label="Start FREE trial" />
              </a>
            </div>

            <h4 className="my-4">
              If you have any questsions - contact us at{" "}
              <a href="mailto:contact@claphere.com">contact@claphere.com</a>
            </h4>
          </div>
        </div>
      </div>
      <Footer />
    </PageContainer>
  )
}
