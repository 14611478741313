import { useState } from "react"
import { faCheck } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { PageContainer } from "../../components/PageContainer"
import { useRouteMatch } from "react-router"
import { Footer } from "../../components/footer"

const pricingOptions = [
  {
    code: "FREE",
    name: "Starter",
    description: "Starter package getting your shop online for FREE!",
    options: ["Digital Menu", "Page at claphere.com"],
    price: "FREE",
    pricePerMonth: false
  },
  {
    code: "ORDERING",
    name: "Ordering",
    description: "For most businesses that want to optimize their service",
    options: [
      "Digital Menu",
      "Page at claphere.com",
      "Unlimited Direct ordering with ClapHere App",
      "Integration with delivery providers",
      "Direct messaging with customers",
      "Access to mobile Merchant",
      "Clap Reviews Feed"
    ],
    price: "฿3900",
    pricePerMonth: true
  },
  {
    code: "HOSPITALITY",
    name: "Hospitality",
    description: "For most businesses that want to optimize their service",
    options: [
      "Digital Menu",
      "Page at claphere.com",
      "Unlimited Direct ordering with ClapHere App",
      "Integration with delivery providers",
      "Direct messaging with customers",
      "Access to mobile Merchant",
      "Clap Reviews Feed",
      "Domain .clap.menu",
      "Customer History & Analytics",
      "Games, Invitations, Coupon",
      "Loyalty Programs",
      "Dedicated Account Manager"
    ],
    price: "฿8900",
    pricePerMonth: true
  },
  {
    code: "PREMIUM",
    name: "Premium",
    description: "For most businesses that want to optimize their service",
    options: [
      "Everything in other plans",
      "Use own domain",
      "Branded website",
      "Branded mobile app"
    ],
    price: "CALL",
    pricePerMonth: false
  }
]

const optionsWithPrices = (country) => {
  const options = [...pricingOptions]
  if (country === "pl") {
    options[1].price = "390PLN"
    options[2].price = "890PLN"
  }

  return options
}

const PricingCard = ({ option, selected }) => {
  return (
    <div
      className="bg-white 
        w-full sm:w-1/4
        bg-white 
        my-2 sm:my-2
        px-6
        py-2
        rounded-2xl
        cursor-pointer
        hover:bg-gray-100
    "
      style={
        selected
          ? {
              transform: "translateY(-50px)",
              backgroundColor: "var(--clap-color-violet)",
              color: "white",
              boxShadow: "0 34px 42px #5243C230;"
            }
          : {}
      }
    >
      <a
        href={`https://business.claphere.com/register?plan=${option.code}`}
        target="_blank"
      >
        <div
          className="text-2xl my-2 flex flex-row items-center"
          style={{ fontWeight: 800 }}
        >
          <div
            className="text-2xl my-2"
            style={{ color: "#FF7200", fontWeight: 800 }}
          >
            {option.price}
          </div>
          {option.pricePerMonth && (
            <div className="ml-4 text-base font-normal">/month</div>
          )}
        </div>
        <div
          className="text-2xl mt-4 mb-4"
          style={{
            color: selected ? "white" : "var(--clap-color-violet)",
            fontWeight: 800
          }}
        >
          {option.name}
        </div>
        <div
          className="text-base mt-2 mb-6 font-normal"
          style={{ color: selected ? "white" : "#848199" }}
        >
          {option.description}
        </div>
        <div className="my-2" />
        <div style={{ height: 450 }}>
          {option.options.map((o) => (
            <div className="my-2 text-base">
              <div className="flex flex-row">
                <div
                  className="mr-4 flex justify-center items-center"
                  style={{
                    height: 22,
                    width: 22,
                    borderRadius: 20,
                    backgroundColor: selected ? "#5B1972" : "#5243C220",
                    color: selected ? "white" : "#5243C2",
                    fontSize: 12
                  }}
                >
                  <FontAwesomeIcon icon={faCheck} />
                </div>
                <div className="flex-1">{o}</div>
              </div>
            </div>
          ))}
        </div>
        <div>
          <div
            className="pointer rounded-3xl px-4 py-2 my-4 text-center font-extrabold"
            style={{
              backgroundColor: selected ? "#FF7200" : "#F496D11a",
              color: selected ? "var(--clap-color-violet)" : "#F496D1"
            }}
          >
            CHOOSE PLAN
          </div>
        </div>{" "}
      </a>
    </div>
  )
}
export const PricingPage = () => {
  const [selectedOption, setSelectedOption] = useState(1)
  const match = useRouteMatch<any>()
  const plans = optionsWithPrices(match.params?.country)

  return (
    <PageContainer>
      <div
        className="max-w-screen-xl 
            m-auto container
           px-4 sm:px-0
           "
      >
        <div className="flex flex-col items-center">
          <div
            className="mt-4 text-6xl italic font-extrabold"
            style={{
              color: "var(--clap-color-violet)"
            }}
          >
            Pricing
          </div>
          <div
            className="my-2 text-xl font-medium"
            style={{
              color: "#848199"
            }}
          >
            No contracts. No surprise fees.
          </div>
        </div>
        {/* 
        <div style={{}}>
          <div>MONTHLY</div>
          <div>YEARLY</div>
        </div> */}
        <div
          className="mt-20 flex flex-col sm:flex-row p-4 rounded-2xl 
        sm:bg-white"
        >
          {plans.map((po, index) => (
            <PricingCard option={po} selected={index === selectedOption} />
          ))}
        </div>

        <div className="my-2">
          <h4 className="py-4 text-center text-3xl font-bold">
            If you have any questions - contact us at{" "}
            <a href="mailto:contact@claphere.com">contact@claphere.com</a>
          </h4>
        </div>
      </div>
      <Footer />
    </PageContainer>
  )
}
