import useAxios from 'axios-hooks'
import React from 'react'

export const SectionFeedHoc = (WrappedComponent) => {
  class RecommendationsFeedHocInner extends React.PureComponent<any, any> {
    constructor(props) {
      super(props)
      this.state = {}
    }

    setStateAsync = (state) => {
      console.log("setState Async", state)
      new Promise<void>((resolve) => {
        this.setState(state, () => resolve())
      })
    }

    handleQueryChange = (params) => {
      console.log("handleQueryChange", params)
    }

    handleToggleBookmark = async (postId, on) => {
      console.log("handleToggleBookmark", postId, on)

      try {
        const update = async (cache, { data }) => {
          console.log("Call update ", data)
          let bookmarkSuccess = false
          if (data && on && data.addBookmark) {
            bookmarkSuccess = data.addBookmark.success
          }
          if (data && !on && data.removeBookmark) {
            bookmarkSuccess = data.removeBookmark.success
          }
          // if (bookmarkSuccess) {
          //   const qlfeedCache = cache.readQuery({
          //     query: qlfeed
          //   })
          //   console.log("qlfeedCache", qlfeedCache)
          //   if (qlfeedCache && qlfeedCache.feed && qlfeedCache.feed.items) {
          //     const itemIdx = qlfeedCache.feed.items.findIndex(
          //       (i) => i.feedUid === postId
          //     )
          //     console.log(itemIdx)
          //     if (itemIdx !== undefined) {
          //       const items = [...qlfeedCache.feed.items]
          //       items[itemIdx] = { ...items[itemIdx] }
          //       if (on) {
          //         items[itemIdx].bookmark = data.addBookmark.bookmark
          //       } else {
          //         items[itemIdx].bookmark = null
          //       }
          //       console.log("Updated items", items)
          //       cache.writeQuery({
          //         query: qlfeed,
          //         data: {
          //           feed: {
          //             ...qlfeedCache.feed,
          //             items
          //           }
          //         }
          //       })
          //     }
          //   }
          // }
        }
        // const deleteResult = data.myPostDelete

        const res = await this.props.onToggleBookmark(postId, on, { update })
      } catch (err) {}
    }
    render() {
      // console.log('Render RecommendationsFeedHoc', this.props, this.state)
      return (
        <WrappedComponent
          {...this.props}
          posts={this.props.posts}
          isLoading={this.state.isLoading}
          onQueryChange={this.handleQueryChange}
          onToggleBookmark={this.handleToggleBookmark}
        />
      )
    }
  }

  const RecommendationsFeedHocInnerWithQuery = (props) => {
    const pageSize = 6
    const variables: any = {
      first: pageSize,
      after: null
    }
    if (props.venueId) {
      variables.venueId = props.venueId
    }

    const url =
      process.env.REACT_APP_CLAPHERE_REST_API + "/posting/v2/CLAP/feed/posts"
    const [{ data, loading, error }, refetch] = useAxios(url, {
      useCache: false
    })
    const feed = data
    const posts = data ? data.edges : undefined

    return (
      <RecommendationsFeedHocInner
        {...props}
        posts={posts}
        onRefetch={refetch}
        isLoading={loading}
        hasNextPage={feed ? feed.pageInfo.hasNextPage : false}
      />
    )
  }
  return RecommendationsFeedHocInnerWithQuery
}
